import React from "react";
import ReactDOM from "react-dom/client"; 
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"; 
import "./index.css";
import LiveSession from "./pages/LiveSession";
import MainLanding from "./pages/MainLanding";
import { Registration } from './pages/Registration';
import { Confirmation } from './pages/Confirmation';
import { CMS as UserCollage } from './pages/UserCollage/CMS';
import { CMS as InsightTokenCMS } from './pages/admin/Media/InsightToken/CMS';
import { CMS as CollageImageCMS } from './pages/admin/Media/Collage/CMS';
import { CMS as ProductInfoCMS } from './pages/admin/Media/ProductInfo/CMS';
import { CMS as LeadTimingCMS } from './pages/admin/LeadTimingAndPricing/CMS';
import reportWebVitals from './reportWebVitals';
import Printout from "./pages/Printout/Printout";
import { Admin } from './pages/admin'
import { Users } from './pages/admin/Users.jsx'
import { Media } from './pages/admin/Media/index.jsx'
import { Login } from './pages/admin/auth/Login'
import { AuthProvider } from './pages/admin/auth/util'


const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLanding />,
  },
  {
    path: '/userCMS/:userId/*',
    element: <UserCollage />,
  },
  {
    path: "/userSession/:userId",
    element: <LiveSession />,
  },
  {
    path: "/register/:markerId",
    element: <Registration />,
  },
  {
    path: "/register/edit/:userId",
    element: <Registration editMode={true}/>,
  },
  {
    path: "/register/confirmation",
    element: <Confirmation />,
  },
  {
    path: '/printout/:userId',
    element: <Printout />
  },
  {
    path: '/admin',
    element: <AuthProvider><Admin /></AuthProvider>,
    children: [
      {
        path: 'users',
        element: <Users />
      },
      {
        path: 'leadTimingAndPricing/*',
        element: <LeadTimingCMS />
      },
      {
        path: 'media',
        element: <Media />,
        children: [
          {
            path: 'insightToken/*',
            element: <InsightTokenCMS />
          },
          {
            path: 'productInfo/*',
            element: <ProductInfoCMS />
          },
          {
            path: 'collage/*',
            element: <CollageImageCMS />
          }
        ]
      }
    ],
  }
]); 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
