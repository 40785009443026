import { useState } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronCircleRight } from "react-icons/fa";
import Vignettes from '../components/Vignettes';
import PageWrapper from '../components/PageWrapper';
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { prevArrow } from '../assets/svgs/arrows';
import { format } from 'date-fns';
import { ThreeCircles } from 'react-loader-spinner'
import { useSearchParams } from 'react-router-dom';
import heroImage from "../assets/Bitmap.png"
import { ServiceHighlights } from '../components/ServiceHighlights';




import 'photoswipe/dist/photoswipe.css'

import { Gallery, Item } from 'react-photoswipe-gallery'




const NavigationArrow = ({ Icon, color, className, onClick }) => {
  return (
    <>
      <Icon className={className} color={color} onClick={onClick} />
    </>
  )
}

const expandSvg = () => (
  <svg width="31px" height="31px" viewBox="0 0 31 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Pre-and-Post-Visit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="UA_ScanQR_New" transform="translate(-328.000000, -786.000000)">
              <g id="Group-22" transform="translate(0.000000, 776.000000)">
                  <g id="Group-41" transform="translate(328.000000, 10.000000)">
                      <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="31" height="31" rx="2"></rect>
                      <g id="Group-40" transform="translate(8.000000, 8.000000)" fill="#191919" fill-rule="nonzero">
                          <path d="M14.1904643,5.70253174e-08 C14.6375585,5.70253174e-08 14.9999999,0.362441498 14.9999999,0.809535673 L14.9999999,8.8235294 L13.3809287,8.8235294 L13.380528,1.61907129 L6.1764706,1.61907129 L6.1764706,5.70253174e-08 L14.1904643,5.70253174e-08 Z" id="Path-2"></path>
                          <path d="M8.01399379,6.1764706 C8.46108796,6.1764706 8.8235294,6.53891204 8.8235294,6.98600621 L8.8235294,14.9999999 L7.20445817,14.9999999 L7.2040575,7.79554183 L5.70253174e-08,7.79554183 L5.70253174e-08,6.1764706 L8.01399379,6.1764706 Z" id="Path-2" transform="translate(4.411765, 10.588235) scale(-1, -1) translate(-4.411765, -10.588235) "></path>
                          <polygon id="Path-3" transform="translate(7.500000, 7.500000) rotate(0.000000) translate(-7.500000, -7.500000) " points="13.744826 5.70253174e-08 14.9999999 1.12219089 1.25517398 14.9999999 5.70222937e-08 13.8778091"></polygon>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>
);

const getFormattedTimestamp = (timestamp) => (
  <>
    Time Stamp - {format(new Date(timestamp), 'MM/dd/yy . hh:mm a O')}
  </>
);

const settings = {
  // dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  // prevArrow: (<FaChevronCircleLeft color="black" />),

  prevArrow: prevArrow(true, '#FFFFFF'),
  nextArrow: (<NavigationArrow Icon={FaChevronCircleRight} color="black"/>),
};

function VignetteSummary({user, userSession, screenshots, isLoading}) {

  // screenshots = [];

  const [thumbsSwiper, setThumbsSwiper] = useState(null);  

  const [searchParams, setSearchParams] = useSearchParams();
  let isRemoteAttendee = searchParams.get("remote");
  isRemoteAttendee = isRemoteAttendee === 'true';

  const userLevelScreenshots = screenshots && screenshots.filter(screenshot => screenshot.level === 'USER');

  return (
    <PageWrapper isRemoteAttendee={isRemoteAttendee}>
      {
        !user ? null
        : isLoading ? <ThreeCircles
                        height="80"
                        width="80"
                        radius="9"
                        color="#FDB913"
                        ariaLabel="loading"
                        wrapperStyle
                        wrapperClass="justify-center mt-[400px]"
                      />
          : (
          <>
            {
              userLevelScreenshots && userLevelScreenshots.length ? (
                <div id="swiper-js">
                  <div className="mb-[22px]">
                    <Gallery>
                      <Swiper
                        style={{
                          "--swiper-navigation-color": "#fff",
                          "--swiper-pagination-color": "#fff",
                          "--swiper-navigation-size": "36px",
                        }} 
                        spaceBetween={10}
                        navigation={false}
                        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className=""
                      >
                        {
                          userLevelScreenshots
                            .sort((a, b) => new Date(a.created_at) < new Date(b.created_at) ? 1 : -1)
                            .map((screenshot, idx) => (
                          // session.screenshots.map((screenshot, idx) => (
                            // <div className="relative">
                              <SwiperSlide key={idx}>
                                <Item
                                  original={screenshot.url}
                                  thumbnail={screenshot.url}
                                  // width="1024"
                                  // height="768"
                                >
                                  {({ ref, open }) => (
                                    // <img  src="https://placekitten.com/80/60?image=1" />
                                    <div ref={ref} onClick={open} className="relative h-full">
                                      <img className="object-cover md:object-fill" src={screenshot.url} />
                                      <div className="swiper-main-bottom-gradient">
                                        <div className="absolute bottom-0 right-0 pb-[12px] pr-[30px] font-medium">
                                          <div className="hidden md:block">
                                            {getFormattedTimestamp(screenshot.created_at)}
                                          </div>
                                          <div className="md:hidden">
                                            {expandSvg()}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Item>
                                
                              </SwiperSlide>
                            // </div>
                          ))
                        }
                      </Swiper>
                    </Gallery>
                  </div>
                  <Swiper
                    style={{
                      "--swiper-navigation-color": "#fff",
                      "--swiper-pagination-color": "#fff",
                    }}
                    onSwiper={setThumbsSwiper}
                    spaceBetween={5}
                    slidesPerView={4}
                    navigation={true}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper max-h-[130px] thumbnail-swiper"
                    breakpoints={{
                      320: {
                        centeredSlides: true,
                        // navigation: false,
                        slidesPerView: 2,
                        spaceBetween: 10,
                      },
                      768: {
                        centeredSlides: false,
                        slidesPerView: 4,
                        spaceBetween: 5,
                      },
                    }}
                  >
                    {
                      screenshots
                        .filter(screenshot => screenshot.level === 'USER')
                        .sort((a, b) => new Date(a.created_at) < new Date(b.created_at) ? 1 : -1)
                        .map((screenshot, idx) => (
                        <SwiperSlide key={idx} className="h-full swiper-slide-thumb">
                          {({ isActive }) => {
                            console.log('idx: ', idx);
                            console.log('isActive? ', isActive);
                            return (
                              <>
                                <img className="" src={screenshot.url} />
                                <div className={`${isActive ? 'block' : 'hidden'} text-[10px] md:hidden`}>
                                  {getFormattedTimestamp(screenshot.created_at)}
                                </div>
                              </>
                            );
                          }}
                        </SwiperSlide>
                      ))
                    }
                  </Swiper>
                </div>
              ) : (
                <div id="hero">
                  <img src={heroImage} />
                </div>
              )
            }
            {/* <section id="section-session-summary" className="mt-[60px] md:mt-[100px]">
              <div className="text-[32px] md:text-[46px] font-medium">{user.locationName}</div>
            </section> */}
            {
              userSession && userSession.vignetteSessions && userSession.vignetteSessions.length ? (
                <section id="section-vignettes" className="mt-[65px] md:mt-[80px]">
                  <div className="text-[23px] md:text-[38px] text-center font-medium">Your Summary</div>
                  <Vignettes
                    className="mt-[40px]"
                    screenshots={screenshots}
                    vignettes={userSession.vignetteSessions}
                  />
                </section>
              ) : (
                <ServiceHighlights />
              )
            }
          </>
        )
      }

    </ PageWrapper>
  )
}

export default VignetteSummary
