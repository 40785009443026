import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { PlusIcon, MinusIcon } from '../../assets/svgs/all.jsx';

const baseInputCss = 'block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6';

const getErrorMessage = (label, errorMsg, fieldName) => {
  if (Array.isArray(errorMsg)) {
    errorMsg = errorMsg.find(el => el !== undefined);
    errorMsg = errorMsg[fieldName];
  }
  switch (errorMsg.type) {
    case 'required':
      return `${label} is a required field`;
    case 'email':
      return `Email is invalid`;
    default:
      return 'unknown error'
  }
}

export const FieldWrapper = ({fieldName, label, required, errorMsg, children}) => {
  let errorIconCss = 'hidden';
  let errorMsgCss = 'hidden';
  let inputCss = 'block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6'

  if (errorMsg) {
    errorIconCss = 'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3';
    errorMsgCss = 'mt-2 text-red-600';
    inputCss = 'block w-full border-0 py-1.5 pr-10 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:leading-6';
  }

  return (
    <div className="sm:col-span-4 mb-[14px]">
      <label htmlFor="email" className="block font-medium leading-6 text-gray-900">
        {label}{required ? '*' : ''}
      </label>
      <div className="relative mt-[8px] shadow-sm">
        {children}
        <div className={errorIconCss}>
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
        </div>
      </div>
      <p className={errorMsgCss}>
        {errorMsg && getErrorMessage(label, errorMsg, fieldName)}
      </p>
    </div>
  )
}

export const Field = (register, isAdmin) => (props) => {
  const {label, dataAttribute, placeholder = '', required, value, isAdminOnly, isStatic, errorMsg} = props;


  let inputCss = baseInputCss;

  if (errorMsg) {
    inputCss = 'block w-full border-0 py-1.5 pr-10 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:leading-6';
  }

  const registrationProps = register(dataAttribute, { value, required, validate: props.validate });

  if (isStatic || (isAdminOnly && !isAdmin)) {
    return null;
  }

  return (
    <FieldWrapper {...props}>
      <input
        className={`bg-transparent ${inputCss}`}
        {...registrationProps}
        type="text"
        placeholder={placeholder}
      />
    </FieldWrapper>
  )
}

export const FieldArray = (register) => (props) => {

  return (
    <FieldWrapper {...props}>
      <ul>
        {props.fields.map((item, index) => {
          return (
            <li className="mb-[5px]" key={item.id}>
              <div className="flex flex-row">
                <input
                  className={`my-auto mr-[10px] bg-transparent ${baseInputCss}`}
                  {...register(`${props.dataAttribute}.${index}.${props.fieldName}`, { required: props.required, validate: props.validate })}
                  type="text"
                  placeholder={props.placeholder}
                />
                <button className="my-auto p-0" type="button" onClick={() => props.remove(index)}>
                  {MinusIcon}
                </button>
              </div>
            </li>
          );
        })}
      </ul>
      <section className="mt-[12px]">
        <div className="flex flex-row">
          <button
            type="button"
            className="p-0 mr-[10px]"
            onClick={() => {
              props.append({ email: '' });
            }}
          >
            {PlusIcon}
          </button>
          <div className=" my-auto">
            Add More Guests
          </div>
        </div>
      </section>
    </FieldWrapper>
  )
}

export const Select = (register) => (props) => {

  const {label, dataAttribute, options, placeholder = '', required, errorMsg} = props;

  let inputCss = 'block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6'

  if (errorMsg) {
    inputCss = 'block w-full border-0 py-1.5 pr-10 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:leading-6';
  }

  return (
    <FieldWrapper {...props}>
      <select
        className={`mt-[8px] bg-transparent ${inputCss}`}
        {...register(dataAttribute, { required })}
        type="text"
        placeholder={placeholder}
      >
        {
          options.map((opt, idx) => (
            <option key={idx} value={opt.id}>{opt.first_name} {opt.last_name}</option>
          ))
        }
      </select>
    </FieldWrapper>
  )
}