import React from 'react'
import { Page, Text, View, Document, StyleSheet, Font, PDFViewer, Image } from '@react-pdf/renderer';
import ralewayFont from '../../assets/font/Raleway-VariableFont_wght.ttf';
import rBold from '../../assets/font/static/Raleway-Bold.ttf';
import rSemiBold from '../../assets/font/static/Raleway-SemiBold.ttf';
import rMedium from '../../assets/font/static/Raleway-Medium.ttf';
import rRegular from '../../assets/font/static/Raleway-Regular.ttf';
import rLight from '../../assets/font/static/Raleway-Light.ttf';
import certLogo from '../../assets/CertificationLogo.png';
import { getChunkedArray } from '../../utils/index.js';
import { getVignetteRooms } from '../../utils/selectors.js';
import { getThumbnailUrlsForProduct } from '../../components/ProductThumbnail';

// import { data as userData } from './mockData';
import { getLayoutName, getProductType, PRODUCT_TYPES, getProductWarranty, isProductLevel2Certified, getNumberOfVignetteRooms } from '../../utils/dataMaps';
import {
  getFormattedLeadTimeRange,
  getProductLaminates,
  getProductOptions,
  getLaminateSwatchUrl,
  getPaintedTrimSwatchUrl,
  getProductPaintAndTrim,
} from '../../utils/selectors.js';
import { getDisplayName } from '../../utils/metaData.js';

import { imageNotFoundUrl } from "../../utils";
import Cover_Page_image from '../../assets/printout-images/FellowesPrintOut_ForAustinStandard.png';
import Image_Company_Info_Cover from '../../assets/printout-images/FellowesPrintOut_ForAustinStandard3.png';
import Image_Company_Info_2 from '../../assets/printout-images/FellowesPrintOut_ForAustinStandard4.png';
import Image_Company_Highlights from '../../assets/printout-images/FellowesPrintOut_ForAustinStandard5.png';
import Image_Product_categories_Slide from '../../assets/printout-images/FellowesPrintOut_ForAustinStandard6.png';

import Image_03_warranty from '../../assets/printout-images/FellowesPrintOut_ForAustinStandard7.png';
import Image_Company_Logo_Final_Slide from '../../assets/printout-images/FellowesPrintOut_ForAustinStandard8.png';


Font.register({ family: 'Raleway', fonts: [
  { src: ralewayFont }, // font-style: normal, font-weight: normal
  { src: rBold, fontWeight: 'bold' },
  { src: rBold, fontWeight: 700 },
  { src: rSemiBold, fontWeight: 600 },
  { src: rMedium, fontWeight: 500 },
  { src: rLight, fontWeight: 400 },
  { src: rRegular, fontWeight: 300 },
 ]});

 Font.registerHyphenationCallback(word => [word]);

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

const styles2 = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#657371',
    // color: "#ff0000",
    // fontSize: 200,
    fontFamily: 'Raleway',
      // line-height: 1.5;
    // fontWeight: 500,
    color: '#FFFFFF',
  },
  section: {
      fontFamily: 'Raleway',
      margin: 0,
      padding: 0,
      flex: 1,
      flexDirection: "row",
      alignItems: "stretch"
  },
  pageimage:{
      fontFamily: 'Raleway',
      flex: 1,
      width: null,
      height: null
  },
  placeholderText:{
      fontFamily: 'Raleway',

      width: "100%",
      height: 50,
      fontSize: '30px',
      lineHeight: 1,
      textAlign: "center"

  },
  placeholderPage: {
    fontFamily: 'Raleway',

      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: '30px',
      hyphens: 'none',
      margin: '0 15%',
      textAlign: 'center',
      // fontWeight: 500,
      lineHeight: 1.7,
  },
  titlePage: {
    fontFamily: 'Raleway',

    backgroundColor: '#FFFFFF',
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
  },
  titlePageText: {
    fontFamily: 'Raleway',

    // backgroundColor: '#FFFFFF',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#000000",
    fontSize: '50px',
    // fontWeight: 400,
    borderLeft: "4px solid #fdb916",
    paddingLeft: 30,
    marginLeft: '7%',
  },
  footerText: {
    fontFamily: 'Raleway',

    display: "flex",
    alignItems: "left",
    justifyContent: "bottom",
    color: "#000000",
    fontSize: '26px',
    // fontWeight: 300,
    borderLeft: "2px solid #fdb916",
    paddingLeft: '10px',
    // marginLeft: '7%',
  },
  noHyphens: {
    hyphens: 'none',
  }
});

const productPageStyle = {
  page: {
    fontFamily: 'Raleway',

    padding: '40px 40px',
    // fontWeight: 600,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    // fontSize: '32px',
  },
  row: {
    fontFamily: 'Raleway',

    display: 'flex',
    flexDirection: 'row',
    columnGap: '60px',
  },
  column: {
    fontFamily: 'Raleway',

    float: 'left',
    width: '25%'
  },
  productBlock: {
    fontFamily: 'Raleway',

    marginBottom: '40px',
    fontSize: '15px',
  },
  heading: {
    fontFamily: 'Raleway',

    marginBottom: '20px'
  }
}

const documentBaseStyle = {
  fontFamily: 'Raleway',
  fontWeight: 300,
}

const rowCss = {
  display: 'flex',
  flexDirection: 'row',
  // flexWrap: 'wrap',
  width: '100%',
  // gap: '20o='
  columnGap: '10px',
  marginTop: '10px',
}

const columnCss = {
  display: 'flex',
  flexDirection: 'column',
  flexBasis: '100%',
  flex: 1,
}

// <Text style={{ position: 'absolute', bottom: '20px', left: '20px', right: '20px', color: '#484848' }}>

const footerTextCss = {
  fontFamily: 'Raleway',
  // display: "flex",
  // alignItems: "left",
  // justifyContent: "bottom",
  color: "#000000",
  fontSize: '26px',
  borderLeft: "2px solid #fdb916",
  paddingLeft: '10px',
  position: 'absolute',
  bottom: '20px',
  left: '20px'
};

const header = { marginBottom: '20px', fontWeight: 600 };
const contentPage = [
  styles2.page,
  { display: 'flex', flexDirection: 'column', backgroundColor: '#FFFFFF', color: '#000000', justifyContent: 'space-between', padding: '40px' }
];



const noneString = 'None Selected'

const aMillionPeriods = '...............................................................................................................................................';
const addLongEllipsisToString = (str) => str+aMillionPeriods;

const tableOfContents = {
  contentElement: { overflow: 'hidden', whiteSpace: 'nowrap', columnGap: '4px', marginBottom: '20px' }
}

const pageNumberDiv = (pageNumber, totalNumberOfPages) => (
  <Text style={{ position: 'absolute', bottom: '20px', left: '20px', right: '20px', color: '#484848' }}>
    <Text style={{ justifyContent: 'center', textAlign: 'right' }}>Page {pageNumber} of {totalNumberOfPages}</Text>
  </Text>
);

const OutlinePage = ({ pageNumber, coverPageNumber, productOverviewPageNumber, rendersPageNumber, summaryPageNumber, totalNumberOfPages }) => (
  <Page title="OUTLINE" size={{width: 8.5*72, height: 11*72}} orientation="landscape" style={{ padding: '60px' }}>
    <View style={{ display: 'flex', flexDirection: 'column', width: '40%' }}>
      <Text style={[styles2.titlePageText, { marginLeft: 0, fontSize: '40px', marginBottom: '30px' }]}>OUTLINE</Text>

      <View style={[rowCss, tableOfContents.contentElement]}>
        <Text style={[columnCss, { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'clip', flex: 5 }]}>{addLongEllipsisToString('Cover')}</Text>
        <Text style={[columnCss, { width: '20%', flex: 1 }]}>{coverPageNumber}</Text>
      </View>
      <View style={[rowCss, tableOfContents.contentElement]}>
        <Text style={[columnCss, { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'clip', flex: 5 }]}>{addLongEllipsisToString('About Fellowes')}</Text>
        <Text style={[columnCss, { width: '20%', flex: 1, justifyContent: 'center', marginTop: '21px' }]}>{coverPageNumber + 1}</Text>
      </View>
      <View style={[rowCss, tableOfContents.contentElement]}>
        <Text style={[columnCss, { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'clip', flex: 5 }]}>{addLongEllipsisToString('Product Info')}</Text>
        <Text style={[columnCss, { width: '20%', flex: 1, justifyContent: 'center', marginTop: '21px' }]}>{productOverviewPageNumber}</Text>
      </View>
      <View style={[rowCss, tableOfContents.contentElement]}>
        <Text style={[columnCss, { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'clip', flex: 5 }]}>{addLongEllipsisToString('Screenshots')}</Text>
        <Text style={[columnCss, { width: '20%', flex: 1, justifyContent: 'center' }]}>{rendersPageNumber}</Text>
      </View>
      <View style={[rowCss, tableOfContents.contentElement, { paddingBottom: '0px' }]}>
        <Text style={[columnCss, { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'clip', flex: 5 }]}>{addLongEllipsisToString('Your Summary')}</Text>
        <Text style={[columnCss, { width: '20%', flex: 1, justifyContent: 'center', marginTop: '21px' }]}>{summaryPageNumber}</Text>
      </View>
    </View>
    {pageNumberDiv(pageNumber, totalNumberOfPages)}
  </Page>
)

const ImagePage = ({ image, title, pageNumber, totalNumberOfPages }) => (
  <Page title={title} size={{width: 8.5*72, height: 11*72}} orientation="landscape" style={styles.page}>
    <View style={{}}>
      <Image source={image} />
    </View>
    {pageNumberDiv(pageNumber, totalNumberOfPages)}
  </Page>
)

const HeaderPage = ({ title, header, pageNumber, totalNumberOfPages }) => (
  <Page title={title} size={{width: 8.5*72, height: 11*72}} orientation="landscape" style={styles2.page}>
    <View style={[styles2.section, styles2.titlePage]}>
      <Text style={styles2.titlePageText}>{header}</Text>
    </View>
    {pageNumberDiv(pageNumber, totalNumberOfPages)}
  </Page>
)

export const PrintoutContent2 = () => {
  return (
    <PDFViewer>
      <Document style={documentBaseStyle}>
        {ImagePage({ image: Image_03_warranty, title: 'WARRANTY', pageNumber: 2, totalNumberOfPages: 21 })}
        {ImagePage({ image: Image_Company_Logo_Final_Slide, title: 'END_LOGO', pageNumber: 1, totalNumberOfPages: 22 })}
      </Document>
    </PDFViewer>
  )
}

export const PrintoutContent = ({ userData }) => {

  console.log('inside printout content with data: ', userData);

  const allProducts = userData.user_session.json_body.vignetteSessions.flatMap(vignetteSession => vignetteSession.productConfigurations);
  const wallProducts = allProducts.filter(product => getProductType(product.product) === PRODUCT_TYPES.WALL);
  const assemblyProducts = allProducts.filter(product => getProductType(product.product) === PRODUCT_TYPES.ASSEMBLY);
  const aqmProducts = allProducts.filter(product => getProductType(product.product) === PRODUCT_TYPES.AIR_QUALITY_MANAGEMENT);

  // let numberOfProductCategories = 0;
  // if (wallProducts.length) numberOfProductCategories++;
  // if (assemblyProducts.length) numberOfProductCategories++;
  // if (aqmProducts.length) numberOfProductCategories++;

  const workspaceProducts = allProducts.filter(product =>
    getProductType(product.product) !== PRODUCT_TYPES.WALL
    && getProductType(product.product) !== PRODUCT_TYPES.ASSEMBLY
    && getProductType(product.product) !== PRODUCT_TYPES.AIR_QUALITY_MANAGEMENT
  );

  const screenshots = userData.screenshots;
  const userLevelScreenshots = userData.screenshots.filter(screenshot => screenshot.level === 'USER');
  const pagesOfScreenshots = Math.ceil(userLevelScreenshots.length / 6);
  let screenshotPages = [];
  for (let i = 0; i < pagesOfScreenshots; i++) {
    screenshotPages.push(i);
  }

  const staticPagesFront = 7;
  const numberOfRenderPages = pagesOfScreenshots;
  const numberOfSummaryPages =
    userData.user_session.json_body.vignetteSessions.length
    + userData.user_session.json_body.vignetteSessions.reduce((acc, currentVignette) => acc + getNumberOfVignetteRooms(currentVignette.vignettePropSelections.vignetteLayout), 0);

  const coverPage = 4;
  const productOverviewPage = staticPagesFront + 1;
  const rendersPage = productOverviewPage + 2;
  const summaryPage = rendersPage + numberOfRenderPages + 1;
  const warrantyPage = summaryPage + numberOfSummaryPages + 1;
  const totalNumberOfPages = warrantyPage + 1;

  const vignetteStartingPages = [summaryPage+1];
  userData.user_session.json_body.vignetteSessions.forEach(vignetteSession =>
    vignetteStartingPages.push(vignetteStartingPages[vignetteStartingPages.length-1] + getNumberOfVignetteRooms(vignetteSession.vignettePropSelections.vignetteLayout) + 1)
  )



  const footerDiv = (footerText) => (
    <View style={footerTextCss}>
      <Text>{footerText}</Text>
    </View>
  )

  return (
    <PDFViewer>
      <Document style={documentBaseStyle}>
        {ImagePage({ image: Cover_Page_image, title: 'COVER', pageNumber: 1, totalNumberOfPages })}
        <Page title="THANKS_FOR_STOPPING_BY" size={{width: 8.5*72, height: 11*72}} orientation="landscape" style={styles2.page}>
          <View style={[styles2.section, styles2.placeholderPage]}>
            <Text style={styles2.noHyphens}>Thank you for stopping by the Fellowes Experience & Design Center, {userData.json_body.first_name}.</Text>
          </View>
          {pageNumberDiv(2, totalNumberOfPages)}
        </Page>
        {OutlinePage({ pageNumber: 3, coverPageNumber: coverPage, productOverviewPageNumber: productOverviewPage, rendersPageNumber: rendersPage, summaryPageNumber: summaryPage, totalNumberOfPages })}
        {ImagePage({ image: Image_Company_Info_Cover, title: 'COMPANY_INFO_1', pageNumber: 3, totalNumberOfPages })}
        {ImagePage({ image: Image_Company_Info_2, title: 'COMPANY_INFO_2', pageNumber: 5, totalNumberOfPages })}
        {ImagePage({ image: Image_Company_Highlights, title: 'COMPANY_INFO_3', pageNumber: 6, totalNumberOfPages })}
        {ImagePage({ image: Image_Product_categories_Slide, title: 'COMPANY_INFO_4', pageNumber: 7, totalNumberOfPages })}
        {HeaderPage({ title: 'PRODUCT_OVERVIEW_HEADER', header: 'YOUR PRODUCT OVERVIEW',  pageNumber: productOverviewPage, totalNumberOfPages })}


        <Page title="PRODUCT_OVERVIEW_CONTENT" size={{width: 8.5*72, height: 11*72}} orientation="landscape" style={{}}>
          <View style={[contentPage]} >
            <View style={[productPageStyle.row, { columnGap: '80px' }]}>
              {
                wallProducts && wallProducts.length ? (
                  <View style={productPageStyle.column}>
                    <Text style={[productPageStyle.heading]}>MODULAR WALLS</Text>
                    {wallProducts.map((product, index) => {
                      return (
                        <View style={[productPageStyle.productBlock, { fontSize: '12px', display: 'flex', flexDirection: 'column' }]}>
                          <Text style={[productPageStyle.row, { fontWeight: 600 }]}>{getDisplayName(product.product)} - {(product.selectedWallTypical.displayName.en_us)}</Text>
                          <Text style={[productPageStyle.row]}>List Price: ${product.basePriceUSD}</Text>
                          <Text style={[productPageStyle.row, { marginTop: '5px', fontWeight: 600 }]}>Options</Text>
                          <Text style={{ flexDirection: 'row' }}>
                            {getProductOptions(product).length ? getProductOptions(product).map((val, idx) => (
                              <Text>{getDisplayName(val)}{idx === getProductOptions(product).length - 1 ? '' : ', '}</Text>
                            )) : <Text>{noneString}</Text>}
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                ) : null
              }
              {
                assemblyProducts && assemblyProducts.length ? (
                  <View style={productPageStyle.column}>
                    <Text style={productPageStyle.heading}>FURNITURE</Text>
                    {assemblyProducts.map((product, index) => {
                      return (
                        <View style={[productPageStyle.productBlock, { fontSize: '12px', display: 'flex', flexDirection: 'column' }]}>
                          <Text style={[productPageStyle.row, { fontWeight: 600 }]}>{getDisplayName(product.product)}</Text>
                          <Text style={[productPageStyle.row]}>List Price: ${product.basePriceUSD}</Text>
                          <Text style={[productPageStyle.row, { marginTop: '5px', fontWeight: 600 }]}>Options</Text>
                          <Text style={{ flexDirection: 'row' }}>
                            {getProductOptions(product).length ? getProductOptions(product).map((val, idx) => (
                              <Text>{getDisplayName(val)}{idx === getProductOptions(product).length - 1 ? '' : ', '}</Text>
                            )) : <Text>{noneString}</Text>}
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                ) : null
              }
              {
                workspaceProducts && workspaceProducts.length ? (
                  <View style={productPageStyle.column}>
                    <Text style={productPageStyle.heading}>WORKSPACE</Text>
                    {workspaceProducts.map((product, index) => {
                      return (
                        <View style={[productPageStyle.productBlock, { fontSize: '12px', display: 'flex', flexDirection: 'column' }]}>
                          <Text style={[productPageStyle.row, { fontWeight: 600 }]}>{getDisplayName(product.product)}</Text>
                          <Text style={[productPageStyle.row]}>List Price: ${product.basePriceUSD}</Text>
                          <Text style={[productPageStyle.row, { marginTop: '5px', fontWeight: 600 }]}>Options</Text>
                          <Text style={{ flexDirection: 'row' }}>
                            {getProductOptions(product).length ? getProductOptions(product).map((val, idx) => (
                              <Text>{getDisplayName(val)}{idx === getProductOptions(product).length - 1 ? '' : ', '}</Text>
                            )) : <Text>{noneString}</Text>}
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                ) : null
              }
              {
                aqmProducts && aqmProducts.length ? (
                  <View style={productPageStyle.column}>
                    <Text style={productPageStyle.heading}>AQM</Text>
                    {aqmProducts.map((product, index) => {
                      return (
                        <View style={[productPageStyle.productBlock, { fontSize: '12px', display: 'flex', flexDirection: 'column' }]}>
                          <Text style={[productPageStyle.row, { fontWeight: 600 }]}>{getDisplayName(product.product)}</Text>
                          <Text style={[productPageStyle.row]}>List Price: ${product.basePriceUSD}</Text>
                          <Text style={[productPageStyle.row, { marginTop: '5px', fontWeight: 600 }]}>Options</Text>
                          <Text style={{ flexDirection: 'row' }}>
                            {getProductOptions(product).length ? getProductOptions(product).map((val, idx) => (
                              <Text>{getDisplayName(val)}{idx === getProductOptions(product).length - 1 ? '' : ', '}</Text>
                            )) : <Text>{noneString}</Text>}
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                ) : null
              }

            </View>
          </View>
          {footerDiv('YOUR PRODUCT OVERVIEW')}
          {pageNumberDiv(productOverviewPage + 1, totalNumberOfPages)}
        </Page>


        {HeaderPage({ title: 'RENDERS_HEADER', header: 'YOUR RENDERS',  pageNumber: rendersPage, totalNumberOfPages })}


        {screenshotPages.map(pageNo => {

          const imagesForPage = userLevelScreenshots.slice(pageNo * 6, pageNo * 6 + 6);
          const numberOfRows = Math.ceil(imagesForPage.length / 2);
          let rows = [];
          for (let i = 0; i < numberOfRows; i++) {
            rows.push(i);
          }

          return (
            <Page title="RENDERS_CONTENT" size={{width: 8.5*72, height: 11*72}} orientation="landscape">
              <View style={[productPageStyle.page, { padding: '20px 40px 60px' }]}>
                <View style={{ display: 'flex' }}>
                  {rows.map(rowNo => {
                    return (
                      <View style={rowCss}>
                        {userLevelScreenshots.slice(pageNo * 6 + rowNo * 2, pageNo * 6 + rowNo * 2 + 2).map(screenshot => {

                          return (
                            <Image style={[columnCss, { width:'auto' }]} src={{ uri: screenshot.url, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} />
                          );
                        })}
                      </View>
                    );

                  })}
                </View>
              </View>
              {footerDiv('YOUR RENDERS')}
              {pageNumberDiv(rendersPage + pageNo + 1, totalNumberOfPages)}
            </Page>
          );
        })}
        {HeaderPage({ title: 'YOUR_SUMMARY_HEADER', header: 'YOUR SUMMARY',  pageNumber: summaryPage, totalNumberOfPages })}
        {userData.user_session.json_body.vignetteSessions.map((vignetteSession, vignetteIndex) => {

          let vignetteId = vignetteSession.id || vignetteSession.iD;
          const vignetteLevelScreenshot = userData.screenshots.find(screenshot => screenshot.vignette_level_key === vignetteId
            && screenshot.level === 'VIGNETTE'
          ) || { url: null };

          const rooms = getVignetteRooms(vignetteSession);

          const wallProduct = rooms[0].find(product => getProductType(product.product) === PRODUCT_TYPES.WALL);

          const vignetteStartingPage = vignetteStartingPages[vignetteIndex];

          const vignetteBase = vignetteSession.vignettePropSelections && vignetteSession.vignettePropSelections.vignetteBase;

          return (
            <>
              <Page title="YOUR_SUMMARY_CONTENT_VIGNETTE" size={{width: 8.5*72, height: 11*72}} orientation="landscape">
                <View style={contentPage}>
                  <View style={{ justifyContent: 'flex-start' }}>
                    <Text style={header}>Vignette {vignetteIndex + 1}</Text>
                    <Image style={{ width:'auto' }} src={{ uri: vignetteLevelScreenshot.url, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} />
                    <View style={[rowCss]}>
                      <View style={[columnCss]}>
                        <Text style={{ fontWeight: 600, fontSize: '25px', marginTop: '10px' }}>{(wallProduct && wallProduct.selectedWallTypical.displayName.en_us) || getLayoutName(vignetteSession)}</Text>
                        <Text style={{ fontWeight: 600, fontSize: '18px', marginTop: '10px' }}>Lead Time</Text>
                        <Text style={{ fontWeight: 500, fontSize: '22px', marginTop: '10px' }}>{getFormattedLeadTimeRange(vignetteSession.minLeadTimeInDays, vignetteSession.maxLeadTimeInDays)}</Text>
                      </View>
                      {wallProduct ? (
                        <View style={[columnCss]}>
                          <View style={[rowCss]}>
                            <View style={[columnCss, { marginRight: '40px' }]}>
                              <Text style={{ fontWeight: 700, fontSize: '18px', marginTop: '5px' }}>{getDisplayName(wallProduct.product)}</Text>
                              <Text style={{ fontSize: '18px', marginTop: '10px' }}>List Price: ${wallProduct.basePriceUSD}</Text>
                              {getProductWarranty(wallProduct.product) ? (
                                <Text style={{ fontSize: '12px', marginTop: '5px' }}>{getProductWarranty(wallProduct.product)}* ( see page {warrantyPage} )</Text>
                              ) : null}
                              <Text style={{ fontSize: '12px', marginTop: '3px' }}>Made in the USA</Text>
                            </View>
                            {isProductLevel2Certified(wallProduct.product) ? (
                              <View style={[columnCss]}>
                                <Image
                                  style={{ height: '50px', width: '50px' }}
                                  src={certLogo}
                                />
                                <Text style={{ fontSize: '12px', marginTop: '5px' }}>Level 2 Certified</Text>
                              </View>
                            ) : null}
                          </View>
                          <View style={{ columnCount: 2, columnGap: '20px' }}>
                            <Text style={[{ fontWeight: 600, fontSize: '14px', marginTop: '15px', marginBottom: '5px' }]}>Options</Text>
                            {getProductOptions(wallProduct).map((dtl, idx) => (
                              <Text key={idx} style={{ fontSize: '14px' }}>- {getDisplayName(dtl)}</Text>
                            ))}
                          </View>
                        </View>
                      ) : null}
                    </View>
                  </View>
                </View>
                {footerDiv('YOUR SUMMARY')}
                {pageNumberDiv(vignetteStartingPage, totalNumberOfPages)}
              </Page>
              {rooms.map((room, roomIndex) => {

                const roomScreenshot = screenshots.find(screenshot => screenshot.vignette_level_key === vignetteId
                  && screenshot.level === 'PRODUCT'
                  && screenshot.room_index === roomIndex)
                  || { url: null };

                const assemblyProduct = room.find(product => {
                  return getProductType(product.product) === PRODUCT_TYPES.ASSEMBLY;
                });

                const otherRoomProducts = room.filter(p => getProductType(p.product) !== PRODUCT_TYPES.ASSEMBLY && getProductType(p.product) !== PRODUCT_TYPES.WALL);
                const chunkedRoomProducts = getChunkedArray(otherRoomProducts, 4);

                return (
                  <>
                    <Page title="YOUR_SUMMARY_CONTENT_ROOM" wrap={false} size={{width: 8.5*72, height: 11*72}} orientation="landscape">
                      <View style={contentPage}>
                        <Text style={header}>Vignette {vignetteIndex + 1} - <Text style={{ fontWeight: 500 }}>Room {roomIndex + 1}</Text></Text>
                        <View style={columnCss}>
                          <View style={[rowCss, { columnGap: '20px' }]}>
                            <View style={[columnCss, { flex: 1.5, flexDirection: 'column', justifyContent: 'center' }]}>
                              <Image
                                style={{ width: '100%', objectFit: 'contain' }}
                                src={{ uri: roomScreenshot.url, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
                              />
                            </View>
                            {assemblyProduct ? (
                              <>
                                <View style={columnCss}>
                                  <Text style={{ fontWeight: 700, fontSize: '22px', marginTop: '0px' }}>{getDisplayName(assemblyProduct.product)}</Text>
                                  <Text style={{ fontSize: '14px', marginTop: '10px' }}>List Price: ${assemblyProduct.basePriceUSD}</Text>
                                  {getProductWarranty(assemblyProduct.product) ? (
                                    <Text style={{ fontSize: '12px', marginTop: '5px' }}>{getProductWarranty(assemblyProduct.product)}* ( see page {warrantyPage} )</Text>
                                  ) : null}
                                  <Text style={{ fontSize: '12px', marginTop: '3px' }}>Made in the USA</Text>
                                  {isProductLevel2Certified(assemblyProduct.product) ? (
                                    <>
                                      <Image style={{ height: '50px', width: '50px', marginTop: '15px' }} src={certLogo}></Image>
                                      <Text style={{ fontSize: '12px', marginTop: '5px' }}>Level 2 Certified</Text>
                                    </>
                                  ) : null}

                                </View>
                                <View style={columnCss}>
                                  <View>
                                    <Text style={[{ fontWeight: 600, fontSize: '14px', marginTop: '0px', marginBottom: '3px' }]}>Laminates</Text>
                                    {getProductLaminates(assemblyProduct).length ? getProductLaminates(assemblyProduct).map((val, idx) => (
                                      <View id={idx} style={[rowCss, { marginTop: '5px' }]}>
                                        <Image
                                          src={{ uri: getLaminateSwatchUrl(val), method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
                                          style={{ height: '25px', width: '25px' }}
                                        />
                                        <View style={[columnCss, { justifyContent: 'center' }]}>
                                          <Text style={{ fontSize: '14px', display: 'flex' }}>{getDisplayName(val)}</Text>
                                        </View>
                                      </View>
                                    )) : (
                                      <View style={[rowCss]}>
                                        <Text style={{ fontSize: '14px', margin: 'auto 0' }}>{noneString}</Text>
                                      </View>
                                    )}
                                  </View>
                                  <View>
                                    <Text style={[{ fontWeight: 600, fontSize: '14px', marginTop: '30px', marginBottom: '3px' }]}>Paint & Trim</Text>
                                    {getProductPaintAndTrim(assemblyProduct).length ? getProductPaintAndTrim(assemblyProduct).map((val, idx) => (
                                      <View id={idx} style={[rowCss, { marginTop: '5px' }]}>
                                        <Image
                                          src={{ uri: getPaintedTrimSwatchUrl(val), method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
                                          style={{ height: '25px', width: '25px' }}
                                        />
                                        <View style={[columnCss, { justifyContent: 'center' }]}>
                                          <Text style={{ fontSize: '14px', display: 'flex' }}>{getDisplayName(val)}</Text>
                                        </View>
                                      </View>
                                    )) : (
                                      <View style={[rowCss]}>
                                        <Text style={{ fontSize: '14px', margin: 'auto 0' }}>{noneString}</Text>
                                      </View>
                                    )}
                                  </View>
                                </View>
                                <View style={columnCss}>
                                  <View>
                                    <Text style={[{ fontWeight: 600, fontSize: '14px', marginTop: '0px', marginBottom: '5px' }]}>Options</Text>
                                    {getProductOptions(assemblyProduct).length ? getProductOptions(assemblyProduct).map((val, idx) => (
                                      <View style={[rowCss]}>
                                        <Text style={{ fontSize: '14px' }}>- {getDisplayName(val)}</Text>
                                      </View>
                                    )) : (
                                      <View style={[rowCss]}>
                                        <Text style={{ fontSize: '14px' }}>{noneString}</Text>}
                                      </View>
                                    )}
                                  </View>
                                </View>
                              </>
                            ) : <Text>No furniture product for room</Text>}


                          </View>
                          {
                            chunkedRoomProducts.length && (
                                <View style={[rowCss, { marginTop: '20px', columnGap: '80px' }]}>
                                  {chunkedRoomProducts[0].map((product, productIndex) => {
                                    const { imageUrl, defaultUrl } = getThumbnailUrlsForProduct(product, vignetteBase);
                                    // console.log('imageUrl: ', imageUrl);
                                    return (
                                      <View style={[columnCss, { maxWidth: '20vw', minWidth: '15vw' }]}>
                                        <Image
                                          style={{ width: 'auto' }}
                                          // style={{ width: '100%' }}
                                          src={{ uri: imageUrl, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
                                        ></Image>

                                        {/* <Html>{ReactDOMServer.renderToStaticMarkup((<ProductThumbnail product={product} style={{ maxHeight: '100px' }} />))}</Html> */}
                                        <Text style={{ fontSize: '18px', marginTop: '15px', }}>{getDisplayName(product.product)}</Text>
                                        <Text style={{ fontSize: '14px', marginTop: '5px' }}>List Price: ${product.basePriceUSD}</Text>
                                        {getProductWarranty(product.product) ? (
                                          <Text style={{ fontSize: '10px', marginTop: '5px' }}>{getProductWarranty(product.product)}* ( see page {warrantyPage} )</Text>
                                        ) : null}
                                        {isProductLevel2Certified(product.product) ? (
                                          <>
                                            <Text style={{ fontSize: '10px', marginTop: '3px' }}>Level 2 Certified</Text>
                                          </>
                                        ) : null}
                                        <Text style={{ fontSize: '14px', fontWeight: 600, marginTop: '20px', marginBottom: '10px' }}>Options</Text>
                                        {product.productVariant !== 'null_opt' ? (
                                          <Text key={1} style={{ fontSize: '14px', marginTop: '5px' }}>- {getDisplayName(product.productVariant)}</Text>
                                        ) : null}
                                        {product.selectedColorway !== 'null_opt' ? (
                                          <Text key={2} style={{ fontSize: '14px', marginTop: '5px' }}>- {getDisplayName(product.selectedColorway)}</Text>
                                        ) : null}
                                        {product.productVariant === 'null_opt' && product.selectedColorway === 'null_opt' ? (
                                          <Text key={2} style={{ fontSize: '14px', marginTop: '5px' }}>{noneString}</Text>
                                        ) : null}
                                      </View>
                                    );
                                  })}
                                </View>
                              )
                          }
                        </View>
                      </View>
                      {footerDiv('YOUR SUMMARY')}
                      {pageNumberDiv(vignetteStartingPage + roomIndex + 1, totalNumberOfPages)}
                    </Page>
                    {
                      chunkedRoomProducts.length > 1 ? (
                        <Page title="YOUR_SUMMARY_CONTENT_ROOM" size={{width: 8.5*72, height: 11*72}} orientation="landscape">
                          <View style={contentPage}>
                            {
                              chunkedRoomProducts.slice(1).map(roomProductChunk => {
                                return (
                                  <View style={[rowCss, { marginTop: '20px', columnGap: '80px' }]}>
                                    {roomProductChunk.map((product, productIndex) => {
                                      const { imageUrl } = getThumbnailUrlsForProduct(product, vignetteBase);
                                      // console.log('imageUrl: ', imageUrl);
                                      return (
                                        <View style={[columnCss, { maxWidth: '20vw', minWidth: '15vw' }]}>
                                          <Image
                                            style={{ width: 'auto' }}
                                            src={{ uri: imageUrl, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
                                          ></Image>
                                          {/* <Html>{ReactDOMServer.renderToStaticMarkup((<ProductThumbnail product={product} style={{ maxHeight: '100px' }} />))}</Html> */}
                                          <Text style={{ fontSize: '18px', marginTop: '15px', }}>{getDisplayName(product.product)}</Text>
                                          <Text style={{ fontSize: '14px', marginTop: '5px' }}>List Price: ${product.basePriceUSD}</Text>
                                          {getProductWarranty(product.product) ? (
                                            <Text style={{ fontSize: '10px', marginTop: '5px' }}>{getProductWarranty(product.product)}* ( see page {warrantyPage} )</Text>
                                          ) : null}
                                          {isProductLevel2Certified(product.product) ? (
                                            <>
                                              <Text style={{ fontSize: '10px', marginTop: '3px' }}>Level 2 Certified</Text>
                                            </>
                                          ) : null}
                                          <Text style={{ fontSize: '14px', fontWeight: 600, marginTop: '20px', marginBottom: '10px' }}>Options</Text>
                                          {product.productVariant !== 'null_opt' ? (
                                            <Text key={1} style={{ fontSize: '14px', marginTop: '5px' }}>- {getDisplayName(product.productVariant)}</Text>
                                          ) : null}
                                          {product.selectedColorway !== 'null_opt' ? (
                                            <Text key={2} style={{ fontSize: '14px', marginTop: '5px' }}>- {getDisplayName(product.selectedColorway)}</Text>
                                          ) : null}
                                          {product.productVariant === 'null_opt' && product.selectedColorway === 'null_opt' ? (
                                            <Text key={2} style={{ fontSize: '14px', marginTop: '5px' }}>{noneString}</Text>
                                          ) : null}
                                        </View>
                                      );
                                    })}
                                  </View>
                                )
                              })
                            }
                          </View>
                        </Page>
                      ) : null
                    }
                  </>
                );
              })}
            </>
          );
        })}
        {ImagePage({ image: Image_03_warranty, title: 'WARRANTY', pageNumber: warrantyPage, totalNumberOfPages })}
        {ImagePage({ image: Image_Company_Logo_Final_Slide, title: 'END_LOGO', pageNumber: warrantyPage + 1, totalNumberOfPages })}
      </Document>
    </PDFViewer>
  );
};
