import { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useMatch,
  Outlet
} from "react-router-dom";
import { Users } from './Users';
import { Media } from './Media';
// import { LeadTimingAndPricing } from './LeadTimingAndPricing';
import { RequireAuth } from './auth/RequireAuth';
import { Login } from './auth/Login';

export const Admin = () => {
  // let path = '/admin', url = '/admin';
  // let { path, url } = useMatch('/admin');
  let { pathnameBase: path } = useMatch('/admin/*');
  let url = path;

  // console.log('use match: ', sldkj)
  const [token, setToken] = useState(localStorage.getItem('token'));
  // const token = localStorage.getItem('token');

  // will it trigger rerender if token is removed?
  // const token = localStorage.getItem('token');
  console.log('token: ', token);

  useEffect(() => {
    
    window.addEventListener('storage', () => {
      // When local storage changes, dump the list to
      // the console.
      console.log('change in storage');
      setToken(localStorage.getItem('token'));
    });
    
       
  }, [])

  if(!token && process.env.REACT_APP_NO_AUTH !== 'true') {
    return <Login setToken={() => {}} />
  }

  const handleLogout = e => {
    e.preventDefault();
    // setToken(null);
    localStorage.removeItem('token');
    window.dispatchEvent(new Event('storage'))
  }

  return (
    <RequireAuth>
      <div className="pt-[45px] min-h-screen">
        <div className="absolute top-0 left-0 right-0 bg-[blue]">
          <div className="flex justify-end">
            <button className="my-[5px] mr-[10px] ml-auto bg-[white]" onClick={handleLogout}>Logout</button>
          </div>
        </div>
        <h2>Admin</h2>
        <ul>
          <li>
            <Link to={`${url}/users`}>Manage Users</Link>
          </li>
          <li>
            <Link to={`${url}/media`}>Manage Media</Link>
          </li>
          <li>
            <Link to={`${url}/leadTimingAndPricing`}>Manage Lead Timing And Pricing</Link>
          </li>
        </ul>
        <div className="">
          <Outlet />
        </div>
      </div>
    </RequireAuth>
  );
}
