import React from 'react'

export const Link = ({ href, alignment, className, children }) => {

  let accentBarClass = 'w-[36px] mx-auto';
  let textJustification = 'justify-center';

  switch (alignment) {
    case 'LEFT':
      accentBarClass = 'w-[40px] ml-0'
      textJustification = 'justify-left'
      break;
  }

  return (
    <a
      href={href}
      type="button"
      className={className}
    >
      <div className={`flex flex-row ${textJustification}`}>
        {children}
      </div>
      <div className={`${accentBarClass} h-[2px] my-[5px] bg-[#FDB913]`}></div>
    </a>
  )
}
