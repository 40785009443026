export const prevArrow = (isThicc, hexColor) => {
  if (isThicc) {
    return (
      <svg id="austins-thicc-prev-arrow" width="22.1810395px" height="40.1197412px" viewBox="0 0 22.1810395 40.1197412" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <g id="Pre-and-Post-Visit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linejoin="round">
              <g id="PV_LandingPage_Vignette2" transform="translate(-187.000000, -1500.940129)" stroke={hexColor} stroke-width="6">
                  <g id="Group-27" transform="translate(160.000000, 1406.000000)">
                      <polyline id="Path-2" points="47 97 30 115 47 133"></polyline>
                  </g>
              </g>
          </g>
      </svg>
    )
  } else {
    return (
      <svg width="19.5905197px" height="38.0598706px" viewBox="0 0 19.5905197 38.0598706" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <g id="Pre-and-Post-Visit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linejoin="round">
              <polyline id="Path-2" stroke={hexColor} stroke-width="3" points="18.5 1.0299353 1.5 19.0299353 18.5 37.0299353"></polyline>
          </g>
      </svg>
    )
  }
};


export const nextArrow = (isThicc, hexColor) => {
  if (isThicc) {
    return (
      <svg width="22.1810395px" height="40.1197412px" viewBox="0 0 22.1810395 40.1197412" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <g id="Pre-and-Post-Visit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linejoin="round">
              <g id="PV_LandingPage_Vignette2" transform="translate(-1710.818961, -1500.940129)" stroke={hexColor} stroke-width="6">
                  <g id="Group-28" transform="translate(160.000000, 1406.000000)">
                      <g id="Group-26" transform="translate(1203.000000, 0.000000)">
                          <polyline id="Path-2" transform="translate(358.500000, 115.000000) scale(-1, 1) translate(-358.500000, -115.000000) " points="367 97 350 115 367 133"></polyline>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    )
  } else {
    return (
      <svg width="19.5905197px" height="38.0598706px" viewBox="0 0 19.5905197 38.0598706" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <g id="Pre-and-Post-Visit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linejoin="round">
              <g id="PV_LandingPage_Vignette2" transform="translate(-1670.909480, -4056.970065)" stroke={hexColor} stroke-width="3">
                  <polyline id="Path-2" transform="translate(1680.500000, 4076.000000) scale(-1, 1) translate(-1680.500000, -4076.000000) " points="1689 4058 1672 4076 1689 4094"></polyline>
              </g>
          </g>
      </svg>
    )
  }
};