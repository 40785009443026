export const mail = (hexColor, width, height) => (
  <svg width={width} height={height} viewBox="0 0 50 30.6818966" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Pre-and-Post-Visit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="PV_LandingPage_Vignette2" transform="translate(-1275.000000, -7331.000000)" fill={hexColor} fill-rule="nonzero">
              <g id="Group-21" transform="translate(1275.000000, 7325.000000)">
                  <g id="noun-email-1082093" transform="translate(0.000000, 6.000000)">
                      <path d="M1.70464,0 C0.779998864,0 0,0.779978572 0,1.70464 L0,28.9772566 C0,29.9018977 0.779978572,30.6818966 1.70464,30.6818966 L48.29536,30.6818966 C49.2200011,30.6818966 50,29.901918 50,28.9772566 L50,1.70464 C50,0.779998864 49.2200214,0 48.29536,0 L1.70464,0 Z M3.6399,2.27251513 L46.3598971,2.27251513 L25.0004058,19.566479 L3.64091461,2.27251513 L3.6399,2.27251513 Z M2.27251513,4.06593317 L15.4299293,14.719299 L2.27251513,26.9179102 L2.27251513,4.06593317 Z M47.7268761,4.06593317 L47.7268761,26.9179102 L34.569462,14.719299 L47.7268761,4.06593317 Z M17.2054902,16.1580107 L24.2898771,21.8928698 L24.2902734,21.8932661 C24.7056329,22.2253873 25.2953626,22.2253873 25.7107222,21.8932661 L32.795109,16.158407 L46.0235456,28.4097778 L3.97826165,28.4097778 L17.2066982,16.158407 L17.2054902,16.1580107 Z" id="Shape"></path>
                  </g>
              </g>
          </g>
      </g>
  </svg>
);

export const text = (hexColor, width, height) => (
  <svg width={width} height={height} viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Pre-and-Post-Visit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="PV_LandingPage_Vignette2" transform="translate(-1296.000000, -7493.000000)" fill={hexColor} fill-rule="nonzero">
              <g id="noun-text-2132247" transform="translate(1296.000000, 7493.000000)">
                  <path d="M30.9428641,0 L4.24999023,0 C1.90653767,0 0,1.90671677 0,4.2505594 L0,33.600042 C0,33.9311183 0.194600475,34.2313499 0.497015926,34.3665389 C0.607793035,34.4157577 0.72481419,34.4400386 0.84151557,34.4400386 C1.04400105,34.4400386 1.2441976,34.3668674 1.40164516,34.2270827 L9.15637944,27.3249983 L19.6913129,27.3249983 L19.6913129,34.6835369 C19.6913129,36.3044784 21.0127448,37.6235251 22.6366173,37.6235251 L35.9233067,37.6235251 L40.598683,41.7867165 C40.7554826,41.9268279 40.955999,42 41.1584844,42 C41.2751774,42 41.3921985,41.9757191 41.5023193,41.9265003 C41.8050713,41.7913112 42,41.4910796 42,41.1600034 L42,23.1000756 C42,21.4791341 40.6821866,20.1600874 39.0629424,20.1600874 L35.2005542,20.1600874 L35.2005542,4.250551 C35.2005542,1.90679237 33.290398,0 30.9423171,0 L30.9428641,0 Z M19.6918009,23.099916 L19.6918009,25.6448538 L8.83625009,25.6448538 C8.62948971,25.6448538 8.43028615,25.7206501 8.27612049,25.8578098 L1.68335091,31.7256063 L1.68335091,4.2501562 C1.68335091,2.83266187 2.83454421,1.67959848 4.25031001,1.67959848 L30.9431839,1.67959848 C32.3629048,1.67959848 33.5180532,2.83266187 33.5180532,4.2501562 L33.5180532,20.1596926 L22.6372569,20.1596926 C21.0133843,20.1596926 19.6919524,21.4787393 19.6919524,23.0996808 L19.6918009,23.099916 Z M39.0634893,21.839921 C39.7551142,21.839921 40.3175158,22.4052808 40.3175158,23.099916 L40.3175158,39.2849713 L36.8048616,36.1573278 C36.6506959,36.0198455 36.4514923,35.9440442 36.244732,35.9440442 L22.6374252,35.9440442 C21.9415339,35.9440442 21.3751518,35.3786845 21.3751518,34.6840493 L21.3751518,26.4858501 L21.3751518,26.4855219 L21.3751518,26.4851938 L21.3751518,23.1002593 C21.3751518,22.4056241 21.9415339,21.8402643 22.6374252,21.8402643 L39.0634893,21.839921 Z" id="Shape"></path>
                  <path d="M6.66009082,11.6314419 L28.5394956,11.6314419 C29.0042983,11.6314419 29.3810112,11.255409 29.3810112,10.7914452 C29.3810112,10.3274815 29.0042983,9.95144859 28.5394956,9.95144859 L6.66009082,9.95144859 C6.19528811,9.95144859 5.81857525,10.3274815 5.81857525,10.7914452 C5.81857525,11.2550814 6.19528811,11.6314419 6.66009082,11.6314419 Z" id="Path"></path>
                  <path d="M29.3810112,16.5319823 C29.3810112,16.0680185 29.0042983,15.6919856 28.5394956,15.6919856 L6.66009082,15.6919856 C6.19528811,15.6919856 5.81857525,16.0680185 5.81857525,16.5319823 C5.81857525,16.995946 6.19528811,17.3719789 6.66009082,17.3719789 L28.5394956,17.3719789 C29.0042983,17.3719789 29.3810112,16.9956184 29.3810112,16.5319823 Z" id="Path"></path>
              </g>
          </g>
      </g>
  </svg>
);

export const PlusIcon = (
    <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Pre-and-Post-Visit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.40108817">
          <g id="Extra-Large" transform="translate(-559.000000, -727.000000)">
              <g id="Group-11" transform="translate(559.000000, 648.000000)">
                  <g id="Group" transform="translate(0.000000, 79.000000)">
                      <g id="Layer_2" transform="translate(3.000000, 3.000000)" fill="#000000" fill-rule="nonzero">
                          <path d="M13,26 C5.82029825,26 0,20.1797017 0,13 C0,5.82029825 5.82029825,0 13,0 C20.1797017,0 26,5.82029825 26,13 C26,16.4478144 24.6303611,19.7544152 22.1923882,22.1923882 C19.7544152,24.6303611 16.4478144,26 13,26 Z M13,2 C6.92486775,2 2,6.92486775 2,13 C2,19.0751322 6.92486775,24 13,24 C19.0751322,24 24,19.0751322 24,13 C24,10.0826186 22.8410748,7.28472557 20.7781746,5.22182541 C18.7152744,3.15892524 15.9173814,2 13,2 Z" id="Shape"></path>
                          <path d="M13,20 C12.4477153,20 12,19.5522847 12,19 L12,7 C12,6.44771525 12.4477153,6 13,6 C13.5522847,6 14,6.44771525 14,7 L14,19 C14,19.5522847 13.5522847,20 13,20 Z" id="Path"></path>
                          <path d="M19,14 L7,14 C6.44771525,14 6,13.5522847 6,13 C6,12.4477153 6.44771525,12 7,12 L19,12 C19.5522847,12 20,12.4477153 20,13 C20,13.5522847 19.5522847,14 19,14 Z" id="Path"></path>
                      </g>
                      <g id="frame">
                          <rect id="Rectangle" x="0" y="0" width="32" height="32"></rect>
                      </g>
                  </g>
              </g>
          </g>
      </g>
    </svg>
  );
  
export const MinusIcon = (
    <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Pre-and-Post-Visit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.40108817">
          <g id="Extra-Large" transform="translate(-559.000000, -727.000000)">
              <g id="Group-11" transform="translate(559.000000, 648.000000)">
                  <g id="Group" transform="translate(0.000000, 79.000000)">
                      <g id="Layer_2" transform="translate(3.000000, 3.000000)" fill="#000000" fill-rule="nonzero">
                          <path d="M13,26 C5.82029825,26 0,20.1797017 0,13 C0,5.82029825 5.82029825,0 13,0 C20.1797017,0 26,5.82029825 26,13 C26,16.4478144 24.6303611,19.7544152 22.1923882,22.1923882 C19.7544152,24.6303611 16.4478144,26 13,26 Z M13,2 C6.92486775,2 2,6.92486775 2,13 C2,19.0751322 6.92486775,24 13,24 C19.0751322,24 24,19.0751322 24,13 C24,10.0826186 22.8410748,7.28472557 20.7781746,5.22182541 C18.7152744,3.15892524 15.9173814,2 13,2 Z" id="Shape"></path>
                          <path d="M19,14 L7,14 C6.44771525,14 6,13.5522847 6,13 C6,12.4477153 6.44771525,12 7,12 L19,12 C19.5522847,12 20,12.4477153 20,13 C20,13.5522847 19.5522847,14 19,14 Z" id="Path"></path>
                      </g>
                      <g id="frame">
                          <rect id="Rectangle" x="0" y="0" width="32" height="32"></rect>
                      </g>
                  </g>
              </g>
          </g>
      </g>
    </svg>
  );