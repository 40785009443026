import highlight1 from "../assets/service_highlights_1.png";
import highlight2 from "../assets/service_highlights_2.png";
import highlight3 from "../assets/service_highlights_3.png";
import highlight4 from "../assets/service_highlights_4.png";

export const ServiceHighlights = () => {
  return (
    <section id="service-highlights" className="sm:mt-[100px] lg:mt-[192px] px-[30px] sm:px-0 pt-[60px]">
      <div className="table clear-both w-full">
        <div className="panel float-left w-full sm:w-1/2 flex items-center">
          <img src={highlight1} />
        </div>
        <div className="panel float-left w-full sm:w-1/2 flex items-center">
          <div className="flex">
            <div className="text w-full sm:w-[60%] mx-auto text-left my-auto">
              <div className="h-full my-auto">
                <div className="text-3xl highlight-header">Modular Walls</div>
                <br/>
                <p className="text-sm leading-6 highlight-subtext">
                  Sophisticated, pre-assembled walls are engineered with functional simplicity and adaptability to move with changing workspaces.
                </p>
                {/* <a href="#" className="text-[#191919]">LEARN MORE</a> */}
                <div className="h-2"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table clear-both w-full">
        <div className="panel sm:float-left table-header-group sm:flex w-full sm:w-1/2 items-center">
          <div className="flex">
            <div className="text w-full sm:w-[60%] mx-auto text-left my-auto">
              <div className="h-full my-auto">
                <div className="text-3xl highlight-header">Furniture</div>
                <br/>
                <p className="text-sm leading-6 highlight-subtext">
                Where you work, should work together for you. Our Furniture are designed to create a cohesive experience across multiple touchpoints, including tables, seating, storage, surfaces, and more.
                </p>
                {/* <a href="#" className="text-[#191919]">LEARN MORE</a> */}
              <div className="h-2"/>
            </div>
          </div>
        </div>
        </div>
        <div className="panel sm:float-left table-caption w-full sm:w-1/2 sm:flex items-center">
          <img src={highlight2} />
        </div>
      </div>
      <div className="table clear-both w-full">
        <div className="panel float-left w-full sm:w-1/2 flex items-center">
          <img src={highlight3} />
        </div>
        <div className="panel float-left w-full sm:w-1/2 flex items-center">
          <div className="flex">
            <div className="text w-full sm:w-[60%] mx-auto text-left my-auto">
              <div className="h-full my-auto">
                <div className="text-3xl highlight-header">Workspace</div>
                <br/>
                <p className="text-sm leading-6 highlight-subtext">
                  Make the most of your space with our flexible line of monitor arms, adaptable power source solutions, dynamic lighting, keyboard managers, risers, foot and back supports. Bring comfort, organization and productivity to your workspace.
                </p>
                {/* <a href="#" className="text-[#191919]">LEARN MORE</a> */}
                <div className="h-2"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table clear-both w-full">
        <div className="panel sm:float-left table-header-group sm:flex w-full sm:w-1/2 items-center">
          <div className="flex">
            <div className="text w-full sm:w-[60%] mx-auto text-left my-auto">
              <div className="h-full my-auto">
                <div className="text-3xl highlight-header">AQM</div>
                <br/>
                <p className="text-sm leading-6 highlight-subtext">
                  Commercial grade air purifiers bring together smart and effective technology in an integrated and reliable solution to remove 99.97% of all airborne contaminants. Fellowes Air Solutions are designed to make your space safe, large or small, private or shared.
                </p>
                {/* <a href="#" className="text-[#191919]">LEARN MORE</a> */}
                <div className="h-2"/>
              </div>
            </div>
          </div>
        </div>
        <div className="panel sm:float-left table-caption w-full sm:w-1/2 sm:flex items-center">
          <img src={highlight4} />
        </div>
      </div>
    </section>
  )
}
