export const metaData = {
    clear_glass_monolithic: {
      thumbnail_path: "/Game/Images/thumbs/materials/glass/clear_glass_monolithic",
      display_name: {
        en_us: "Clear Glass - Monolothic"
      },
      asset_path: "/Game/ArtData/Materials/Glass/Clear_Glass_monolithic"
    },
    clear_glass_low_iron_monolithic: {
      thumbnail_path: "/Game/Images/thumbs/materials/glass/clear_glass_low_iron_monolithic",
      display_name: {
        en_us: "Clear Low Iron - Monolothic"
      },
      asset_path: "/Game/ArtData/Materials/Glass/ClearGlass_LowIron"
    },
    product_volo_framed: {
        thumbnail_path: "/Game/Images/thumbs/products/product_volo",
        asset_name: "NA",
        display_name: {
            en_us: "Volo Framed"
        }
    },
    product_volo_frameless: {
        thumbnail_path: "/Game/Images/thumbs/products/product_volo_frameless",
        asset_name: "NA",
        display_name: {
            "en_us": "Volo Frameless"
        }
    },
    clear_glass_laminated: {
      thumbnail_path: "/Game/Images/thumbs/materials/glass/clear_glass_laminated",
      display_name: {
        en_us: "Clear Glass - Laminated"
      },
      asset_path: "/Game/ArtData/Materials/Glass/Clear_Glass_Laminated"
    },
    frost_glass: {
      thumbnail_path: "/Game/Images/thumbs/materials/glass/frost_glass",
      display_name: {
        en_us: "Frost <60% - 70%"
      },
      asset_path: "/Game/ArtData/Materials/Glass/Frosted_Glass"
    },
    textured_glass_reeded: {
      thumbnail_path: "/Game/Images/thumbs/materials/glass/textured_glass_reeded",
      display_name: {
        en_us: "1/4\" Reeded"
      },
      supplier: "supplier_glass_mirror_craft",
      asset_path: "/Game/ArtData/Materials/Glass/VariaResin/Reed_VR"
    },
    textured_glass_aquatex: {
      thumbnail_path: "/Game/Images/thumbs/materials/glass/textured_glass_aquatex",
      display_name: {
        en_us: "Aquatex"
      },
      supplier: "supplier_glass_mirror_craft",
      asset_path: "/Game/ArtData/Materials/Glass/VariaResin/Aquatex"
    },
    backpainted_glass_designer_white: {
      thumbnail_path: "/Game/Images/thumbs/materials/glass/backpainted_glass_designer_white",
      display_name: {
        en_us: "Designer White (Backpainted)"
      },
      asset_path: "/Game/ArtData/Materials/Glass/BackPaintedGlass/BPG_DesignerWhite",
      flags: [
        "backpainted_glass"
      ]
    },
    backpainted_glass_platinum: {
      thumbnail_path: "/Game/Images/thumbs/materials/glass/backpainted_glass_platinum",
      display_name: {
        en_us: "Platinum (Backpainted)"
      },
      asset_path: "/Game/ArtData/Materials/Glass/BackPaintedGlass/BPG__Platnum",
      flags: [
        "backpainted_glass"
      ]
    },
    backpainted_glass_black: {
      thumbnail_path: "/Game/Images/thumbs/materials/glass/backpainted_glass_black",
      display_name: {
        en_us: "Black (Backpainted)"
      },
      asset_path: "/Game/ArtData/Materials/Glass/BackPaintedGlass/BPG_Black",
      flags: [
        "backpainted_glass"
      ]
    },
    backpainted_glass_stone: {
      thumbnail_path: "/Game/Images/thumbs/materials/glass/backpainted_glass_stone",
      display_name: {
        en_us: "Stone (Backpainted)"
      },
      asset_path: "/Game/ArtData/Materials/Glass/BackPaintedGlass/BPG_Stone",
      flags: [
        "backpainted_glass"
      ]
    },
    backpainted_glass_storm: {
      thumbnail_path: "/Game/Images/thumbs/materials/glass/backpainted_glass_storm",
      display_name: {
        en_us: "Storm (Backpainted)"
      },
      asset_path: "/Game/ArtData/Materials/Glass/BackPaintedGlass/BPG__Storm",
      flags: [
        "backpainted_glass"
      ]
    },
    backpainted_glass_cloud: {
      thumbnail_path: "/Game/Images/thumbs/materials/glass/backpainted_glass_cloud",
      display_name: {
        en_us: "Cloud (Backpainted)"
      },
      asset_path: "/Game/ArtData/Materials/Glass/BackPaintedGlass/BPG_Cloud",
      flags: [
        "backpainted_glass"
      ]
    },
    backpainted_glass_matcha: {
      thumbnail_path: "/Game/Images/thumbs/materials/glass/backpainted_glass_matcha",
      display_name: {
        en_us: "Matcha (Backpainted)"
      },
      asset_path: "/Game/ArtData/Materials/Glass/BackPaintedGlass/BPG__Matcha",
      flags: [
        "backpainted_glass"
      ]
    },
    backpainted_glass_clay: {
      thumbnail_path: "/Game/Images/thumbs/materials/glass/backpainted_glass_clay",
      display_name: {
        en_us: "Clay (Backpainted)"
      },
      asset_path: "/Game/ArtData/Materials/Glass/BackPaintedGlass/",
      flags: [
        "backpainted_glass"
      ]
    },
    backpainted_glass_surf: {
      thumbnail_path: "/Game/Images/thumbs/materials/glass/backpainted_glass_surf",
      display_name: {
        en_us: "Surf (Backpainted)"
      },
      asset_path: "/Game/ArtData/Materials/Glass/BackPaintedGlass/BPG_Surf",
      flags: [
        "backpainted_glass"
      ]
    },
    backpainted_glass_graphite: {
      thumbnail_path: "/Game/Images/thumbs/materials/glass/backpainted_glass_graphite",
      display_name: {
        en_us: "Silver (Backpainted)"
      },
      asset_path: "/Game/ArtData/Materials/Glass/BackPaintedGlass/BPG_Graphite",
      flags: [
        "backpainted_glass"
      ]
    },
    etched_glass_sturm: {
      thumbnail_path: "/Game/Images/thumbs/materials/glass/etched_glass_sturm",
      display_name: {
        en_us: "Sturm (Etched)"
      },
      supplier: "supplier_3form",
      asset_path: "/Game/ArtData/Materials/Glass/Etch/Strum"
    },
    etched_glass_horizon: {
      thumbnail_path: "/Game/Images/thumbs/materials/glass/etched_glass_horizon",
      display_name: {
        en_us: "Horizon (Etched)"
      },
      supplier: "supplier_3form",
      asset_path: "/Game/ArtData/Materials/Glass/Etch/Horizon"
    },
    etched_glass_sateen: {
      thumbnail_path: "/Game/Images/thumbs/materials/glass/etched_glass_sateen",
      display_name: {
        en_us: "Sateen (Etched)"
      },
      supplier: "supplier_skyline_design",
      asset_path: "/Game/ArtData/Materials/Glass/Etch/Santeen"
    },
    etched_glass_branch: {
      thumbnail_path: "/Game/Images/thumbs/materials/glass/etched_glass_branch",
      display_name: {
        en_us: "Branch (Etched)"
      },
      supplier: "supplier_3form",
      asset_path: "/Game/ArtData/Materials/Glass/Etch/Branch"
    },
    etched_glass_cloy: {
      thumbnail_path: "/Game/Images/thumbs/materials/glass/etched_glass_cloy",
      display_name: {
        en_us: "Cloy (Etched)"
      },
      supplier: "supplier_3form",
      asset_path: "/Game/ArtData/Materials/Glass/Etch/Cloy"
    },
    etched_glass_sateen_bronze: {
      thumbnail_path: "/Game/Images/thumbs/materials/glass/etched_glass_sateen_bronze",
      display_name: {
        en_us: "Sateen Bronze (Etched)"
      },
      supplier: "supplier_skyline_design",
      asset_path: "/Game/ArtData/Materials/Glass/Etch/SanteenBronze"
    },
    resin_horizon_day: {
      thumbnail_path: "/Game/Images/thumbs/materials/acrylics_and_resin/resin_horizon_day",
      display_name: {
        en_us: "Horizon - Day"
      },
      supplier: "supplier_3form",
      asset_path: "/Game/ArtData/Materials/Glass/VariaResin/HorizonDay"
    },
    resin_strand_white: {
      thumbnail_path: "/Game/Images/thumbs/materials/acrylics_and_resin/resin_strand_white",
      display_name: {
        en_us: "Strand - White"
      },
      supplier: "supplier_3form",
      asset_path: "/Game/ArtData/Materials/Glass/VariaResin/StrandWhite"
    },
    resin_veild_coastline: {
      thumbnail_path: "/Game/Images/thumbs/materials/acrylics_and_resin/resin_veild_coastline",
      display_name: {
        en_us: "Veild - Coastline"
      },
      supplier: "supplier_3form",
      asset_path: "/Game/ArtData/Materials/Glass/VariaResin/veilCoastline"
    },
    resin_hem_ashland: {
      thumbnail_path: "/Game/Images/thumbs/materials/acrylics_and_resin/resin_hem_ashland",
      display_name: {
        en_us: "Hem - Ashland"
      },
      supplier: "supplier_3form",
      asset_path: "/Game/ArtData/Materials/Glass/VariaResin/Hem_Ashland"
    },
    resin_hem_thames: {
      thumbnail_path: "/Game/Images/thumbs/materials/acrylics_and_resin/resin_hem_thames",
      display_name: {
        en_us: "Hem - Thames"
      },
      supplier: "supplier_3form",
      asset_path: "/Game/ArtData/Materials/Glass/VariaResin/Hem_Thomas"
    },
    resin_carbon_2_quattro: {
      thumbnail_path: "/Game/Images/thumbs/materials/acrylics_and_resin/resin_carbon_2_quattro",
      display_name: {
        en_us: "Carbon 2 - Quattro"
      },
      supplier: "supplier_3form",
      asset_path: "/Game/ArtData/Materials/Glass/VariaResin/Carbon"
    },
    resin_vita_orbit: {
      thumbnail_path: "/Game/Images/thumbs/materials/acrylics_and_resin/resin_vita_orbit",
      display_name: {
        en_us: "Vita - Orbit"
      },
      supplier: "supplier_3form",
      asset_path: "/Game/ArtData/Materials/Glass/VariaResin/VIta_Orbit"
    },
    resin_traffic_clear: {
      thumbnail_path: "/Game/Images/thumbs/materials/acrylics_and_resin/resin_traffic_clear",
      display_name: {
        en_us: "Traffic - Clear"
      },
      supplier: "supplier_3form",
      asset_path: "/Game/ArtData/Materials/Glass/VariaResin/Traffic"
    },
    resin_connection_pure: {
      thumbnail_path: "/Game/Images/thumbs/materials/acrylics_and_resin/resin_connection_pure",
      display_name: {
        en_us: "Connection - Pure"
      },
      supplier: "supplier_3form",
      asset_path: "/Game/ArtData/Materials/Glass/VariaResin/Connection"
    },
    resin_fray_smoke: {
      thumbnail_path: "/Game/Images/thumbs/materials/acrylics_and_resin/resin_fray_smoke",
      display_name: {
        en_us: "Fray - Smoke"
      },
      supplier: "supplier_3form",
      asset_path: "/Game/ArtData/Materials/Glass/VariaResin/FraySmoke"
    },
    laminate_black: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_black",
      display_name: {
        en_us: "Black"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Solids/Black"
    },
    laminate_charcoal: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_charcoal",
      display_name: {
        en_us: "Charcoal"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Solids/Charcoal"
    },
    laminate_designer_white: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_designer_white",
      display_name: {
        en_us: "Designer White"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Solids/DesignerWhite"
    },
    laminate_light_gray: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_light_gray",
      display_name: {
        en_us: "Light Gray"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Solids/LightGray"
    },
    laminate_stucco: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_stucco",
      display_name: {
        en_us: "Stucco"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Solids/Stucco"
    },
    laminate_sand: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_sand",
      display_name: {
        en_us: "Sand"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Solids/Sand"
    },
    laminate_graphite: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_graphite",
      display_name: {
        en_us: "Graphite"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Solids/Graphite"
    },
    laminate_crisp_linen: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_crisp_linen",
      display_name: {
        en_us: "Crisp Linen"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Patterned/CrispLinen"
    },
    laminate_classic_linen: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_classic_linen",
      display_name: {
        en_us: "Classic Linen"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Patterned/ClassicLinen"
    },
    laminate_casual_linen: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_casual_linen",
      display_name: {
        en_us: "Casual Linen"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Patterned/CasualLinen"
    },
    laminate_white_tigris: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_white_tigris",
      display_name: {
        en_us: "White Tigris"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Patterned/WhiteTigrise"
    },
    laminate_evening_tigris: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_evening_tigris",
      display_name: {
        en_us: "Evening Tigris"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Patterned/EveningTigrise"
    },
    laminate_sarum_twill: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_sarum_twill",
      display_name: {
        en_us: "Sarum Twill"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Formica/SarumTwill"
    },
    laminate_earthen_twill: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_earthen_twill",
      display_name: {
        en_us: "Earthen Twill"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Formica/EarthenTwill"
    },
    laminate_graphite_twill: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_graphite_twill",
      display_name: {
        en_us: "Graphite Twill"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Formica/GraphiteTwill"
    },
    laminate_beigewood: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_beigewood",
      display_name: {
        en_us: "Beigewood"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Woods/Beigewood_DM"
    },
    laminate_brazilwood: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_brazilwood",
      display_name: {
        en_us: "Brazilwood"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Woods/Brazilwood"
    },
    laminate_empire_mahogany: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_empire_mahogany",
      display_name: {
        en_us: "Empire Mahogany"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Woods/EmpireMahogany_DM"
    },
    laminate_espresso_pearwood: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_espresso_pearwood",
      display_name: {
        en_us: "Espresso Pearwood"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Richwood/ExpressoPearwood"
    },
    lamiante_finnish_oak: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/lamiante_finnish_oak",
      display_name: {
        en_us: "Finnish Oak"
      },
      supplier: "supplier_formica",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Formica/FinnishOak"
    },
    laminate_fusion_maple: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_fusion_maple",
      display_name: {
        en_us: "Fusion Maple"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Woods/FusionMaple_DM"
    },
    laminate_walnut: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_walnut",
      display_name: {
        en_us: "Walnut"
      },
      supplier: "supplier_formica",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Formica/Walnut"
    },
    laminate_wild_cherry: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_wild_cherry",
      display_name: {
        en_us: "Wild Cherry"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Woods/WildCherry_CM"
    },
    laminate_phantom_ecru: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_phantom_ecru",
      display_name: {
        en_us: "Phantom Ecru"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Woods/PhantomEcru"
    },
    laminate_phantom_pearl: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_phantom_pearl",
      display_name: {
        en_us: "Phantom Pearl"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Woods/PhantomPearl"
    },
    laminate_phantom_charcoal: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_phantom_charcoal",
      display_name: {
        en_us: "Phantom Charcoal"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Woods/PhantomCharcoal_CM"
    },
    laminate_shadow_oak: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_shadow_oak",
      display_name: {
        en_us: "Shadow Oak"
      },
      supplier: "supplier_uniboard",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "xxxxxxxxxx"
    },
    laminate_grey: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_grey",
      display_name: {
        en_us: "Grey"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Solids/Gray"
    },
    laminate_dove_grey: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_dove_grey",
      display_name: {
        en_us: "Dove Grey"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Solids/DoveGray"
    },
    laminate_manitoba_maple: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_manitoba_maple",
      display_name: {
        en_us: "Manitoba Maple"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Woods/ManitobaMaple"
    },
    laminate_pinnacle_walnut: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_pinnacle_walnut",
      display_name: {
        en_us: "Pinnacle Walnut"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Woods/PinnacleWalnut"
    },
    laminate_linen: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_linen",
      display_name: {
        en_us: "Linen"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Solids/Linen"
    },
    laminate_antique_white: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_antique_white",
      display_name: {
        en_us: "Antique White"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Solids/AntiqueWhite"
    },
    laminate_platinum: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_platinum",
      display_name: {
        en_us: "Platinum"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Solids/Platinum"
    },
    laminate_khaki_brown: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_khaki_brown",
      display_name: {
        en_us: "Khaki Brown"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Solids/KhakiBrown"
    },
    laminate_shadow: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_shadow",
      display_name: {
        en_us: "Shadow"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Solids/Shadow"
    },
    laminate_slate_gray: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_slate_gray",
      display_name: {
        en_us: "Slate Gray"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Solids/SlateGrey"
    },
    laminate_walnut_heights: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_walnut_heights",
      display_name: {
        en_us: "Walnut Heights"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Woods/WalnutHeights"
    },
    laminate_asian_night: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_asian_night",
      display_name: {
        en_us: "Asian Night"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Woods/AsianNight"
    },
    laminate_daintree: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_daintree",
      display_name: {
        en_us: "Daintree"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Woods/Daintree"
    },
    laminate_dering_forest: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_dering_forest",
      display_name: {
        en_us: "Dering Forest"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Woods/DeringForest"
    },
    laminate_field_elm: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_field_elm",
      display_name: {
        en_us: "Field Elm"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Woods/FieldElm"
    },
    laminate_uptown_walnut: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_uptown_walnut",
      display_name: {
        en_us: "Uptown Walnut"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Woods/UptownWalnut"
    },
    laminate_neowalnut: {
      thumbnail_path: "/Game/Images/thumbs/materials/laminates/laminate_neowalnut",
      display_name: {
        en_us: "Neowalnut"
      },
      supplier: "supplier_wilsonart",
      asset_path: "/Game/ArtData/Materials/Wood/Laminate_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Woods/Laminate/Woods/NeoWalnut"
    },
    veneer_beigewood: {
      thumbnail_path: "/Game/Images/thumbs/materials/veneer/veneer_beigewood",
      display_name: {
        en_us: "Beigewood"
      },
      asset_path: "/Game/ArtData/Materials/Wood/Veneer/Beigewood_SG"
    },
    veneer_fusion_maple: {
      thumbnail_path: "/Game/Images/thumbs/materials/veneer/veneer_fusion_maple",
      display_name: {
        en_us: "Fusion Maple"
      },
      asset_path: "/Game/ArtData/Materials/Wood/Veneer/FusionMaple"
    },
    veneer_brazilwood: {
      thumbnail_path: "/Game/Images/thumbs/materials/veneer/veneer_brazilwood",
      display_name: {
        en_us: "Brazilwood"
      },
      asset_path: "/Game/ArtData/Materials/Wood/Veneer/Brazilwood"
    },
    veneer_wild_cherry: {
      thumbnail_path: "/Game/Images/thumbs/materials/veneer/veneer_wild_cherry",
      display_name: {
        en_us: "Wild Cherry"
      },
      asset_path: "/Game/ArtData/Materials/Wood/Veneer/WildCherry"
    },
    veneer_empire_mahogany: {
      thumbnail_path: "/Game/Images/thumbs/materials/veneer/veneer_empire_mahogany",
      display_name: {
        en_us: "Empire Mahogany"
      },
      asset_path: "/Game/ArtData/Materials/Wood/Veneer/EmpireMahogany"
    },
    painted_finish_silver: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_silver",
      display_name: {
        en_us: "Silver"
      },
      asset_path: "/Game/ArtData/Materials/PaintedMetal/Silver"
    },
    painted_finish_black: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_black",
      display_name: {
        en_us: "Black"
      },
      asset_path: "/Game/ArtData/Materials/PaintedMetal/Black"
    },
    painted_finish_white: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_white",
      display_name: {
        en_us: "White"
      },
      asset_path: "/Game/ArtData/Materials/PaintedMetal/White"
    },
    painted_finish_slate: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_slate",
      display_name: {
        en_us: "Slate"
      },
      asset_path: "/Game/ArtData/Materials/PaintedTrim/RisingPrimary/Rising_Azure"
    },
    painted_finish_slate_light: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_slate_light",
      display_name: {
        en_us: "Slate Light"
      },
      asset_path: "/Game/ArtData/Materials/PaintedTrim/RisingPrimary/Rising_SlateLight"
    },
    painted_finish_azure: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_azure",
      display_name: {
        en_us: "Azure"
      },
      asset_path: "/Game/ArtData/Materials/PaintedTrim/RisingPrimary/Rising_Azure"
    },
    painted_finish_azure_light: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_azure_light",
      display_name: {
        en_us: "Azure Light"
      },
      asset_path: "/Game/ArtData/Materials/PaintedTrim/RisingPrimary/Rising_AzureLight"
    },
    painted_finish_sage: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_sage",
      display_name: {
        en_us: "Sage"
      },
      asset_path: "/Game/ArtData/Materials/PaintedTrim/RisingPrimary/Rising_Sage"
    },
    painted_finish_stardust: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_stardust",
      display_name: {
        en_us: "Stardust"
      },
      asset_path: "/Game/ArtData/Materials/PaintedTrim/RisingPrimary/Rising_Stardust"
    },
    painted_finish_bright_silver: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_bright_silver",
      display_name: {
        en_us: "Bright Silver"
      },
      asset_path: "/Game/ArtData/Materials/PaintedTrim/RisingPrimary/Rising_BrightSilver"
    },
    painted_finish_bronze: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_bronze",
      display_name: {
        en_us: "Bronze"
      },
      asset_path: "/Game/ArtData/Materials/PaintedTrim/RisingPrimary/Rising_Bronze"
    },
    painted_finish_polished_aluminum: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_polished_aluminum",
      display_name: {
        en_us: "Polished Aluminum"
      },
      asset_path: "/Game/ArtData/Materials/PaintedTrim/RisingPrimary/Rising_Chrome"
    },
    painted_finish_designer_white: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_designer_white",
      display_name: {
        en_us: "Designer White"
      },
      asset_path: "/Game/ArtData/Materials/PaintedTrim/Trim_Flat/Trim_DesignerWhite"
    },
    painted_finish_designer_white_textured: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_designer_white_textured",
      display_name: {
        en_us: "Designer White Textured"
      },
      asset_path: "/Game/ArtData/Materials/PaintedTrim/Trim_Flat/Trim_DesignerWhite_Textured"
    },
    painted_finish_light_gray: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_light_gray",
      display_name: {
        en_us: "Light Gray"
      },
      asset_path: "/Game/ArtData/Materials/PaintedTrim/Trim_Flat/Trim_LightGray"
    },
    painted_finish_light_gray_textured: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_light_gray_textured",
      display_name: {
        en_us: "Light Gray Textured"
      },
      asset_path: "/Game/ArtData/Materials/PaintedTrim/Trim_Flat/Trim_LightGray_Textured"
    },
    painted_finish_sand: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_sand",
      display_name: {
        en_us: "Sand"
      },
      asset_path: "/Game/ArtData/Materials/PaintedTrim/Trim_Flat/Trim_Sand"
    },
    painted_finish_sand_textured: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_sand_textured",
      display_name: {
        en_us: "Sand Textured"
      },
      asset_path: "/Game/ArtData/Materials/PaintedTrim/Trim_Flat/Trim_Sand_Textured",
      texture_path: "xxxxxxxxxx"
    },
    painted_finish_gray: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_gray",
      display_name: {
        en_us: "Gray"
      },
      asset_path: "/Game/ArtData/Materials/PaintedTrim/Trim_Flat/Trim_Gray"
    },
    painted_finish_gray_textured: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_gray_textured",
      display_name: {
        en_us: "Gray Textured"
      },
      asset_path: "/Game/ArtData/Materials/PaintedTrim/Trim_Flat/Trim_Gray_Textured"
    },
    painted_finish_charcoal: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_charcoal",
      display_name: {
        en_us: "Charcoal"
      },
      asset_path: "/Game/ArtData/Materials/PaintedTrim/Trim_Flat/Trim_Charcoal"
    },
    painted_finish_charcoal_textured: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_charcoal_textured",
      display_name: {
        en_us: "Charcoal Textured"
      },
      asset_path: "/Game/ArtData/Materials/PaintedTrim/Trim_Flat/Trim_Charcoal_Textured"
    },
    painted_finish_platinum: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_platinum",
      display_name: {
        en_us: "Platinum"
      },
      asset_path: "/Game/ArtData/Materials/PaintedTrim/Trim_Flat/Trim_Platinum"
    },
    painted_finish_satin_etch: {
      thumbnail_path: "/Game/Images/thumbs/materials/painted_finish/painted_finish_satin_etch",
      display_name: {
        en_us: "Satin Etch"
      },
      asset_path: "xxxxxxxxxx",
      texture_path: "xxxxxxxxxx"
    },
    framework_cappuccino: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/framework_cappuccino",
      display_name: {
        en_us: "Cappuccino"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_framework",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Framework/Framework_Cappuccino_KK1",
      sku: "KK1"
    },
    framework_dove_gray: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/framework_dove_gray",
      display_name: {
        en_us: "DoveGray"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_framework",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Framework/Framework_DoveGray_BJ7",
      sku: "BJ7"
    },
    framework_espresso: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/framework_espresso",
      display_name: {
        en_us: "Espresso"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_framework",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Framework/Framework_Espresso_KK2",
      sku: "KK2"
    },
    framework_eucalyptus: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/framework_eucalyptus",
      display_name: {
        en_us: "Eucalyptus"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_framework",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Framework/Framework_Eucalyptus_B4Y",
      sku: "B4Y"
    },
    framework_gray_blue: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/framework_gray_blue",
      display_name: {
        en_us: "GrayBlue"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_framework",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Framework/Framework_GrayBlue_B4X",
      sku: "B4X"
    },
    framework_grotto: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/framework_grotto",
      display_name: {
        en_us: "Grotto"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_framework",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Framework/Framework_Grotto_KJX",
      sku: "KJX"
    },
    framework_hunter: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/framework_hunter",
      display_name: {
        en_us: "Hunter"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_framework",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Framework/Framework_Hunter_BFP",
      sku: "BFP"
    },
    framework_marble: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/framework_marble",
      display_name: {
        en_us: "Marble"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_framework",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Framework/Framework_Marble_BFR",
      sku: "BFR"
    },
    framework_sandy_pebble: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/framework_sandy_pebble",
      display_name: {
        en_us: "SandyPebble"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_framework",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Framework/Framework_SandyPebble_B4V",
      sku: "B4V"
    },
    framework_twilight: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/framework_twilight",
      display_name: {
        en_us: "Twilight"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_framework",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Framework/Framework_Twilight_BG8",
      sku: "BG8"
    },
    merge_dove: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/merge_dove",
      display_name: {
        en_us: "Dove"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_merge",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Merge/Merge_Dove_Z42",
      sku: "Z42"
    },
    merge_driftwood: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/merge_driftwood",
      display_name: {
        en_us: "Driftwood"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_merge",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Merge/Merge_Driftwood_Z43",
      sku: "Z43"
    },
    merge_eucalyptus: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/merge_eucalyptus",
      display_name: {
        en_us: "Eucalyptus"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_merge",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Merge/Merge_Eucalyptus_Z44",
      sku: "Z44"
    },
    merge_fawn: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/merge_fawn",
      display_name: {
        en_us: "Fawn"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_merge",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Merge/Merge_Fawn_Z45",
      sku: "Z45"
    },
    merge_fire_engine: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/merge_fire_engine",
      display_name: {
        en_us: "FireEngine"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_merge",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Merge/Merge_FireEngine_Z46",
      sku: "Z46"
    },
    merge_graphite: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/merge_graphite",
      display_name: {
        en_us: "Graphite"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_merge",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Merge/Merge_Graphite_Z47",
      sku: "Z47"
    },
    merge_green_apple: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/merge_green_apple",
      display_name: {
        en_us: "GreenApple"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_merge",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Merge/Merge_GreenApple_Z49",
      sku: "Z49"
    },
    merge_ivy: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/merge_ivy",
      display_name: {
        en_us: "Ivy"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_merge",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Merge/Merge_Ivy_Z4A",
      sku: "Z4A"
    },
    merge_mandarin: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/merge_mandarin",
      display_name: {
        en_us: "Mandarin"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_merge",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Merge/Merge_Mandarin_Z4C",
      sku: "Z4C"
    },
    merge_marshmallow: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/merge_marshmallow",
      display_name: {
        en_us: "Marshmallow"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_merge",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Merge/Merge_Marshmallow_Z4D",
      sku: "ZD4"
    },
    merge_midnight: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/merge_midnight",
      display_name: {
        en_us: "Midnight"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_merge",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Merge/Merge_Midnight_Z4F",
      sku: "Z4F"
    },
    merge_slate: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/merge_slate",
      display_name: {
        en_us: "Slate"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_merge",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Merge/Merge_Slate_Z4H",
      sku: "Z4H"
    },
    amaze_morel: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/amaze_morel",
      display_name: {
        en_us: "Morel"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_amaze",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Amaze/Amaze_Morel_Z4W",
      sku: "Z4W"
    },
    amaze_platinum: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/amaze_platinum",
      display_name: {
        en_us: "Platinum"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_amaze",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Amaze/Amaze_Platinum_Z4T__1_",
      sku: "Z4T"
    },
    amaze_sky: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/amaze_sky",
      display_name: {
        en_us: "Sky"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_amaze",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Amaze/Amaze_Sky_Z4U__1_",
      sku: "Z4U"
    },
    amaze_steel: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/amaze_steel",
      display_name: {
        en_us: "Steel"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_amaze",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Amaze/Amaze_Steel_Z4V",
      sku: "Z4V"
    },
    aurora_cloud: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/aurora_cloud",
      display_name: {
        en_us: "Cloud"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_aurora",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Aurora/Aurora_Cloud_Z55",
      sku: "Z55"
    },
    aurora_frost: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/aurora_frost",
      display_name: {
        en_us: "Frost"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_aurora",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Aurora/Aurora_Frost_Z54",
      sku: "Z54"
    },
    aurora_granite: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/aurora_granite",
      display_name: {
        en_us: "Granite"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_aurora",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Aurora/Aurora_Granite_Z58",
      sku: "Z58"
    },
    aurora_latte: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/aurora_latte",
      display_name: {
        en_us: "Latte"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_aurora",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Aurora/Aurora_Latte_Z56",
      sku: "Z56"
    },
    aurora_pewter: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/aurora_pewter",
      display_name: {
        en_us: "Pewter"
      },
      supplier: "supplier_burlington",
      fabric_pattern: "fabric_pattern_aurora",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Burlington/Aurora/Aurora_Pewter_Z57",
      sku: "Z57"
    },
    chase_coin: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/chase_coin",
      display_name: {
        en_us: "Coin"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_chase",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Chase/Chase_Coin_Z4K",
      sku: "Z4K"
    },
    chase_ecru: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/chase_ecru",
      display_name: {
        en_us: "Ecru"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_chase",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Chase/Chase_Ecru_Z4M",
      sku: "Z4M"
    },
    chase_mushroom: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/chase_mushroom",
      display_name: {
        en_us: "Mushroom"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_chase",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Chase/Chase_Mushroom_Z4P",
      sku: "Z4P"
    },
    chase_pumice: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/chase_pumice",
      display_name: {
        en_us: "Pumice"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_chase",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Chase/Chase_Pumice_Z4R",
      sku: "Z4R"
    },
    chase_shadow: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/chase_shadow",
      display_name: {
        en_us: "Shadow"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_chase",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Chase/Chase_Shadow_Z4N",
      sku: "Z4N"
    },
    intermix_foggy: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/intermix_foggy",
      display_name: {
        en_us: "Foggy"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_intermix",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Intermix/Intermix_Foggy_KDM",
      sku: "KDM"
    },
    intermix_husky: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/intermix_husky",
      display_name: {
        en_us: "Husky"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_intermix",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Intermix/Intermix_Husky_KDK",
      sku: "KDK"
    },
    intermix_neptune: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/intermix_neptune",
      display_name: {
        en_us: "Neptune"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_intermix",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Intermix/Intermix_Neptune_KDT",
      sku: "KDT"
    },
    intermix_rocket_ship: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/intermix_rocket_ship",
      display_name: {
        en_us: "RocketShip"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_intermix",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Intermix/Intermix_RocketShip_KDJ",
      sku: "KDJ"
    },
    intermix_seagull: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/intermix_seagull",
      display_name: {
        en_us: "Seagull"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_intermix",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Intermix/Intermix_Seagull_KDH",
      sku: "KDH"
    },
    intermix_smoky_quartz: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/intermix_smoky_quartz",
      display_name: {
        en_us: "SmokyQuartz"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_intermix",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Intermix/Intermix_SmokyQuartz_KDV",
      sku: "KDV"
    },
    intermix_swan: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/intermix_swan",
      display_name: {
        en_us: "Swan"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_intermix",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Intermix/Intermix_Swan_KDR",
      sku: "KDR"
    },
    mingle_biscuit: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/mingle_biscuit",
      display_name: {
        en_us: "Biscuit"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_mingle",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Mingle/Mingle_Biscuit_Z53",
      sku: "Z53"
    },
    mingle_carbon: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/mingle_carbon",
      display_name: {
        en_us: "Carbon"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_mingle",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Mingle/Mingle_Carbon_Z4X",
      sku: "Z4X"
    },
    mingle_crystal: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/mingle_crystal",
      display_name: {
        en_us: "Crystal"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_mingle",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Mingle/Mingle_Crystal_Z4Y",
      sku: "Z4Y"
    },
    mingle_wheatberry: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/mingle_wheatberry",
      display_name: {
        en_us: "Wheatberry"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_mingle",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Mingle/Mingle_Wheatberry_Z52",
      sku: "Z52"
    },
    mingle_winter: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/mingle_winter",
      display_name: {
        en_us: "Winter"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_mingle",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Mingle/Mingle_Winter_Z51",
      sku: "Z51"
    },
    netiquette_analog: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/netiquette_analog",
      display_name: {
        en_us: "Analog"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_netiquette",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Netiquette/Analog_DM",
      sku: "KK5"
    },
    netiquette_chipset: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/netiquette_chipset",
      display_name: {
        en_us: "Chipset"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_netiquette",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Netiquette/Chipset_DM",
      sku: "KK3"
    },
    netiquette_dashboard: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/netiquette_dashboard",
      display_name: {
        en_us: "Dashboard"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_netiquette",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Netiquette/Netiquette_Dashboard_KK4",
      sku: "KK4"
    },
    netiquette_gamma: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/netiquette_gamma",
      display_name: {
        en_us: "Gamma"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_netiquette",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Netiquette/Netiquette_Gamma_KK8",
      sku: "KK8"
    },
    netiquette_inkjet: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/netiquette_inkjet",
      display_name: {
        en_us: "Inkjet"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_netiquette",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Netiquette/InkJet_DM",
      sku: "KK7"
    },
    netiquette_pixel: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/netiquette_pixel",
      display_name: {
        en_us: "Pixel"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_netiquette",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Netiquette/Pixel_DM",
      sku: "KKC"
    },
    netiquette_refresh: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/netiquette_refresh",
      display_name: {
        en_us: "Refresh"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_netiquette",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Netiquette/Netiquette_Refresh_KKA",
      sku: "KKA"
    },
    netiquette_schema: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/netiquette_schema",
      display_name: {
        en_us: "Schema"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_netiquette",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Netiquette/Netiquette_Schema_KK6",
      sku: "KK6"
    },
    netiquette_vector: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/netiquette_vector",
      display_name: {
        en_us: "Vector"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_netiquette",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Netiquette/Netiquette_Vector_KK9",
      sku: "KK9"
    },
    pursuit_cement: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/pursuit_cement",
      display_name: {
        en_us: "Cement"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_pursuit",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Pursuit/Pursuit_Cement_KDG",
      sku: "KDG"
    },
    pursuit_dove: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/pursuit_dove",
      display_name: {
        en_us: "Dove"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_pursuit",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Pursuit/Pursuit_Dove_KDA",
      sku: "KDA"
    },
    pursuit_elephant: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/pursuit_elephant",
      display_name: {
        en_us: "Elephant"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_pursuit",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Pursuit/Pursuit_Elephant_KDF",
      sku: "KDF"
    },
    pursuit_mist_grey: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/pursuit_mist_grey",
      display_name: {
        en_us: "MistGrey"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_pursuit",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Pursuit/Pursuit_MistGrey_KDC",
      sku: "KDC"
    },
    pursuit_whisper: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/pursuit_whisper",
      display_name: {
        en_us: "Whisper"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_pursuit",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Pursuit/Pursuit_Whisper_KD9",
      sku: "KD9"
    },
    pursuit_white_linen: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/pursuit_white_linen",
      display_name: {
        en_us: "WhiteLinen"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_pursuit",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Pursuit/Pursuit_WhiteLinen",
      sku: "KDD"
    },
    pact_aloe: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/pact_aloe",
      display_name: {
        en_us: "Aloe"
      },
      supplier: "supplier_momentum",
      fabric_pattern: "fabric_pattern_pact",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Momentum/Pact/Pact_Aloe_BM5",
      sku: "BM5"
    },
    pact_coastal: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/pact_coastal",
      display_name: {
        en_us: "Coastal"
      },
      supplier: "supplier_momentum",
      fabric_pattern: "fabric_pattern_pact",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Momentum/Pact/Pact_Coastal_BM8",
      sku: "BM8"
    },
    pact_dove: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/pact_dove",
      display_name: {
        en_us: "Dove"
      },
      supplier: "supplier_momentum",
      fabric_pattern: "fabric_pattern_pact",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Momentum/Pact/Pact_Dove_BMA",
      sku: "BMA"
    },
    pact_fern: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/pact_fern",
      display_name: {
        en_us: "Fern"
      },
      supplier: "supplier_momentum",
      fabric_pattern: "fabric_pattern_pact",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Momentum/Pact/Pact_Fern_BME",
      sku: "BME"
    },
    pact_harbour: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/pact_harbour",
      display_name: {
        en_us: "Harbour"
      },
      supplier: "supplier_momentum",
      fabric_pattern: "fabric_pattern_pact",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Momentum/Pact/Pact_Harbour_BMF",
      sku: "BFM"
    },
    pact_midnight: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/pact_midnight",
      display_name: {
        en_us: "Midnight"
      },
      supplier: "supplier_momentum",
      fabric_pattern: "fabric_pattern_pact",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Momentum/Pact/Pact_Midnight_BMJ",
      sku: "BMJ"
    },
    pact_patina: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/pact_patina",
      display_name: {
        en_us: "Patina"
      },
      supplier: "supplier_momentum",
      fabric_pattern: "fabric_pattern_pact",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Momentum/Pact/Pact_Patina_BMM",
      sku: "BMM"
    },
    pact_putty: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/pact_putty",
      display_name: {
        en_us: "Putty"
      },
      supplier: "supplier_momentum",
      fabric_pattern: "fabric_pattern_pact",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Momentum/Pact/Pact_Putty_BMN",
      sku: "BMN"
    },
    pact_sketch: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/pact_sketch",
      display_name: {
        en_us: "Sketch"
      },
      supplier: "supplier_momentum",
      fabric_pattern: "fabric_pattern_pact",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Momentum/Pact/Pact_Sketch_BMU",
      sku: "BMU"
    },
    pact_taupe: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/pact_taupe",
      display_name: {
        en_us: "Taupe"
      },
      supplier: "supplier_momentum",
      fabric_pattern: "fabric_pattern_pact",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Momentum/Pact/Pact_Taupe_BMW",
      sku: "BMW"
    },
    pact_toasty: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/pact_toasty",
      display_name: {
        en_us: "Toasty"
      },
      supplier: "supplier_momentum",
      fabric_pattern: "fabric_pattern_pact",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Momentum/Pact/Pact_Toasty_BMX",
      sku: "BMX"
    },
    tabby_weave_cement_mix: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/tabby_weave_cement_mix",
      display_name: {
        en_us: "CementMix"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_tabby_weave",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/TabbyWeave/TabbyWeave_CementMix_QQ3",
      sku: "QQ3"
    },
    tabby_weave_crystal_blue: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/tabby_weave_crystal_blue",
      display_name: {
        en_us: "CrystalBlue"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_tabby_weave",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/TabbyWeave/TabbyWeave_CrystalBlue_175",
      sku: "175"
    },
    tabby_weave_grey_mix: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/tabby_weave_grey_mix",
      display_name: {
        en_us: "GreyMix"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_tabby_weave",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/TabbyWeave/TabbyWeave_GrayMix_238",
      sku: "Z38"
    },
    tabby_weave_medium_gray: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/tabby_weave_medium_gray",
      display_name: {
        en_us: "MediumGray"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_tabby_weave",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/TabbyWeave/TabbyWeave_MediumGray_K99",
      sku: "K99"
    },
    tabby_weave_quartz: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/tabby_weave_quartz",
      display_name: {
        en_us: "Quartz"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_tabby_weave",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/TabbyWeave/TabbyWeave_SilverPapier_BV2",
      sku: "380"
    },
    tabby_weave_silver_papier: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/tabby_weave_silver_papier",
      display_name: {
        en_us: "SilverPapier"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_tabby_weave",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/TabbyWeave/CementMix/TabbyWeave_SilverPapier_BV2",
      sku: "BV2"
    },
    tabby_weave_wedgewood: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/tabby_weave_wedgewood",
      display_name: {
        en_us: "Wedgewood"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_tabby_weave",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/TabbyWeave/TabbyWeave_Wedgewood_150",
      sku: "150"
    },
    anchorage_angora: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_angora",
      display_name: {
        en_us: "Angora"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_anchorage",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/Angora_DM",
      sku: "AR1"
    },
    anchorage_asteroid: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_asteroid",
      display_name: {
        en_us: "Asteroid"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_anchorage",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/asteroid_DM",
      sku: "ARO"
    },
    anchorage_aubergine: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_aubergine",
      display_name: {
        en_us: "Aubergine"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_anchorage",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/aubergine_DM",
      sku: "BF4"
    },
    anchorage_birch: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_birch",
      display_name: {
        en_us: "Birch"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_anchorage",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/Birch_DM",
      sku: "AQ8"
    },
    anchorage_cobalt: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_cobalt",
      display_name: {
        en_us: "Cobalt"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_anchorage",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/Cobalt_DM",
      sku: "BF9"
    },
    anchorage_deep_water: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_deep_water",
      display_name: {
        en_us: "DeepWater"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_anchorage",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/DeepWater__DM",
      sku: "AQ7"
    },
    anchorage_graphite: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_graphite",
      display_name: {
        en_us: "Graphite"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_anchorage",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/Graphite_DM",
      sku: "BFM"
    },
    anchorage_green_apple: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_green_apple",
      display_name: {
        en_us: "GreenApple"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_anchorage",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/GreenApple_DM",
      sku: "BFF"
    },
    anchorage_lapis: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_lapis",
      display_name: {
        en_us: "Lapis"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_anchorage",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/Lapis_DM",
      sku: "BFA"
    },
    anchorage_midnight: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_midnight",
      display_name: {
        en_us: "Midnight"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_anchorage",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/Midnight_DM",
      sku: "BFD"
    },
    anchorage_onyx: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_onyx",
      display_name: {
        en_us: "Onyx"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_anchorage",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/Onyx_DM",
      sku: "GEB"
    },
    anchorage_pool: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_pool",
      display_name: {
        en_us: "Pool"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_anchorage",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/Pool_DM",
      sku: "KX1"
    },
    anchorage_pumpkin: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_pumpkin",
      display_name: {
        en_us: "Pumpkin"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_anchorage",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/Pumpkin_DM",
      sku: "BF2"
    },
    anchorage_quarry_blue: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_quarry_blue",
      display_name: {
        en_us: "QuarryBlue"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_anchorage",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/QuarryBlue_DM",
      sku: "AQ6"
    },
    anchorage_red_delicious: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_red_delicious",
      display_name: {
        en_us: "RedDelicious"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_anchorage",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/RedDelicious_DM",
      sku: "BFN"
    },
    anchorage_slate: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_slate",
      display_name: {
        en_us: "Slate"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_anchorage",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/Slate__DM",
      sku: "AQ5"
    },
    anchorage_thistle: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_thistle",
      display_name: {
        en_us: "Thistle"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_anchorage",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/Thistle_DM",
      sku: "BFC"
    },
    anchorage_vanilla: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_vanilla",
      display_name: {
        en_us: "Vanilla"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_anchorage",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/Vanilla_DM",
      sku: "AQ1"
    },
    anchorage_waterfall: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_waterfall",
      display_name: {
        en_us: "Waterfall"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_anchorage",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/Waterfall_DM",
      sku: "BXP"
    },
    anchorage_willow: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_willow",
      display_name: {
        en_us: "Willow"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_anchorage",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/Willow_DM",
      sku: "BFG"
    },
    anchorage_wolf: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_wolf",
      display_name: {
        en_us: "Wolf"
      },
      supplier: "supplier_guilford_of_maine",
      fabric_pattern: "fabric_pattern_anchorage",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/Wolf_DM",
      sku: "BFJ"
    },
    glint_caspian: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/glint_caspian",
      display_name: {
        en_us: "Caspian"
      },
      supplier: "supplier_momentum",
      fabric_pattern: "fabric_pattern_glint",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Momentum/Glint/Gint_Caspian_DM",
      sku: "KG4"
    },
    glint_column: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/glint_column",
      display_name: {
        en_us: "Column"
      },
      supplier: "supplier_momentum",
      fabric_pattern: "fabric_pattern_glint",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Momentum/Glint/Glint_Column_DM",
      sku: "KG5"
    },
    glint_gypsum: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/glint_gypsum",
      display_name: {
        en_us: "Gypsum"
      },
      supplier: "supplier_momentum",
      fabric_pattern: "fabric_pattern_glint",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Momentum/Glint/Glint_Gypsum",
      sku: "KG7"
    },
    glint_opal: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/glint_opal",
      display_name: {
        en_us: "Opal"
      },
      supplier: "supplier_momentum",
      fabric_pattern: "fabric_pattern_glint",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Momentum/Glint/Gint_Opal_DM",
      sku: "KG9"
    },
    glint_pumice: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/glint_pumice",
      display_name: {
        en_us: "Pumice"
      },
      supplier: "supplier_momentum",
      fabric_pattern: "fabric_pattern_glint",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Momentum/Glint/Glint_Pumace_DM",
      sku: "KJ2"
    },
    glint_relic: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/glint_relic",
      display_name: {
        en_us: "Relic"
      },
      supplier: "supplier_momentum",
      fabric_pattern: "fabric_pattern_glint",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Momentum/Glint/Gint_Relic_DM",
      sku: "KGC"
    },
    glint_serene: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/glint_serene",
      display_name: {
        en_us: "Serene"
      },
      supplier: "supplier_momentum",
      fabric_pattern: "fabric_pattern_glint",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Momentum/Glint/Gint_Serene_Dm",
      sku: "KGE"
    },
    glint_shale: {
      thumbnail_path: "/Game/Images/thumbs/materials/fabric/glint_shale",
      display_name: {
        en_us: "Shale"
      },
      supplier: "supplier_momentum",
      fabric_pattern: "fabric_pattern_glint",
      asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
      texture_path: "/Game/ArtData/Textures/Fabric/Momentum/Glint/Gint_Shale_DM",
      sku: "KGF"
    },
      titan_trim_flat: {
        thumbnail_path: "/Game/Images/thumbs/products/options/titan_trim_flat",
        display_name: {
          en_us: "Flat Facade"
        },
        tag: "xxxxxxxxxx"
      },
      titan_trim_low_profile: {
        thumbnail_path: "/Game/Images/thumbs/products/options/titan_trim_low_profile",
        display_name: {
          en_us: "Low Profile Facade"
        },
        tag: "xxxxxxxxxx"
      },
      titan_trim_accent: {
        thumbnail_path: "/Game/Images/thumbs/products/options/titan_trim_accent",
        display_name: {
          en_us: "Accent Facade"
        },
        tag: "xxxxxxxxxx"
      },
      titan_trim_projected: {
        thumbnail_path: "/Game/Images/thumbs/products/options/titan_trim_projected",
        display_name: {
          en_us: "Projected Facade"
        },
        tag: "xxxxxxxxxx"
      },
      single_glazed: {
        thumbnail_path: "/Game/Images/thumbs/products/options/single_glazed",
        display_name: {
          en_us: "Single Panel Glass"
        },
        tag: "xxxxxxxxxx"
      },
      double_glazed: {
        thumbnail_path: "/Game/Images/thumbs/products/options/double_glazed",
        display_name: {
          en_us: "Double Panel Glass"
        },
        tag: "xxxxxxxxxx"
      },
      door_swinging_frameless_glass: {
        thumbnail_path: "/Game/Images/thumbs/products/options/door_swinging_frameless_glass",
        display_name: {
          en_us: "Swinging Frameless Glass"
        },
        tag: "xxxxxxxxxx",
        asset: "TEMP_SWING_GLASS"
      },
      door_swinging_framed_aluminum_4_4_4: {
        thumbnail_path: "/Game/Images/thumbs/products/options/door_swinging_framed_aluminum_4_4_4",
        display_name: {
          en_us: "Swinging Framed Aluminum 4-4-4"
        },
        tag: "xxxxxxxxxx",
        asset: "TEMP_SWING_ALUMINUM_4_4_4"
      },
      door_swinging_framed_aluminum_4_4_10: {
        thumbnail_path: "/Game/Images/thumbs/products/options/door_swinging_framed_aluminum_4_4_10",
        display_name: {
          en_us: "Swinging Framed Aluminum 4-4-10"
        },
        tag: "xxxxxxxxxx",
        asset: "TEMP_SWING_ALUMINUM_4_4_10"
      },
      door_swinging_wood_full_lite: {
        thumbnail_path: "/Game/Images/thumbs/products/options/door_swinging_wood_full_lite",
        display_name: {
          en_us: "Swinging Wood Full Lite"
        },
        tag: "xxxxxxxxxx",
        asset: "TEMP_SWING_WOOD_FULL_LITE"
      },
      door_swinging_wood_solid: {
        thumbnail_path: "/Game/Images/thumbs/products/options/door_swinging_wood_solid",
        display_name: {
          en_us: "Swinging Wood Solid"
        },
        tag: "xxxxxxxxxx",
        asset: "TEMP_SWING_WOOD_SOLID"
      },
      door_sliding_frameless_glass: {
        thumbnail_path: "/Game/Images/thumbs/products/options/door_sliding_frameless_glass",
        display_name: {
          en_us: "Sliding Frameless Glass"
        },
        tag: "xxxxxxxxxx",
        asset: "TEMP_SLIDE_GLASS"
      },
      door_sliding_framed_aluminum_2_2_2: {
        thumbnail_path: "/Game/Images/thumbs/products/options/door_sliding_framed_aluminum_2_2_2",
        display_name: {
          en_us: "Sliding Framed Aluminum 2-2-2"
        },
        tag: "xxxxxxxxxx",
        asset: "TEMP_SLIDE_ALUMINUM_2_2_2"
      },
      door_sliding_framed_aluminum_2_2_4: {
        thumbnail_path: "/Game/Images/thumbs/products/options/door_sliding_framed_aluminum_2_2_4",
        display_name: {
          en_us: "Sliding Framed Aluminum 2-2-4"
        },
        tag: "xxxxxxxxxx",
        asset: "TEMP_SLIDE_ALUMINUM_2_2_4"
      },
      door_sliding_framed_aluminum_4_4_4: {
        thumbnail_path: "/Game/Images/thumbs/products/options/door_sliding_framed_aluminum_4_4_4",
        display_name: {
          en_us: "Sliding Framed Aluminum 4-4-4"
        },
        tag: "xxxxxxxxxx",
        asset: "TEMP_SLIDE_ALUMINUM_4_4_4"
      },



    volo_door_swinging_frameless_glass: {
        thumbnail_path: "/Game/Images/thumbs/products/options/door_swinging_frameless_glass",
        display_name: {
            en_us: "Swinging Frameless Glass"
        },
        tag: "xxxxxxxxxx",
        asset: "TEMP_SWING_GLASS" // ~~~~~ FPO ~~~~~ //
    },
    volo_door_swinging_framed_aluminum_4_4_4: {
        thumbnail_path: "/Game/Images/thumbs/products/options/door_swinging_framed_aluminum_4_4_4",
        display_name: {
            en_us: "Swinging Framed Aluminum 4-4-4"
        },
        tag: "xxxxxxxxxx",
        asset: "TEMP_SWING_ALUMINUM_4_4_4" // ~~~~~ FPO ~~~~~ //
    },
    volo_door_swinging_framed_aluminum_4_4_10: {
        thumbnail_path: "/Game/Images/thumbs/products/options/door_swinging_framed_aluminum_4_4_10",
        display_name: {
            en_us: "Swinging Framed Aluminum 4-4-10"
        },
        tag: "xxxxxxxxxx",
        asset: "TEMP_SWING_ALUMINUM_4_4_10" // ~~~~~ FPO ~~~~~ //
    },
    volo_door_swinging_wood_full_lite: {
        thumbnail_path: "/Game/Images/thumbs/products/options/door_swinging_wood_full_lite",
        display_name: {
            en_us: "Swinging Wood Full Lite"
        },
        tag: "xxxxxxxxxx",
        asset: "TEMP_SWING_WOOD_FULL_LITE" // ~~~~~ FPO ~~~~~ //
    },
    volo_door_swinging_wood_solid: {
        thumbnail_path: "/Game/Images/thumbs/products/options/door_swinging_wood_solid",
        display_name: {
            en_us: "Swinging Wood Solid"
        },
        tag: "xxxxxxxxxx",
        asset: "TEMP_SWING_WOOD_SOLID" // ~~~~~ FPO ~~~~~ //
    },

    volo_door_sliding_frameless_glass: {
        thumbnail_path: "/Game/Images/thumbs/products/options/door_sliding_frameless_glass",
        display_name: {
            en_us: "Sliding Frameless Glass"
        },
        tag: "xxxxxxxxxx",
        asset: "TEMP_SLIDE_GLASS" // ~~~~~ FPO ~~~~~ //
    },
    volo_door_sliding_framed_aluminum_2_2_2: {
        thumbnail_path: "/Game/Images/thumbs/products/options/door_sliding_framed_aluminum_2_2_2",
        display_name: {
            en_us: "Sliding Framed Aluminum 2-2-2"
        },
        tag: "xxxxxxxxxx",
        asset: "TEMP_SLIDE_ALUMINUM_2_2_2" // ~~~~~ FPO ~~~~~ //
    },
    volo_door_sliding_framed_aluminum_2_2_4: {
        thumbnail_path: "/Game/Images/thumbs/products/options/door_sliding_framed_aluminum_2_2_4",
        display_name: {
            en_us: "Sliding Framed Aluminum 2-2-4"
        },
        tag: "xxxxxxxxxx",
        asset: "TEMP_SLIDE_ALUMINUM_2_2_4" // ~~~~~ FPO ~~~~~ //
    },
    volo_door_sliding_framed_aluminum_4_4_4: {
        thumbnail_path: "/Game/Images/thumbs/products/options/door_sliding_framed_aluminum_4_4_4",
        display_name: {
            en_us: "Sliding Framed Aluminum 4-4-4"
        },
        tag: "xxxxxxxxxx",
        asset: "TEMP_SLIDE_ALUMINUM_4_4_4" // ~~~~~ FPO ~~~~~ //
    },




    door_hardware_passage_lever_set: {
        thumbnail_path: "/Game/Images/thumbs/products/options/door_hardware_passage_lever_set",
        display_name: {
          en_us: "Passage Lever Set Door Hardware"
        },
        tag: "PassageLever"
      },
      door_hardware_post_pull_18: {
        thumbnail_path: "/Game/Images/thumbs/products/options/door_hardware_post_pull_18",
        display_name: {
          en_us: "18\" Post Pull Door Hardware"
        },
        tag: "PostPull18"
      },
      door_hardware_post_pull_36: {
        thumbnail_path: "/Game/Images/thumbs/products/options/door_hardware_post_pull_36",
        display_name: {
          en_us: "36\" Post Pull Door Hardware"
        },
        tag: "PostPull36"
      },
      door_hardware_post_pull_48: {
        thumbnail_path: "/Game/Images/thumbs/products/options/door_hardware_post_pull_48",
        display_name: {
          en_us: "48\" Post Pull Door Hardware"
        },
        tag: "PostPull48"
      },
      door_hardware_post_pull_72: {
        thumbnail_path: "/Game/Images/thumbs/products/options/door_hardware_post_pull_72",
        display_name: {
          en_us: "72\" Post Pull Door Hardware"
        },
        tag: "PostPull72"
      },
      hardware_option_classic: {
        thumbnail_path: "/Game/Images/thumbs/products/options/hardware_option_classic",
        display_name: {
          en_us: "Classic Hardware"
        },
        tag: "Classic"
      },
      hardware_option_streamline: {
        thumbnail_path: "/Game/Images/thumbs/products/options/hardware_option_streamline",
        display_name: {
          en_us: "Streamline Hardware"
        },
        tag: "Streamline"
      },
      hardware_option_designer: {
        thumbnail_path: "/Game/Images/thumbs/products/options/hardware_option_designer",
        display_name: {
          en_us: "Designer Hardware"
        },
        tag: "Designer"
      },
      hardware_option_mod2: {
        thumbnail_path: "/Game/Images/thumbs/products/options/hardware_option_mod2",
        display_name: {
          en_us: "Mod2 Hardware"
        },
        tag: "Mod2"
      },
      hardware_option_modern: {
        thumbnail_path: "/Game/Images/thumbs/products/options/hardware_option_modern",
        display_name: {
          en_us: "Modern Hardware"
        },
        tag: "Modern"
      },
      rising_config_single: {
        thumbnail_path: "/Game/Images/thumbs/products/options/rising_config_single",
        display_name: {
          en_us: "Single Arm"
        },
        tag: "xxxxxxxxxx"
      },
      rising_config_double: {
        thumbnail_path: "/Game/Images/thumbs/products/options/rising_config_double",
        display_name: {
          en_us: "Dual Arm"
        },
        tag: "xxxxxxxxxx"
      },
      sena_config_single: {
        thumbnail_path: "/Game/Images/thumbs/products/options/sena_config_single",
        display_name: {
          en_us: "Single Arm"
        },
        tag: "xxxxxxxxxx"
      },
      sena_config_double: {
        thumbnail_path: "/Game/Images/thumbs/products/options/sena_config_double",
        display_name: {
          en_us: "Dual Arm"
        },
        tag: "xxxxxxxxxx"
      },
      aqm_enabled: {
        thumbnail_path: "/Game/Images/thumbs/products/options/aqm_enabled",
        display_name: {
          en_us: "Include Array\""
        },
        tag: "xxxxxxxxxx"
      },
      aqm_disabled: {
        thumbnail_path: "/Game/Images/thumbs/products/options/aqm_disabled",
        display_name: {
          en_us: "Exclude Array\""
        },
        tag: "xxxxxxxxxx"
      },

        titan_office_1: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/titan_office_1",
          display_name: {
            en_us: "Titan Office 1"
          }
        },
        titan_office_2: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/titan_office_2",
          display_name: {
            en_us: "Titan Office 2"
          }
        },
        titan_office_3: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/titan_office_3",
          display_name: {
            en_us: "Titan Office 3"
          }
        },
        titan_conference_1: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/titan_conference_1",
          display_name: {
            en_us: "Titan Conference 1"
          }
        },
        titan_conference_2: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/titan_conference_2",
          display_name: {
            en_us: "Titan Conference 2"
          }
        },
        titan_conference_3: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/titan_conference_3",
          display_name: {
            en_us: "Titan Conference 3"
          }
        },
        titan_conference_4: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/titan_conference_4",
          display_name: {
            en_us: "Titan Conference 4"
          }
        },
        trig_exec_office_1: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/trig_exec_office_1",
          display_name: {
            en_us: "Furniture 1"
          },
          asset_name: "Trig_ExecutiveOffice1_Assembled",
          vignette_compatibility: [
            "ExecutiveOffice"
          ],
          product_skus: [
            "TRGBF2LATCS75",
            "TRGBSWSL19",
            "TRGPND1P1UW",
            "TRGRECS3072.PNDR.GN",
            "TRGULEG30",
            "ZFWS30H.FD"
          ]
        },
        trig_exec_office_2: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/trig_exec_office_2",
          display_name: {
            en_us: "Furniture 2"
          },
          asset_name: "BP_Trig_ExecOffice2_Assembly",
          vignette_compatibility: [
            "ExecutiveOffice"
          ],
          product_skus: [
            "REJ2SREC3072TFLTSGN",
            "TRGMOD54",
            "TRGODS72.66L",
            "TRGRECS2072.NN.GN",
            "TRGTS1212OS66",
            "TRGUDLAT20",
            "TRGULEG20"
          ]
        },
        trig_small_office_1: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/trig_small_office_1",
          display_name: {
            en_us: "Furniture 1"
          },
          asset_name: "BP_Trig_SmallOffice1_Assembly",
          vignette_compatibility: [
            "SmallOffice"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        trig_small_office_2: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/trig_small_office_2",
          display_name: {
            en_us: "Furniture 1"
          },
          asset_name: "BP_Trig_SmallOffice2_Assembly",
          vignette_compatibility: [
            "SmallOffice"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        trig_conference_1: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/trig_conference_1",
          display_name: {
            en_us: "Conference Table 48x96\""
          },
          asset_name: "BP_CT_Trig_Square_48x96_Assembly",
          vignette_compatibility: [
            "LargeConferenceRoom"
          ],
          product_skus: [
            "TRGRECT4896SL"
          ]
        },
        trig_conference_2: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/trig_conference_2",
          display_name: {
            en_us: "Conference Table 48x120\""
          },
          asset_name: "BP_CT_Trig_Square_48x120_Assembly",
          vignette_compatibility: [
            "LargeConferenceRoom"
          ],
          product_skus: [
            "TRGRECT48120SL"
          ]
        },
        trig_open_1: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/trig_open_1",
          display_name: {
            en_us: "Open Floorplan"
          },
          asset_name: "BP_Trig_OpenFloor_SingleTable_Assembled",
          vignette_compatibility: [
            "OpenFloorplan"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        trig_open_2: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/trig_open_2",
          display_name: {
            en_us: "Open Floorplan"
          },
          asset_name: "BP_Trig_OpenFloor_DoubleDesk_Assembled",
          vignette_compatibility: [
            "OpenFloorplan"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        trig_open_3: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/trig_open_3",
          display_name: {
            en_us: "Open Floorplan"
          },
          asset_name: "BP_Trig_OpenFloor_FullDesk_Assembled",
          vignette_compatibility: [
            "OpenFloorplan"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        victory_exec_office_1: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/trig_exec_office_1",
          display_name: {
            en_us: "Victory (Office)"
          },
          asset_name: "BP_Trig_ExecOffice2_Assembly",
          vignette_compatibility: [
            "SmallOffice",
            "ExecutiveOffice"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        victory_open_1: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/victory_open_1",
          display_name: {
            en_us: "Victory 1 (Open Floorplan)"
          },
          asset_name: "BP_Victory_OpenFloorGroup1_Assembled",
          vignette_compatibility: [
            "OpenFloorplan"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        victory_open_2: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/victory_open_2",
          display_name: {
            en_us: "Victory 2 (Open Floorplan)"
          },
          asset_name: "BP_Victory_OpenFloorGroup2_Assembled",
          vignette_compatibility: [
            "OpenFloorplan"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        victory_open_3: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/victory_open_3",
          display_name: {
            en_us: "Victory 3 (Open Floorplan)"
          },
          asset_name: "BP_Victory_OpenFloorGroup3_Assembled",
          vignette_compatibility: [
            "OpenFloorplan"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        capture_open_1: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/capture_open_1",
          display_name: {
            en_us: "Capture 1"
          },
          asset_name: "BP_Capture_Pods_Assembled",
          vignette_compatibility: [
            "OpenFloorplan"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        choices_conference_1: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/choices_conference_1",
          display_name: {
            en_us: "Choices Race 48x96\""
          },
          asset_name: "BP_CT_CH_Race_48x96_Assembly",
          vignette_compatibility: [
            "LargeConferenceRoom"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        choices_conference_2: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/choices_conference_2",
          display_name: {
            en_us: "Choices Race 60x120\""
          },
          asset_name: "BP_CT_CH_Race_48x120_Assembly",
          vignette_compatibility: [
            "LargeConferenceRoom"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        choices_conference_3: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/choices_conference_3",
          display_name: {
            en_us: "Choices Rectangle 48x96\""
          },
          asset_name: "BP_CT_CH_Rect_48x96_Assembly",
          vignette_compatibility: [
            "LargeConferenceRoom"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        choices_conference_4: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/choices_conference_4",
          display_name: {
            en_us: "Choices Rectangle 60x120\""
          },
          asset_name: "BP_CT_CH_Rect_48x120_Assembly",
          vignette_compatibility: [
            "LargeConferenceRoom"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        choices_conference_5: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/choices_conference_5",
          display_name: {
            en_us: "Choices Boat 48x96\""
          },
          asset_name: "BP_CT_Choice_boat_48x96_Assembly",
          vignette_compatibility: [
            "LargeConferenceRoom"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        choices_conference_6: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/choices_conference_6",
          display_name: {
            en_us: "Choices Boat 60x120\""
          },
          asset_name: "BP_CT_Choice_boat_48x120_Assembly",
          vignette_compatibility: [
            "LargeConferenceRoom"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        zona_training_1: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/zona_training_1",
          display_name: {
            en_us: "Zona 1"
          },
          asset_name: "Zona1_Assembled",
          vignette_compatibility: [
            "TrainingRoom"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        zona_training_2: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/zona_training_2",
          display_name: {
            en_us: "Zona 2"
          },
          asset_name: "Zona2_Assembled",
          vignette_compatibility: [
            "TrainingRoom"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        zona_training_3: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/zona_training_3",
          display_name: {
            en_us: "Zona 3"
          },
          asset_name: "Zona3_Assembled",
          vignette_compatibility: [
            "TrainingRoom"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        intrinsic_exec_office_1: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/intrinsic_exec_office_1",
          display_name: {
            en_us: "Intrinsic Office 1"
          },
          asset_name: "BP_Intrinsic_ExecOffice1_Assembly",
          vignette_compatibility: [
            "ExecutiveOffice"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        intrinsic_conference_1: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/intrinsic_conference_1",
          display_name: {
            en_us: "Intrinsic Ractrack 48x96\""
          },
          asset_name: "BP_CT_Intr_RaceT_48x96_Assembly",
          vignette_compatibility: [
            "LargeConferenceRoom"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        intrinsic_conference_2: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/intrinsic_conference_2",
          display_name: {
            en_us: "Intrinsic Racetrack 48x120\""
          },
          asset_name: "BP_CT_Intr_RaceT_48x120_Assembly",
          vignette_compatibility: [
            "LargeConferenceRoom"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        intrinsic_conference_3: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/intrinsic_conference_3",
          display_name: {
            en_us: "Intrinsic Rectangle 48x96\""
          },
          asset_name: "BP_CT_Intrinsic_Square_48x96_Assembly",
          vignette_compatibility: [
            "LargeConferenceRoom"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        intrinsic_conference_4: {
          thumbnail_path: "/Game/Images/thumbs/products/variants/intrinsic_conference_4",
          display_name: {
            en_us: "Intrinsic Rectangle 48x120\""
          },
          asset_name: "BP_CT_Intrinsic_Square_48x120_Assembly",
          vignette_compatibility: [
            "LargeConferenceRoom"
          ],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        rising_single_arm: {
          thumbnail_path: "/Game/Images/thumbs/products/options/rising_config_single",
          display_name: {
            en_us: "Single Arm"
          },
          asset_name: "Rising_SingleMonitorArm_Blueprint",
          vignette_compatibility: [],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        rising_dual_arm: {
          thumbnail_path: "/Game/Images/thumbs/products/options/rising_config_double",
          display_name: {
            en_us: "Dual Arm"
          },
          asset_name: "Rising_DualArm_Monitor_2_Blueprint",
          vignette_compatibility: [],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        rising_loft_22: {
          thumbnail_path: "/Game/Images/thumbs/products/options/rising_loft_22",
          display_name: {
            en_us: "Rising Loft 22\""
          },
          asset_name: "RLM_Small",
          vignette_compatibility: [],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        rising_loft_46: {
          thumbnail_path: "/Game/Images/thumbs/products/options/rising_loft_46",
          display_name: {
            en_us: "Rising Loft 46\""
          },
          asset_name: "RLM_Medium",
          vignette_compatibility: [],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        rising_loft_58: {
          thumbnail_path: "/Game/Images/thumbs/products/options/rising_loft_58",
          display_name: {
            en_us: "Rising Loft 58\""
          },
          asset_name: "RLM_Large",
          vignette_compatibility: [],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        sena_single_arm: {
          thumbnail_path: "/Game/Images/thumbs/products/options/sena_config_single",
          display_name: {
            en_us: "Single Arm"
          },
          asset_name: "Sena_SingleMonArm",
          vignette_compatibility: [],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        sena_dual_arm: {
          thumbnail_path: "/Game/Images/thumbs/products/options/sena_config_double",
          display_name: {
            en_us: "Dual Arm"
          },
          asset_name: "Sena_DualMonArm",
          vignette_compatibility: [],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        lustre_default: {
          thumbnail_path: "/Game/Images/thumbs/products/product_lustre",
          display_name: {
            en_us: "Lustre"
          },
          asset_name: "Lustre",
          vignette_compatibility: [],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        flex_charge_default: {
          thumbnail_path: "/Game/Images/thumbs/products/product_flex_charge",
          display_name: {
            en_us: "Flex-Charge"
          },
          asset_name: "Flex",
          vignette_compatibility: [],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        hana_foot_rest: {
          thumbnail_path: "/Game/Images/thumbs/products/product_hana_foot_rest",
          display_name: {
            en_us: "Hana Footrest"
          },
          asset_name: "Hana_Footrest",
          vignette_compatibility: [],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        hana_laptop_stand: {
          thumbnail_path: "/Game/Images/thumbs/products/product_hana_laptop_stand",
          display_name: {
            en_us: "Hana Laptop Stand"
          },
          asset_name: "Hana_LaptopStand",
          vignette_compatibility: [],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        elea_white: {
          thumbnail_path: "/Game/Images/thumbs/products/options/elea_white",
          display_name: {
            en_us: "White"
          },
          asset_name: "Elea_White",
          vignette_compatibility: [],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        elea_black: {
          thumbnail_path: "/Game/Images/thumbs/products/options/elea_black",
          display_name: {
            en_us: "Black"
          },
          asset_name: "Elea_Black",
          vignette_compatibility: [],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
        aqm_array: {
          thumbnail_path: "/Game/Images/thumbs/products/product_array",
          display_name: {
            en_us: "Array"
          },
          asset_name: "Array_Preview",
          vignette_compatibility: [],
          product_skus: [
            "xxxxxxxxxx"
          ]
        },
          product_titan: {
            thumbnail_path: "/Game/Images/thumbs/products/product_titan",
            asset_name: "TitanPreview",
            display_name: {
              en_us: "Titan"
            }
          },
          product_trig: {
            thumbnail_path: "/Game/Images/thumbs/products/product_trig",
            asset_name: "",
            display_name: {
              en_us: "Office Furniture"
            },
            price_usd: 5387,
            lead_time_days: 42
          },
          product_victory: {
            thumbnail_path: "/Game/Images/thumbs/products/product_victory",
            asset_name: "",
            display_name: {
              en_us: "Victory"
            },
            price_usd: 1354,
            lead_time_days: 2
          },
          product_capture: {
            thumbnail_path: "/Game/Images/thumbs/products/product_capture",
            asset_name: "",
            display_name: {
              en_us: "Capture"
            },
            price_usd: 13252,
            lead_time_days: 42
          },
          product_choices: {
            thumbnail_path: "/Game/Images/thumbs/products/product_choices",
            asset_name: "",
            display_name: {
              en_us: "Choices"
            },
            price_usd: 829,
            lead_time_days: 42
          },
          product_zona: {
            thumbnail_path: "/Game/Images/thumbs/products/product_zona",
            asset_name: "",
            display_name: {
              en_us: "Zona"
            },
            price_usd: 1114,
            lead_time_days: 42
          },
          product_intrinsic: {
            thumbnail_path: "/Game/Images/thumbs/products/product_intrinsic",
            asset_name: "",
            display_name: {
              en_us: "Intrinsic"
            },
            price_usd: 4133,
            lead_time_days: 42
          },
          product_rising: {
            thumbnail_path: "/Game/Images/thumbs/products/product_rising",
            asset_name: "/Game/ProductActors/Rising_SingleMonitorArm_Blueprint",
            display_name: {
              en_us: "Rising"
            },
            workspace_product_category: "workspace_category_monitor_arms"
          },
          product_rising_loft: {
            thumbnail_path: "/Game/Images/thumbs/products/product_rising_loft",
            asset_name: "/Game/ProductActors/RLM_Large",
            display_name: {
              en_us: "Rising Loft"
            },
            workspace_product_category: "workspace_category_table_enhancements"
          },
          product_elea: {
            thumbnail_path: "/Game/Images/thumbs/products/product_elea",
            asset_name: "/Game/ProductActors/Elea_White",
            display_name: {
              en_us: "Elea"
            },
            workspace_product_category: "workspace_category_seating",
            price_usd: 3210,
            lead_time_days: 2
          },
          product_sena: {
            thumbnail_path: "/Game/Images/thumbs/products/product_sena",
            asset_name: "",
            display_name: {
              en_us: "Sena"
            },
            workspace_product_category: "workspace_category_monitor_arms",
            price_usd: 296,
            lead_time_days: 2
          },
          product_lustre: {
            thumbnail_path: "/Game/Images/thumbs/products/product_lustre",
            asset_name: "/Game/ProductActors/Lustre",
            display_name: {
              en_us: "Lustre"
            },
            workspace_product_category: "workspace_category_lighting",
            price_usd: 606,
            lead_time_days: 2
          },
          product_flex_charge: {
            thumbnail_path: "/Game/Images/thumbs/products/product_flex_charge",
            asset_name: "/Game/ProductActors/Flex",
            display_name: {
              en_us: "Flex-Charge"
            },
            workspace_product_category: "workspace_category_power",
            price_usd: 183,
            lead_time_days: 2
          },
          product_hana_foot_rest: {
            thumbnail_path: "/Game/Images/thumbs/products/product_hana_foot_rest",
            asset_name: "",
            display_name: {
              en_us: "Hana (Foot Rest)"
            },
            workspace_product_category: "workspace_category_foot_rest",
            price_usd: 354,
            lead_time_days: 2
          },
          product_hana_laptop_stand: {
            thumbnail_path: "/Game/Images/thumbs/products/product_hana_laptop_stand",
            asset_name: "",
            display_name: {
              en_us: "Hana (Laptop Stand)"
            },
            workspace_product_category: "workspace_category_laptop_stand",
            price_usd: 354,
            lead_time_days: 2
          },
          product_array: {
            thumbnail_path: "/Game/Images/thumbs/products/product_array",
            asset_name: "",
            display_name: {
              en_us: "Array"
            },
            price_usd: 3350,
            lead_time_days: 42
          },

            titan_trim: {
              display_name: {
                en_us: "Trim"
              },
              tag: "xxxxxxxxxx"
            },
            modular_wall_glazing: {
              display_name: {
                en_us: "Glazing"
              },
              tag: "xxxxxxxxxx"
            },
            modular_wall_door_style: {
              display_name: {
                en_us: "Door Style"
              },
              tag: "xxxxxxxxxx"
            },
            modular_wall_door_hardware: {
              display_name: {
                en_us: "Door Hardware"
              },
              tag: "xxxxxxxxxx"
            },
            furniture_assembly_hardware: {
              display_name: {
                en_us: "Hardware"
              },
              tag: "FurnitureAssemblyHardware"
            },
            aqm_status: {
              display_name: {
                en_us: "Include AQM"
              },
              tag: "AQM"
            },
            rising_2_tone_black: {
              thumbnail_path: "/Game/Images/thumbs/products/colorways/rising_2_tone_black",
              display_name: {
                en_us: "2-Tone Black"
              },
              sku: "RSNG-BLK"
            },
            rising_2_tone_platinum: {
              thumbnail_path: "/Game/Images/thumbs/products/colorways/rising_2_tone_platinum",
              display_name: {
                en_us: "2-Tone Platinum"
              },
              sku: "RSNG-PLT"
            },
            rising_designer_white_silver: {
              thumbnail_path: "/Game/Images/thumbs/products/colorways/rising_designer_white_silver",
              display_name: {
                en_us: "Designer White/Silver"
              },
              sku: "RSNG-DWT"
            },
            rising_azure: {
              thumbnail_path: "/Game/Images/thumbs/products/colorways/rising_azure",
              display_name: {
                en_us: "Azure"
              },
              sku: "RSNG-AZR"
            },
            rising_slate: {
              thumbnail_path: "/Game/Images/thumbs/products/colorways/rising_slate",
              display_name: {
                en_us: "Slate"
              },
              sku: "RSNG-SLT"
            },
            rising_stardust: {
              thumbnail_path: "/Game/Images/thumbs/products/colorways/rising_stardust",
              display_name: {
                en_us: "Stardust"
              },
              sku: "RSNG-STR"
            },
            rising_sage: {
              thumbnail_path: "/Game/Images/thumbs/products/colorways/rising_sage",
              display_name: {
                en_us: "Sage"
              },
              sku: "RSNG-SGE"
            },
            rising_black_bronze: {
              thumbnail_path: "/Game/Images/thumbs/products/colorways/rising_black_bronze",
              display_name: {
                en_us: "Black/Bronze"
              },
              sku: "RSNG-BRZ"
            },
            rising_polished_aluminum_designer_white: {
              thumbnail_path: "/Game/Images/thumbs/products/colorways/rising_polished_aluminum_designer_white",
              display_name: {
                en_us: "Polished Aluminum/Designer White"
              },
              sku: "RSNG-POL"
            },
            workspace_accessory_silver: {
              thumbnail_path: "/Game/Images/thumbs/products/colorways/workspace_accessory_silver",
              display_name: {
                en_us: "Silver"
              }
            },
            workspace_accessory_white: {
              thumbnail_path: "/Game/Images/thumbs/products/colorways/workspace_accessory_white",
              display_name: {
                en_us: "White"
              }
            },
            workspace_accessory_black: {
              thumbnail_path: "/Game/Images/thumbs/products/colorways/workspace_accessory_black",
              display_name: {
                en_us: "Black"
              }
            },
            rising_loft_white_silver: {
              thumbnail_path: "/Game/Images/thumbs/products/colorways/rising_loft_white_silver",
              display_name: {
                en_us: "White Silver"
              }
            },
            rising_loft_silver: {
              thumbnail_path: "/Game/Images/thumbs/products/colorways/rising_loft_silver",
              display_name: {
                en_us: "Silver"
              }
            },
            rising_loft_black: {
              thumbnail_path: "/Game/Images/thumbs/products/colorways/rising_loft_black",
              display_name: {
                en_us: "Black"
              }
            },





    etched_glass_cascade: {
        thumbnail_path: "/Game/Images/thumbs/materials/glass/etched_glass_cascade",
        display_name: {
            en_us: "Cascade"
        },
        supplier: "supplier_skyline_design",
        asset_path: "/Game/ArtData/Materials/Glass/Etch/Cascade"
    },
    etched_glass_sateen_batiste: {
        thumbnail_path: "/Game/Images/thumbs/materials/glass/etched_glass_batiste",
        display_name: {
            en_us: "Sateen Batiste"
        },
        supplier: "supplier_skyline_design",
        asset_path: "/Game/ArtData/Materials/Glass/Etch/Batiste"
    },
    etched_glass_sateen_ar110: {
        thumbnail_path: "/Game/Images/thumbs/materials/glass/etched_glass_AR110",
        display_name: {
            en_us: "Sateen AR110"
        },
        supplier: "supplier_skyline_design",
        asset_path: "/Game/ArtData/Materials/Glass/Etch/AR110"
    },
    etched_glass_sateen_shimmer: {
        thumbnail_path: "/Game/Images/thumbs/materials/glass/etched_glass_shimmer",
        display_name: {
            en_us: "Sateen Shimmer"
        },
        supplier: "supplier_skyline_design",
        asset_path: "/Game/ArtData/Materials/Glass/Etch/Shimmer"
    },
    etched_glass_sateen_lines: {
        thumbnail_path: "/Game/Images/thumbs/materials/glass/etched_glass_lines",
        display_name: {
            en_us: "Sateen Lines"
        },
        supplier: "supplier_skyline_design",
        asset_path: "/Game/ArtData/Materials/Glass/Etch/LInes"
    },
    etched_doubleside_glass_sateen_opt2: {
        thumbnail_path: "/Game/Images/thumbs/materials/glass/Sateen_EtchSingle",
        display_name: {
            en_us: "Sateen Option 2"
        },
        supplier: "supplier_skyline_design",
        asset_path: "/Game/ArtData/Materials/Glass/Etch/Sateen_Opt2"
    },
    etched_doubleside_glass_sateen_bronze: {
        thumbnail_path: "/Game/Images/thumbs/materials/glass/etched_glass_sateen_bronze",
        display_name: {
            en_us: "Sateen Bronze"
        },
        supplier: "supplier_skyline_design",
        asset_path: "/Game/ArtData/Materials/Glass/Etch/SateenBronze"
    },

    printed_sateen_warm: {
        thumbnail_path: "/Game/Images/thumbs/materials/glass/printed_sateen_warm",
        display_name: {
            en_us: "Digital Printed Sateen Warm"
        },
        supplier: "supplier_skyline_design",
        asset_path: "/Game/ArtData/Materials/Glass/Digital/Printed_Sateen_Warm"
    },
    printed_sateen_cool: {
        thumbnail_path: "/Game/Images/thumbs/materials/glass/printed_sateen_cool",
        display_name: {
            en_us: "Digital Printed Sateen Cool"
        },
        supplier: "supplier_skyline_design",
        asset_path: "/Game/ArtData/Materials/Glass/Digital/Printed_Sateen_Cool"
    },
    printed_sateen_light: {
        thumbnail_path: "/Game/Images/thumbs/materials/glass/printed_sateen_light",
        display_name: {
            en_us: "Digital Printed Sateen Light"
        },
        supplier: "supplier_skyline_design",
        asset_path: "/Game/ArtData/Materials/Glass/Digital/Printed_Sateen_light"
    },
    printed_sateen_dark: {
        thumbnail_path: "/Game/Images/thumbs/materials/glass/printed_sateen_dark",
        display_name: {
            en_us: "Digital Printed Sateen Dark"
        },
        supplier: "supplier_skyline_design",
        asset_path: "/Game/ArtData/Materials/Glass/Digital/Printed_Sateen_Dark"
    },




    anchorage_eucalyptus: {
        thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_eucalyptus",
        display_name: {
            en_us: "Eucalyptus"
        },
        supplier: "supplier_guilford_of_maine",
        fabric_pattern: "fabric_pattern_anchorage",
        asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
        texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/Eucalyptus_DM",
        sku: "anchorage_eucalyptus"
    },
    anchorage_geranium: {
        thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_geranium",
        display_name: {
            en_us: "Geranium"
        },
        supplier: "supplier_guilford_of_maine",
        fabric_pattern: "fabric_pattern_anchorage",
        asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
        texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/Geranium_DM",
        sku: "anchorage_geranium"
    },
    anchorage_goose: {
        thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_goose",
        display_name: {
            en_us: "Goose"
        },
        supplier: "supplier_guilford_of_maine",
        fabric_pattern: "fabric_pattern_anchorage",
        asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
        texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/Goose_DM",
        sku: "anchorage_goose"
    },
    anchorage_mulberry: {
        thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_mulberry",
        display_name: {
            en_us: "Mulberry"
        },
        supplier: "supplier_guilford_of_maine",
        fabric_pattern: "fabric_pattern_anchorage",
        asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
        texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/Mulberry_DM",
        sku: "anchorage_mulberry"
    },
    anchorage_shadow: {
        thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_shadow",
        display_name: {
            en_us: "Shadow"
        },
        supplier: "supplier_guilford_of_maine",
        fabric_pattern: "fabric_pattern_anchorage",
        asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
        texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/Shadow_DM",
        sku: "anchorage_shadow"
    },
    anchorage_sunshine: {
        thumbnail_path: "/Game/Images/thumbs/materials/fabric/anchorage_sunshine",
        display_name: {
            en_us: "Sunshine"
        },
        supplier: "supplier_guilford_of_maine",
        fabric_pattern: "fabric_pattern_anchorage",
        asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
        texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Anchorage/Sunshine_DM",
        sku: "anchorage_sunshine"
    },

    marin_dolphin: {
        thumbnail_path: "/Game/Images/thumbs/materials/fabric/marin_dolphin",
        display_name: {
            en_us: "Dolphin"
        },
        supplier: "supplier_guilford_of_maine",
        fabric_pattern: "fabric_pattern_marin",
        asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
        texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Marin/Dolphin_DM",
        sku: "marin_dolphin"
    },
    marin_grey_whale: {
        thumbnail_path: "/Game/Images/thumbs/materials/fabric/marin_grey_whale",
        display_name: {
            en_us: "Grey Whale"
        },
        supplier: "supplier_guilford_of_maine",
        fabric_pattern: "fabric_pattern_marin",
        asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
        texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Marin/GreyWhale_DM",
        sku: "marin_grey_whale"
    },


    purpose_ambition: {
        thumbnail_path: "/Game/Images/thumbs/materials/fabric/purpose_ambition",
        display_name: {
            en_us: "Ambition"
        },
        supplier: "supplier_guilford_of_maine",
        fabric_pattern: "fabric_pattern_purpose",
        asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
        texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Purpose/Ambition_DM",
        sku: "purpose_ambition"
    },
    purpose_wish: {
        thumbnail_path: "/Game/Images/thumbs/materials/fabric/purpose_wish",
        display_name: {
            en_us: "Wish"
        },
        supplier: "supplier_guilford_of_maine",
        fabric_pattern: "fabric_pattern_purpose",
        asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
        texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Purpose/Wish_DM",
        sku: "purpose_wish"
    },
    palette_cutch: {
        thumbnail_path: "/Game/Images/thumbs/materials/fabric/palette_cutch",
        display_name: {
            en_us: "Cutch"
        },
        supplier: "supplier_guilford_of_maine",
        fabric_pattern: "fabric_pattern_palette",
        asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
        texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Palette/Cutch_DM",
        sku: "palette_cutch"
    },
    palette_iron: {
        thumbnail_path: "/Game/Images/thumbs/materials/fabric/palette_iron",
        display_name: {
            en_us: "Iron"
        },
        supplier: "supplier_guilford_of_maine",
        fabric_pattern: "fabric_pattern_palette",
        asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
        texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Palette/Iron_DM",
        sku: "palette_iron"
    },

    resolve_fortitude: {
        thumbnail_path: "/Game/Images/thumbs/materials/fabric/resolve_fortitude",
        display_name: {
            en_us: "Fortitude"
        },
        supplier: "supplier_guilford_of_maine",
        fabric_pattern: "fabric_pattern_resolve",
        asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
        texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Resolve/Fortitude_DM",
        sku: "resolve_fortitude"
    },
    resolve_zeal: {
        thumbnail_path: "/Game/Images/thumbs/materials/fabric/resolve_zeal",
        display_name: {
            en_us: "Zeal"
        },
        supplier: "supplier_guilford_of_maine",
        fabric_pattern: "fabric_pattern_resolve",
        asset_path: "/Game/ArtData/Materials/Fabric/Fabric_MasterMaterial",
        texture_path: "/Game/ArtData/Textures/Fabric/Gilford_of_Maine/Resolve/Zeal_DM",
        sku: "resolve_zeal"
    },



    vinyl_designer_white: {
        thumbnail_path: "/Game/Images/thumbs/materials/vinyl/vinyl_designer_white",
        display_name: {
            en_us: "Designer White"
        },
        supplier: "supplier_momentum",
        fabric_pattern: "filter_mat_type_vinyl",
        asset_path: "/Game/ArtData/Materials/VinylFilm/Vinyl_DesignerWhite",
//			texture_path: "/Game/ArtData/Textures/VinylFilm/Vinyl_DesignerWhite_DM",
        sku: "KGF"
    },

    vinyl_charcoal: {
        thumbnail_path: "/Game/Images/thumbs/materials/vinyl/vinyl_charcoal",
        display_name: {
            en_us: "Charcoal"
        },
        supplier: "supplier_momentum",
        fabric_pattern: "filter_mat_type_vinyl",
        asset_path: "/Game/ArtData/Materials/VinylFilm/Vinyl_Charcoal",
//			texture_path: "/Game/ArtData/Textures/VinylFilm/Vinyl_Charcoal_DM",
        sku: "KGF"
    },

    vinyl_gray: {
        thumbnail_path: "/Game/Images/thumbs/materials/vinyl/vinyl_gray",
        display_name: {
            en_us: "Gray"
        },
        supplier: "supplier_momentum",
        fabric_pattern: "filter_mat_type_vinyl",
        asset_path: "/Game/ArtData/Materials/VinylFilm/Vinyl_Gray",
//			texture_path: "/Game/ArtData/Textures/VinylFilm/Vinyl_Gray_DM",
        sku: "KGF"
    },

    vinyl_light_gray: {
        thumbnail_path: "/Game/Images/thumbs/materials/vinyl/vinyl_light_gray",
        display_name: {
            en_us: "Light Gray"
        },
        supplier: "supplier_momentum",
        fabric_pattern: "filter_mat_type_vinyl",
        asset_path: "/Game/ArtData/Materials/VinylFilm/Vinyl_LightGray",
//			texture_path: "/Game/ArtData/Textures/VinylFilm/Vinyl_LightGray_DM",
        sku: "KGF"
    },

    vinyl_sand: {
        thumbnail_path: "/Game/Images/thumbs/materials/vinyl/vinyl_sand",
        display_name: {
            en_us: "Sand"
        },
        supplier: "supplier_momentum",
        fabric_pattern: "filter_mat_type_vinyl",
        asset_path: "/Game/ArtData/Materials/VinylFilm/Vinyl_Sand",
//			texture_path: "/Game/ArtData/Textures/VinylFilm/Vinyl_Sand_DM",
        sku: "KGF"
    },



    atom: {
        thumbnail_path: "/Game/Images/thumbs/materials/ecoustic/atom",
        display_name: {
            en_us: "Atom"
        },
        supplier: "supplier_unika_vaev",
        fabric_pattern: "filter_mat_type_ecoustic_panel",
        asset_path: "/Game/ArtData/Materials/AcousticPanel/Atom",
        sku: "KGF"
    },

    galaxy: {
        thumbnail_path: "/Game/Images/thumbs/materials/ecoustic/galaxy",
        display_name: {
            en_us: "Galaxy"
        },
        supplier: "supplier_unika_vaev",
        fabric_pattern: "filter_mat_type_ecoustic_panel",
        asset_path: "/Game/ArtData/Materials/AcousticPanel/Galaxy",
        sku: "KGF"
    },

    snowdrop: {
        thumbnail_path: "/Game/Images/thumbs/materials/ecoustic/snowdrop",
        display_name: {
            en_us: "Snowdrop"
        },
        supplier: "supplier_unika_vaev",
        fabric_pattern: "filter_mat_type_ecoustic_panel",
        asset_path: "/Game/ArtData/Materials/AcousticPanel/Snowdrop",
        sku: "KGF"
    },


    printed_sateen_satinlite_warm: {
        thumbnail_path: "/Game/Images/thumbs/materials/glass/printed_sateen_satinlite_warm",
        display_name: {
            en_us: "Printed Sateen Warm Satinlite"
        },
        supplier: "supplier_skyline_design",
        asset_path: "/Game/ArtData/Materials/Glass/Digital/Printed_Sateen_Warm_Satinlite"
    },
    printed_sateen_satinlite_cool: {
        thumbnail_path: "/Game/Images/thumbs/materials/glass/printed_sateen_satinlite_cool",
        display_name: {
            en_us: "Printed Sateen Cool Satinlite"
        },
        supplier: "supplier_skyline_design",
        asset_path: "/Game/ArtData/Materials/Glass/Digital/Printed_Sateen_Cool_Satinlite"
    },
    printed_sateen_satinlite_dark: {
        thumbnail_path: "/Game/Images/thumbs/materials/glass/printed_sateen_satinlite_dark",
        display_name: {
            en_us: "Printed Sateen Dark Satinlite"
        },
        supplier: "supplier_skyline_design",
        asset_path: "/Game/ArtData/Materials/Glass/Digital/Printed_Sateen_Dark_Satinlite"
    },
    printed_sateen_satinlite_light: {
        thumbnail_path: "/Game/Images/thumbs/materials/glass/printed_sateen_satinlite_light",
        display_name: {
            en_us: "Printed Sateen Light Satinlite"
        },
        supplier: "supplier_skyline_design",
        asset_path: "/Game/ArtData/Materials/Glass/Digital/Printed_Sateen_light_Satinlite"
    },









    reveal_ceiling_runner: {
        thumbnail_path: "/Game/Images/thumbs/products/options/ceiling_runner_reveal",
        display_name: {
            en_us: "Reveal Ceiling Runners"
        },
        tag: "reveal_ceiling_runner"
    },
    traditional_ceiling_runner: {
        thumbnail_path: "/Game/Images/thumbs/products/options/ceiling_runner_traditional",
        display_name: {
            en_us: "Traditional Ceiling Runners"
        },
        tag: "xxxxxxxxxx"
    },


    panel_style_single: {
        thumbnail_path: "/Game/Images/thumbs/products/options/panel_style_single",
        display_name: {
            en_us: "Single Panels"
        },
        tag: "xxxxxxxxxx"
    },
    panel_style_double: {
        thumbnail_path: "/Game/Images/thumbs/products/options/panel_style_double",
        display_name: {
            en_us: "Double Panels"
        },
        tag: "xxxxxxxxxx"
    },
    panel_style_triple: {
        thumbnail_path: "/Game/Images/thumbs/products/options/panel_style_triple",
        display_name: {
            en_us: "Triple Panels"
        },
        tag: "xxxxxxxxxx"
    },
    panel_style_solid: {
        thumbnail_path: "/Game/Images/thumbs/products/options/panel_style_solid",
        display_name: {
            en_us: "Solid Panels"
        },
        tag: "xxxxxxxxxx"
    },
    panel_style_clerestory: {
        thumbnail_path: "/Game/Images/thumbs/products/options/panel_style_clerestory",
        display_name: {
            en_us: "Clerestory Panels"
        },
        tag: "xxxxxxxxxx"
    },
    panel_style_window_lite: {
        thumbnail_path: "/Game/Images/thumbs/products/options/panel_style_window_lite",
        display_name: {
            en_us: "Window Lite Panels"
        },
        tag: "xxxxxxxxxx"
    },


    aluminum_trim: {
        thumbnail_path: "/Game/Images/thumbs/products/options/volo_trim_alumframed",
        display_name: {
            en_us: "Aluminum Trim"
        },
        tag: "xxxxxxxxxx"
    },
    four_vinyl_trim: {
        thumbnail_path: "/Game/Images/thumbs/products/options/volo_trim_vinyl4",
        display_name: {
            en_us: "4\" Vinyl Trim"
        },
        tag: "four_vinyl_trim"
    },
    six_vinyl_trim: {
        thumbnail_path: "/Game/Images/thumbs/products/options/volo_trim_vinyl6",
        display_name: {
            en_us: "6\" Vinyl Trim"
        },
        tag: "six_vinyl_trim"
    },








}

export const getDisplayName = (key) => {
  if (metaData[key]) {
    return metaData[key].display_name.en_us;
  } else {
    return '<key not found>';
  }
}
