import {
    Admin,
    BooleanInput,
    Create,
    Datagrid,
    DateField,
    Edit,
    FileInput,
    FileField,
    ImageField,
    List,
    required,
    Resource,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    UrlField,
} from 'react-admin';
import React, { useEffect, useState } from 'react';
import { FileInputPlaceholder } from '../../components/CMSUtils.jsx';
import { useParams, useSearchParams } from 'react-router-dom';
import { dataProvider } from '../../utils/cmsDataProvider.js';

    export const CollageContentList = () => (
        <List>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="title" />
                <ImageField source="thumbnail_url" label="preview" />
                <UrlField source="url" />
                <UrlField source="thumbnail_url" />
                <DateField source="created_at" />
                <DateField source="updated_at" />
            </Datagrid>
        </List>
    );

    const typeChoices = [
        { id: 'image', name: 'image' },
        { id: 'video', name: 'video' },
    ]
    
    export const CollageCreate = ({ userId }) => (
        <Create>
            <SimpleForm>
                <TextInput style={{ display: 'none' }} hidden source="isMedia" defaultValue={true} validate={[required()]} />
                <TextInput source="title" validate={[required()]} />
                <FileInput
                    source="file"
                    accept={['image/jpeg', 'video/mp4']}
                    validate={[required()]}
                    placeholder={<FileInputPlaceholder />}
                >
                    <FileField source="src" title="title" />
                </FileInput>
                <BooleanInput source="fullscreen_only" validate={[required()]} />
                <TextInput style={{ display: 'none' }} disabled source="collage_marker_id" defaultValue={userId} validate={[required()]} />
            </SimpleForm>
        </Create>
    );

    export const CollageEdit = () => (
        <Edit>
            <SimpleForm>
                <TextInput disabled source="id" />
                {/* <TextInput style={{ display: 'none' }} hidden source="corresponding_asset_type" validate={[required()]} /> */}
                <TextInput source="title" validate={[required()]} />
                <TextInput source="url" validate={[required()]} />
                <TextInput source="thumbnail_url" validate={[required()]} />
                <BooleanInput source="fullscreen_only" validate={[required()]} />
            </SimpleForm>
        </Edit>
    );


    export const MediaStructList = () => (
        <List>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="corresponding_asset_type" />
                <TextField source="title" />
                <TextField source="type" />
                <ImageField source="thumbnail_url" label="preview" />
                <UrlField source="url" />
                <UrlField source="thumbnail_url" />
                <TextField source="fullscreen_only" />
                <TextField source="insight_token_id" />
                <TextField source="insight_token_category_id" />
                <TextField source="insight_token_category_index" />
                <TextField source="insight_token_sub_category_id" />
                <TextField source="insight_token_sub_category_index" />
                <TextField source="product_key" />
            </Datagrid>
        </List>
    );

    const correspondingAssetTypes = [
        { id: 'COLLAGE_IMAGE', name: 'COLLAGE_IMAGE' },
        { id: 'PRODUCT', name: 'PRODUCT' },
        { id: 'INSIGHT_TOKEN', name: 'INSIGHT_TOKEN' },
    ]

    export const MediaStructCreate = () => (
        <Create>
            <SimpleForm>
                <SelectInput source="corresponding_asset_type" choices={correspondingAssetTypes}  validate={[required()]} />
                <TextInput source="title" validate={[required()]} />
                <FileInput source="file" validate={[required()]} />
                <SelectInput source="type" choices={typeChoices} validate={[required()]} />
                <BooleanInput source="fullscreen_only" defaultValue={false} validate={[required()]} />
                <TextInput source="product_key"  />
                <TextInput source="insight_token_id"  />
                <TextInput source="insight_token_category_id"  />
                <TextInput source="insight_token_category_index"  />
                <TextInput source="insight_token_sub_category_id"  />
                <TextInput source="insight_token_sub_category_index"  />
            </SimpleForm>
        </Create>
    );

    export const MediaStructEdit = () => (
        <Edit>
            <SimpleForm>
                <SelectInput source="corresponding_asset_type" choices={correspondingAssetTypes}  validate={[required()]} />
                <TextInput source="title" validate={[required()]} />
                {/* <FileInput source="file" validate={[required()]} /> */}
                <TextInput source="url" validate={[required()]} />
                <TextInput source="thumbnail_url" validate={[required()]} />
                <SelectInput source="type" choices={typeChoices} validate={[required()]} />
                <BooleanInput source="fullscreen_only" defaultValue={false} validate={[required()]} />
                <TextInput source="product_key"  />
                <TextInput source="insight_token_id"  />
                <TextInput source="insight_token_category_id"  />
                <TextInput source="insight_token_category_index"  />
                <TextInput source="insight_token_sub_category_id"  />
                <TextInput source="insight_token_sub_category_index"  />
            </SimpleForm>
        </Edit>
    );

// const newHistory = createHistory({ basename: '/cms' });

export const CMS = () => {

    const { userId: userId2 } = useParams();


    const [searchParams, setSearchParams] = useSearchParams();
    const [userId, setUserId] = useState('');

    console.log('userId: ', userId);

    useEffect(() => {
        setUserId(userId2);
    }, []);

    if (!userId) {
        return (<div>No user id provided</div>);
    }

      
    return (
        <>
        {
            userId !== 'null' ? (
                <Admin
                    dataProvider={dataProvider}
                    basename={`/userCMS/${userId}`}
                >
                    <Resource
                        name={`images/userCollageContent/${userId}`}
                        list={CollageContentList}
                        edit={CollageEdit}
                        create={CollageCreate({ userId })}
                    />
                </Admin>
            ) : (
                <div>Please edit general media content through the admin portal.</div>
            )
        }
        </>
    );
};