import React from 'react'
import logo from "../assets/Logo.png"

export const RegistrationWrapper = ({ result, children }) => {
  return (
    <div className="min-h-screen mx-auto max-w-7xl px-4 py-24 sm:px-6 sm:py-32 lg:px-8">
      <img className="sm:h-[104px] w-auto mx-auto mb-[70px]" src={logo} alt="logo" />
      {children}
    </div>
  )
}
