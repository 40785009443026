import { useSearchParams } from 'react-router-dom';
import heroImage from "../assets/Bitmap.png"
import PageWrapper from '../components/PageWrapper.jsx';
import { ServiceHighlights } from '../components/ServiceHighlights';

function MainLanding() {

  const [searchParams, setSearchParams] = useSearchParams();
  let isRemoteAttendee = searchParams.get("remote");
  isRemoteAttendee = isRemoteAttendee === 'true';

  return (
    <PageWrapper isRemoteAttendee={isRemoteAttendee}>
      <div id="hero">
        <img src={heroImage} />
      </div>
      <ServiceHighlights />
    </PageWrapper>
  )
}

export default MainLanding