import React from 'react'
import { useLocation } from 'react-router-dom';
import { RegistrationWrapper } from '../components/RegistrationWrapper';

export const Confirmation = () => {

  const { state } = useLocation();

  console.log('state: ', state);

  let message = state && state.body && state.body.msg;
  if (!message) {
    message = (state && !state.isError)
                  ? 'Success! Thank you for registering!'
                  : 'Uh-oh! Something went wrong. Please try again.'
  }

  return (
    <RegistrationWrapper>
      <div className={`${state.isError ? 'text-red-500' : ''} font-bold text-md`}>{message}</div>
    </RegistrationWrapper>
  )
}
