import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getBaseUrl } from '../../../utils';

// import './Login.css';

async function loginUser(credentials) {
 return fetch(`${getBaseUrl()}/auth/adminLogin`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  }).then(response => {
      if (response.status === 400) {
        throw new Error('Invalid username or password');
      }
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.json();
  }).catch((err) => {
      throw err;
  });
}

export function Login({ setToken }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();
    setError('');
    try {
      const { token } = await loginUser({
        username,
        password
      });
      
      console.log('token: ', token);
      // setToken(token);
      localStorage.setItem('token', token);
      window.dispatchEvent(new Event('storage'));
    } catch (err) {
      console.log('err: ', err);
      setError(err.message);
    }
  }

  return(
    <div className="login-wrapper min-h-screen">
      <h1>Please Log In</h1>
      <form onSubmit={handleSubmit}>
        { error ? <p className=' text-[red]'>{error}</p> : null}
        <label>
          <p>Username</p>
          <input type="text" onChange={e => setUserName(e.target.value)} />
        </label>
        <label>
          <p>Password</p>
          <input type="password" onChange={e => setPassword(e.target.value)} />
        </label>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};

// import { useNavigate, useHistory, useLocation } from "react-router-dom";
// import useAuth from './util';

// export const Login = () => {
//   const { login } = useAuth();
//   const { state: { from: referringPage }} = useLocation();
//   const navigate = useNavigate();

//   console.log('referringPage: ', referringPage);

//   const handleLogin = () => {
//     // const res = login({ username: 'un', password: 'pw' });
//     // console.log('res: ', res);

//     // login({ username: 'un', password: 'pw' })
//     //   .then(response => {
//     //     console.log('responsing 1')
//     //     if (response.status < 200 || response.status >= 300) {
//     //         throw new Error(response.statusText);
//     //     }
//     //     return response.json();
//     //   })
//     //   .then(body => {
//     //     console.log('responsing 2')

//     //     localStorage.setItem('token', body.token);
//     //   })
//     //   .catch(() => {
//     //       console.log('in the rye 1')

//     //       throw new Error('Network error')
//     //   })

//     // login({ username: 'un', password: 'pw' }).then((idk) => {
//     //   console.log('idk: ', idk);
//     //   // navigate(referringPage);
//     // });

//     try {
//       login({ username: 'un', password: 'pw' });
//       navigate(referringPage);
//     } catch (err) {
//       console.log('failed to log in ', err);
//     }
//   };

//   return (
//     <div>
//       <h1>Login</h1>
//       <button onClick={handleLogin}>Log in</button>
//     </div>
//   );
// };