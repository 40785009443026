import React from 'react'
import {
  useMatch,
  Outlet,
  Link,
} from "react-router-dom";

export const Media = () => {
  let { pathnameBase: path } = useMatch('/admin/media/*');
  let url = path;
  return (
    <>
      <div>Manage Media</div>
      <ul>
        <li>
          <Link to={`${url}/productInfo`}>Product Info</Link>
          {/* <a href="/admin/cms/media/productInfo">Product Info</a> */}
        </li>
        <li>
          <Link to={`${url}/collage`}>Collage Images</Link>
          {/* <a href="/admin/cms/media/collage">Collage Images</a> */}
        </li>
        <li>
          <Link to={`${url}/insightToken`}>Insight Tokens</Link>
          {/* <a href="/admin/cms/media/insightToken">Insight Tokens</a> */}
        </li>
      </ul>
      <Outlet />
    </>
  )
}
