import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { user as mockUser } from '../mock_data/user';
import 'photoswipe/dist/photoswipe.css'
import MainLanding from './MainLanding';
import VignetteSummary from './VignetteSummary';
import { getBaseUrl } from '../utils';



function LiveSession() {
  const [user, setUser] = useState(null);
  const [userSession, setUserSession] = useState(null);
  const [screenshots, setScreenshots] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // const [leadTimingAndPricingData, setLeadTimingAndPricingData] = useState(null);

  // console.log('leadtimng: ', leadTimingAndPricingData);

  const { userId } = useParams();

  useEffect(() => {

    const source = new EventSource(`${getBaseUrl()}/frontend/user/${userId}?encoded=true`);

    source.addEventListener('open', () => {
      console.log('SSE opened!');
    });

    source.addEventListener('message', (e) => {
      setIsLoading(false);
      const data = JSON.parse(e.data);
      console.log('data: ', data);
      if (data.body.error) {
        return;
      }
      console.log('incoming data: ', data.body.json_body);
      setUser(data.body.json_body);
      setUserSession(data.body.user_session && data.body.user_session.json_body);
      setScreenshots(data.body.screenshots);
      // setHost(e.data.json_body.host);
      // setVignetteSessions(e.data.json_body.vignetteSessions);

      // setDonation(data);
    });

    source.addEventListener('error', (e) => {
      setIsLoading(false);
      console.error('sdsdfsf Error: ',  e);
    });

    return () => {
      // setIsLoading(false);
      console.log('closing sse');
      source.close();
    };
  }, []);

  return (
    <VignetteSummary user={user} userSession={userSession} screenshots={screenshots} isLoading={isLoading} />
  )


  // return (
  //   <>
  //     {
  //       !isLoading && (!userSession || !(userSession.vignetteSessions && userSession.vignetteSessions.length))
  //       ? <MainLanding />
  //       : <VignetteSummary user={user} userSession={userSession} screenshots={screenshots} isLoading={isLoading} />
  //     }
  //   </>
  // )
}

export default LiveSession
