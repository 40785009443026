import { getDisplayName } from "./metaData";
import { getNumberOfVignetteRooms, getProductType, PRODUCT_TYPES } from './dataMaps';

export const onlyUniqueFilter = (value, index, array) => {
  return array.indexOf(value) === index;
}

export const getProductOptions = (product) => {
  if (!product.selectedProductOptionGroups) return [];
  return product.selectedProductOptionGroups.flatMap(group => group.options).map(option => option.key);
}

export const getProductMaterials = (product) => {
  if (!product.materialConfigurations) return [];
  console.log('getproduct materials: ', product.materialConfigurations.map(mtl => mtl.finish).filter(onlyUniqueFilter));
  return product.materialConfigurations.map(mtl => mtl.finish);
}

export const getProductOptionsAndMaterials = (product) => {
  return [...getProductOptions(product), ...getProductMaterials(product)].filter(onlyUniqueFilter);
}


export const getProductGlass = (product) => {
    if (!product.materialConfigurations) return [];
    return product.materialConfigurations.filter(mtl => mtl.type === 'glass').map(mtl => mtl.finish).filter(onlyUniqueFilter);
}

export const getProductLaminates = (product) => {
  if (!product.materialConfigurations) return [];
  return product.materialConfigurations.filter(mtl => mtl.type === 'laminate').map(mtl => mtl.finish).filter(onlyUniqueFilter);
}

export const getProductMaterialByType = (product, type) => {
  if (!product.materialConfigurations) return [];
  return product.materialConfigurations.filter(mtl => mtl.type === type).map(mtl => mtl.finish).filter(onlyUniqueFilter);
}


export const getProductPaintAndTrim = (product) => {
    if (!product.materialConfigurations) return [];
    return product.materialConfigurations.filter(mtl => mtl.type === 'painted_finish').map(mtl => mtl.finish).filter(onlyUniqueFilter);
}


export const getMaterialSwatchUrl = (matType, matKey) => `https://fultonmarket-dev.s3.us-west-1.amazonaws.com/assets/thumbs/materials/${matType}/${matKey}.png`

export const getLaminateSwatchUrl = (laminateKey) => `https://fultonmarket-dev.s3.us-west-1.amazonaws.com/assets/thumbs/materials/laminates/${laminateKey}.png`

export const getPaintedTrimSwatchUrl = (paintedTrimKey) => `https://fultonmarket-dev.s3.us-west-1.amazonaws.com/assets/thumbs/materials/painted_finish/${paintedTrimKey}.png`

export const getLeadTimeInWeeks = (leadTimeInDays) => {
  console.log('leadTimeINDays: ', leadTimeInDays);
  return Math.ceil(leadTimeInDays/7);
}

export const getLeadTimeRange = (minLeadTimeInDays, maxLeadTimeInDays) => {
  const minWeeks = getLeadTimeInWeeks(minLeadTimeInDays);
  const maxWeeks = getLeadTimeInWeeks(maxLeadTimeInDays);
  if (minWeeks === maxWeeks) {
    return `${maxWeeks} week(s)`
  } else {
    return `${minWeeks}-${maxWeeks} week(s)`
  }
}

const pluralize = (count, singularNoun, pluralNoun) => {
  return count === 1 ? singularNoun : pluralNoun;
}

const convertDaysToDayOrWeekString = (numDays) => {
  if (numDays >= 7) {
		const numWeeks = Math.ceil(numDays / 7);
    return `${numWeeks} ${pluralize(numWeeks, 'Week', 'Weeks')}`;
	}
  return `${numDays} ${pluralize(numDays, 'Day', 'Days')}`;
}

export const getFormattedLeadTimeRange = (minLeadTimeInDays, maxLeadTimeInDays) => {
  if (minLeadTimeInDays <= 0 || maxLeadTimeInDays <= 0) {
		return '-';
	}
	if (minLeadTimeInDays <= 2 && maxLeadTimeInDays <= 2) {
		return 'Quick Ship';
	}

	// Case when the range start and end are the same
	if (minLeadTimeInDays == maxLeadTimeInDays) {
		return convertDaysToDayOrWeekString(minLeadTimeInDays);
	}

	const minLeadIsLessThanOneWeek = minLeadTimeInDays < 7;
	const maxLeadIsLessThanOneWeek = maxLeadTimeInDays < 7;

	// If both min and max values are in days format
	if (minLeadIsLessThanOneWeek && maxLeadIsLessThanOneWeek) {
    return `${minLeadTimeInDays} to ${maxLeadTimeInDays} Days`;
	}

	// If both min and max values are in weeks format
	if ( !minLeadIsLessThanOneWeek && !maxLeadIsLessThanOneWeek ) {
		const minWeeks = Math.ceil(minLeadTimeInDays / 7);
		const maxWeeks = Math.ceil(maxLeadTimeInDays / 7);
    const str = minWeeks === maxWeeks ? convertDaysToDayOrWeekString(minLeadTimeInDays) : `${minWeeks} to ${maxWeeks} Weeks`;
    return str;
	}

	// If the units are different (one in days and one in weeks)
	const minReadable = convertDaysToDayOrWeekString(minLeadTimeInDays);
	const maxReadable = convertDaysToDayOrWeekString(maxLeadTimeInDays);
  return `${minReadable} to ${maxReadable}`;
}

export const getVignetteRooms = (vignette) => {
  console.log('product vignette id: ', vignette.id);
  const defaultProducts = vignette.productConfigurations.filter(product => product.roomIndex === -1);
  console.log('defaultProducts: ', defaultProducts);
  // QUESTION - will room indices be 0 based or 1 based?
  const overrideProducts = vignette.productConfigurations.filter(product => product.roomIndex !== -1);
  console.log('overrideProducts: ', overrideProducts);

  const numberOfVignetteRooms = getNumberOfVignetteRooms(vignette.vignettePropSelections.vignetteLayout)



  const rooms = Array.from({length: numberOfVignetteRooms}, (v, i) => defaultProducts);
  // ASSUMPTION - room indices are 0 based
  overrideProducts.forEach((ovProduct) => {
    // we will remove default products from a room if there is an override product
    // for that product_type / category combination
    // ASSUMPTION - each room can only have one product per product_type / category combination
    // OTHERWISE - need a flag isOverride to determine whether to override or append
    const { roomIndex, product: ovProductKey } = ovProduct;
    const ovProductType = getProductType(ovProductKey);

    rooms[roomIndex] = rooms[roomIndex].filter(product => (!(getProductType(product.product) === ovProductType)));
    rooms[roomIndex].push(ovProduct);
  });

  return rooms;
}
