import { useState } from "react";
import logo from "../assets/Logo.png"
import Contact from '../components/Contact.jsx';
import Share from '../components/Share.jsx';
import Footer from '../components/Footer.jsx';
import Rating from '../components/Rating.jsx';
import Header from '../components/Header.jsx';
import ProductPillars from '../components/ProductPillars.jsx';
import Hero from '../assets/Hero.png'
import MobileHero from '../assets/MobileHero.jpg';

function PageWrapper({ children, isRemoteAttendee }) {
  // const [starRating, setStarRating] = useState(0);

  let heading1 = 'THANKS FOR PARTICIPATING IN THE';
  let heading2 = 'Fellowes Design & Experience Center';
  let heading3 = "Below you will find all the content you've created with our Portray Experience";

  if (isRemoteAttendee) {
    heading1 = 'YOUR REMOTE SESSION HAS STARTED';
    heading2 = 'STAY UPDATED WITH REAL-TIME EDITS BY YOUR COLLEAGUE!';
    heading3 = 'Below you will find all the content created with our Portray Experience.'
  }

  return (
    <>
      <div className="mx-auto max-w-[1600px] sm:px-[20px] min-h-[699px] md:min-h-[929px]">
        <Header />
        <div className="absolute w-full left-0 right-0 max-h-[620px] md:max-h-[865px] md:min-h-[850px] overflow-hidden bg-[#84908e]">
          <img alt="" className="hidden md:block" src={Hero} />
          <img alt="" className="md:hidden" src={MobileHero} />
        </div>

        <main className="mt-[60px] relative">
          <img className="w-[70%] sm:w-[50%] md:w-[40%] xl:w-[30%] mx-auto mb-[60px]" src={logo} alt="logo" />
          <div className="text-left text-[55px] md:text-[37px] lg:text-[45px] xl:text-[55px] px-[30px] md:px-0 text-white mt-[50px] mb-[70px]">
            <div className=" w-[85%] md:w-[67%] xl:w-[62%]">
              <div className="text-[0.25em] md:text-[0.58em] font-semibold uppercase">{heading1}</div>
              <div className="text-[0.54em] md:text-[1em] font-normal uppercase">{heading2}</div>
            </div>
            <div className="md:w-[44%] text-[0.25em] md:text-[0.42em] mt-[0.36em]">{heading3}</div>
          </div>
          {children}
        </main>
      </div>
      <div className="w-screen">
        {/* <ProductPillars /> */}
        {/* <Contact /> */}
        <Share />
        {/* <Rating starRating={starRating} setStarRating={setStarRating} /> */}
        <Footer />
      </div>
    </>
  )
}

export default PageWrapper