import React from 'react'
import logoWhite from "../assets/logo_white_on_black.png";

const Footer = () => {
  return (
    <section id="footer" className="bg-[#191919]">
      <div className="mx-auto py-[60px] flex flex-col">
        <div className="w-60 mx-auto mb-[14px]">
          <img className="flex justify-center" src={logoWhite} />
        </div>
        {/* <div className="text-white">&copy; Fellowes Brand 2023</div> */}
      </div>
    </section>
  )
}

export default Footer