import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from 'swiper';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { prevArrow, nextArrow } from "../assets/svgs/arrows";
import {
    getProductOptions,
    getFormattedLeadTimeRange,
    getVignetteRooms, getProductMaterials,
} from '../utils/selectors.js'

// import required modules
import { Link } from "./Link";
import { MobileProductDetails, ThreeColumnProductDetailsOptionsGrid, TwoColumnProductDetailsOptionsGrid } from "./MobileProductDetails";

import { imageNotFoundUrl } from "../utils";

import { getLayoutName, getProductType, PRODUCT_TYPES } from '../utils/dataMaps';
import { getDisplayName } from '../utils/metaData';
import { ProductThumbnail } from '../components/ProductThumbnail';

const NavigationArrow = ({ Icon, color, className, onClick }) => {
  return (
    <>
      <Icon className={className} color={color} onClick={onClick} />
    </>
  )
}

const pagination = {
  clickable: true,
  renderBullet: function (index, className) {
    return '<span class="' + className + '">' + (index + 1) + '</span>';
  },
};


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const RoomTab = ({name, isSelectedTab, onClick, width}) => {
  const accentBarClass = isSelectedTab ? 'block' : 'invisible';
  return (
    <button
      // key={idx}
      onClick={onClick}
      style={{ width }}
      className={classNames(
        isSelectedTab
          ? 'font-semibold'
          : 'font-normal',
        `py-4 px-1 text-center text-[15px] md:text-[28px]`
      )}
      aria-current={isSelectedTab ? 'page' : undefined}
    >
      {name}
      <div className={`hidden md:block ${accentBarClass} w-[80px] mx-auto h-[4px] my-[5px] bg-[#FDB913]`}></div>
    </button>
  )
}

const TabsAllVisible = ({rooms, selectedRoom, setSelectedRoom}) => {
  return (
    <nav className="-mb-px flex">
        {[...Array(rooms).keys()].map((idx) => {
        const isSelectedTab = selectedRoom === idx;
        return <RoomTab
                key={idx}
                name={`Room ${idx + 1}`}
                isSelectedTab={isSelectedTab}
                onClick={() => {
                  setSelectedRoom(idx);
                }}
                width={`${100/rooms}%`}
              />
      })}
    </nav>
  )
};

const TabsSlider = ({rooms, selectedRoom, setSelectedRoom}) => {
  return (
      <Swiper
        style={{
          "--swiper-navigation-color": "#FDB913",
          "--swiper-navigation-size": "36px",
          // width: "25%",
          // "--swiper-navigation-sides-offset": "0px"
        }}
        modules={[Navigation, Pagination]}
        slidesPerView={1}
        spaceBetween={10}
        // pagination={{
        //   clickable: true,
        // }}
        navigation={true}
        className="mySwiper mt-[60px]"
        onSlideChange={(swiper) => setSelectedRoom(swiper.activeIndex)}
        // prevArrow={prevArrow(false, '#FDB913')}
        // nextArrow={nextArrow(false, '#FDB913')}
        breakpoints={{
          320: {
            style: {
              "--swiper-navigation-sides-offset": "-20px"
            }
          },
          768: {
            style: {
              "--swiper-navigation-sides-offset": "0px"
            }
          },
        }}
      >
        {
          [...Array(rooms).keys()].map((idx) => {
          const isSelectedTab = selectedRoom === idx;
            return (
              <SwiperSlide>
                <RoomTab
                  key={idx}
                  name={`Room ${idx + 1}`}
                  isSelectedTab={isSelectedTab}
                  onClick={() => {
                    setSelectedRoom(idx);
                  }}
                  // width="100%"
                />
              </SwiperSlide>
            )
          })
        }
      </Swiper>
  );
}

const RoomTabs = ({rooms, selectedRoom, setSelectedRoom}) => {

  return (
    <div>
      <div id="room-tabs-desktop" className="hidden md:block">
        {
          rooms > 4
          ? <TabsSlider rooms={rooms} selectedRoom={selectedRoom} setSelectedRoom={setSelectedRoom} />
          : <TabsAllVisible rooms={rooms} selectedRoom={selectedRoom} setSelectedRoom={setSelectedRoom} />
        }
      </div>
      <div id="room-tabs-mobile" className="md:hidden">
        <TabsSlider rooms={rooms} selectedRoom={selectedRoom} setSelectedRoom={setSelectedRoom} />
      </div>
    </div>
  )
}


// const getProductThumbnailUrl = (product) => {

//   let path = '';

//   const productKey = product.product !== 'null_opt' ? product.product : '';
//   const productVariant = product.productVariant !== 'null_opt' ? product.productVariant : '';
//   const productColorway = product.selectedColorway !== 'null_opt' ? product.selectedColorway : '';

//   const basePath = 'https://fultonmarket-dev.s3.us-west-1.amazonaws.com/web-ui/product-thumbnails';


//   switch (product.product) {
//     case 'product_rising':
//       if (product.productVariant === 'rising_single_arm') {
//         path = 'product_rising-rising_single_arm-rising_black_bronze.png';
//       } else if (product.productVariant === 'rising_dual_arm') {
//         path = 'product_rising-rising_dual_arm-rising_black_bronze.png';
//       }
//       break;
//     case 'product_sena':
//       if (product.productVariant === 'sena_single_arm') {
//         path = 'product_sena-sena_single_arm-sena_black.png';
//       } else if (product.productVariant === 'sena_dual_arm') {
//         path = 'product_sena-sena_dual_arm-sena_black.png';
//       }
//       break;
//     case 'product_rising_loft':
//       path = 'product_rising_loft--rising_loft_black.png';
//       break;
//     case 'product_elea':
//       path = 'product_elea--elea_white.png';
//       break;
//     case 'product_lustre':
//       path = 'product_lustre--lustre_white.png';
//       break;
//     case 'product_flex_charge':
//       path = 'product_flex_charge--flex_charge_white.png';
//       break;
//     case 'product_hana_foot_rest':
//       path = 'product_hana_foot_rest--hana_foot_rest_black.png';
//       break;
//     case 'product_hana_laptop_stand':
//       path = 'product_hana_laptop_stand--hana_laptop_stand_black.png';
//       break;
//     default: return 'undefined';
//   }

//   if (productKey && productVariant && productColorway) {
//     path = `${productKey}-${productVariant}-${productColorway}.png`
//   }

//   return `${basePath}/${path}`;
// }

export const Room = ({ screenshots, assemblyProduct, selectedRoom, vignetteId }) => {

  const roomScreenshot = screenshots.find(screenshot =>
    screenshot.vignette_level_key === vignetteId
    && screenshot.level === 'PRODUCT'
    && screenshot.room_index === selectedRoom);

  if (!roomScreenshot) {
    return null;
  }

  return (
    <div>
      <div id="desk-product-section-desktop" data-vignette-id={`${vignetteId}`} className="hidden xl:flex flex-row">
        <img src={roomScreenshot.url} className="w-[39%] object-scale-down" />
        {
          assemblyProduct ? (
            <div className="w-auto pl-[75px] text-left w-full">
              <div className="flex flex-col mx-auto">
                <div className="text-[30px] font-medium">
                  {getDisplayName(assemblyProduct.product)}
                </div>
                <div className="text-[18px] mt-[10px] font-bold">Starting at: ${assemblyProduct.basePriceUSD}</div>

                <TwoColumnProductDetailsOptionsGrid className="xl:hidden" product={assemblyProduct} />
                <ThreeColumnProductDetailsOptionsGrid className="hidden xl:grid" product={assemblyProduct} />
              </div>
            </div>
          ) : (<div className="m-auto">No furniture product configured yet for this room</div>)
        }
      </div>
        <MobileProductDetails className="flex xl:hidden" product={assemblyProduct} assemblyProductScreenshot={roomScreenshot} isAssemblyProduct={true} />
      </div>

  )
}


const VignetteElement = ({ screenshots, vignette, idx, isOpen, onClick }) => {

  const rooms = getVignetteRooms(vignette);
  const [selectedRoom, setSelectedRoom] = useState(0);


  const roomProducts = rooms[selectedRoom];


  const wallProduct = roomProducts.find(product => getProductType(product.product) === PRODUCT_TYPES.WALL);
  const assemblyProduct = roomProducts.find(product => {
    return getProductType(product.product) === PRODUCT_TYPES.ASSEMBLY;
  });

  let vignetteId = vignette.id || vignette.iD;

  const vignetteLevelScreenshot =
    screenshots.find(screenshot =>
      screenshot.vignette_level_key === vignetteId
      && screenshot.level === 'VIGNETTE'
    );

  if (!vignetteLevelScreenshot) {
    return null;
  }


  console.log('wall product: ', wallProduct);

  const vignetteBase = vignette.vignettePropSelections && vignette.vignettePropSelections.vignetteBase;

  console.log('vignetteId, roomProducts: ', vignetteId, roomProducts);

  return (
    <div className="group px-[30px] mt-[40px] mb-[120px]">
      <summary className="relative list-none font-medium transition-colors duration-700 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden">
        <div className="flex flex-row justify-between">
          <div className="text-[15px] md:text-[28px] text-left transition duration-700 text-[#191919]">{(wallProduct && wallProduct.selectedWallTypical.displayName.en_us) || getLayoutName(vignette)}</div>
          <div className="text-[15px] md:text-[20px] my-auto text-left transition duration-700 text-[#191919]"><span>Lead Time: {getFormattedLeadTimeRange(vignette.minLeadTimeInDays, vignette.maxLeadTimeInDays)}</span></div>
        </div>
        <div className="bg-[#D9D8D6] w-full h-[4px] mt-[20px]"/>
      </summary>
      <div id="accordion-content" className="mt-[41px]">
        <div id="accordion-content-summary" data-vignette-id={`${vignetteId}`}>
          <img
            // alt="vignette screenshot"
            className="absolute md:relative left-[50%] translate-x-[-50%] h-[250px] md:h-auto object-cover"
            src={vignetteLevelScreenshot.url}
          />
          <div className="h-[250px] md:h-0" />
          {
            wallProduct ? (
              <>
                <div id="wall-product-summary-desktop" className="hidden lg:block mb-[50px]">
                  <div className="flex justify-between text-left mt-[40px]">
                  </div>
                  <div id="vignette-wall-specs" className="flex flex-row mt-[40px] text-left">
                    <div id="vignette-wall-type" className="mr-[57px]">
                      <div className="text-[18px] font-bold">{getDisplayName(wallProduct.product)}</div>
                      <div className="text-[18px] font-bold">Starting at: ${wallProduct.basePriceUSD}</div>
                    </div>
                    <ul className="grid grid-cols-3 h-[100%] gap-y-[8px]">
                      {
                        getProductOptions(wallProduct).map((dtl, idx) => (
                          <li key={idx} className="w-[250px] text-[16px] font-medium">- {getDisplayName(dtl)}</li>
                        ))
                      }
                    {
                        getProductMaterials(wallProduct).map((dtl, idx) => (
                            <li key={idx} className="w-[250px] text-[16px] font-medium">- {getDisplayName(dtl)}</li>
                        ))
                    }


                    </ul>
                  </div>
                </div>
                <div id="wall-product-summary-mobile" className="lg:hidden mb-[50px]">
                  {/* <div className="mt-[20px]">
                    <div className="text-[22px] font-medium">{wallProduct.selectedWallTypical.displayName.en_us}</div>
                  </div> */}
                  <div className="mt-[25px]  justify-items-center">
                    <div className="list-none">
                      <div className="text-[17px] font-semibold">{getDisplayName(wallProduct.product)}</div>
                      <div className="text-[17px] font-medium">Starting at: ${wallProduct.basePriceUSD}</div>
                      <div className="mt-[15px] text-[14px] flex flex-col gap-[7px]">
                        {
                          getProductOptions(wallProduct).map((dtl, idx) => (
                            <li key={idx} className="w-[250px] mx-auto font-medium">- {getDisplayName(dtl)}</li>
                          ))
                        }
                          {
                              getProductMaterials(wallProduct).map((dtl, idx) => (
                                  <li key={idx} className="w-[250px] mx-auto font-medium">- {getDisplayName(dtl)}</li>
                              ))
                          }

                      </div>
                    </div>
                    <div className="text-left flex flex-col justify-between">

                    </div>
                  </div>
                </div>
                <div class="hidden md:block h-[1px] w-[100%] mx-auto bg-[#979797]"></div>
              </>
            ) : null
          }
              <div id="vignette-rooms" className="px-0 md:px-[70px] mt-[50px]">
                <RoomTabs rooms={rooms.length} selectedRoom={selectedRoom} setSelectedRoom={setSelectedRoom} />
                <div className="mt-[20px] md:mt-[60px]">
                  <Room assemblyProduct={assemblyProduct} screenshots={screenshots} selectedRoom={selectedRoom} vignetteId={vignetteId} />
                </div>
                <div data-vignette-id={vignetteId} />
                <Swiper
                  watchSlidesProgress
                  style={{
                    "--swiper-navigation-color": "#FDB913",
                    "--swiper-navigation-size": "36px",
                    "--swiper-navigation-sides-offset": "-20px",
                  }}
                  slidesPerView={4}
                  spaceBetween={10}
                  navigation={true}
                  // pagination={pagination}
                  breakpoints={{
                    320: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 50,
                    },
                    1280: {
                      slidesPerView: 4,
                      spaceBetween: 50,
                    }
                  }}
                  modules={[Navigation, Pagination]}
                  className="mySwiper md:mt-[60px] h-[490px]"
                  prevArrow={prevArrow(false, '#FDB913')}
                  nextArrow={nextArrow(false, '#FDB913')}
                >
                  {
                    roomProducts.filter(p => getProductType(p.product) !== PRODUCT_TYPES.ASSEMBLY && getProductType(p.product) !== PRODUCT_TYPES.WALL).map((product, idx) => {
                      return (
                        <SwiperSlide id="selected-product-slide" data-vignette-id={vignetteId} key={idx} className="">
                          {({ isVisible }) => (
                            <div className={`w-[200px] flex flex-col text-left ${!isVisible ? 'hidden' : ''}`}>
                              <div className="h-[174px] other">
                                <ProductThumbnail product={product} vignetteBase={vignetteBase} />
                              </div>
                              <div className="text-[24px] md:text-[30px] mt-[30px]">{getDisplayName(product.product)}</div>
                              <div className="text-[18px] mt-[10px] font-bold">Starting at: ${product.basePriceUSD}</div>
                              <div className="text-[18px] font-semibold mt-[30px]">Options {isVisible}</div>
                              <ul className="text-[16px] mt-[20px]">
                                {
                                  product.productVariant !== 'null_opt' ? <li key={1}>- {getDisplayName(product.productVariant)}</li> : null
                                }
                                {
                                  product.selectedColorway !== 'null_opt' ? <li key={2}>- {getDisplayName(product.selectedColorway)}</li> : null
                                }
                              </ul>
                              {/* <Link href="#" alignment="LEFT" className="text-[16px] md:text-[20px] text-[#191919] mt-[5px] md:mt-[20px]">
                                <div>LEARN MORE</div>
                              </Link> */}
                            </div>
                          )}
                        </SwiperSlide>
                      )
                    })
                  }

                </Swiper>
                <div class="hidden md:block h-[1px] w-[100%] mx-auto my-[50px] bg-[#979797]"></div>
              </div>

        </div>
        <div id="accordion-content-carousel">

        </div>
      </div>
    </div>
  )
}






export default function Vignettes({ className, screenshots, vignettes }) {

  console.log('screenshots: ', screenshots);

  const [openVignette, setOpenVignette] = useState(0);

  return (
    <div className={className}>
      <section id="vignette-accordion" className="w-full rounded ">
        {
          vignettes.map((vignette, idx) => {
            const isOpen = openVignette === idx;
            if (!vignette.isAnonymous) {
              return (
                <VignetteElement key={`${idx}-${isOpen}`} vignette={vignette} screenshots={screenshots} idx={idx} isOpen={isOpen} onClick={() => {setOpenVignette(idx)}} />
              );
            }
          })
        }
      </section>
    </div>
  )
}
