import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getBaseUrl } from '../../utils';
import { PrintoutContent } from "./PrintoutContent";



function Printout() {
    const { userId } = useParams();

    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
    
        const fetchUserData = async () => {
            setIsLoading(true);
            let fetchUrl = `${getBaseUrl()}/frontend/user/static/${userId}?encoded=true`;
            const resp = await fetch(fetchUrl);
            const data = await resp.json();
            return data;
        }
    
        fetchUserData().then(data => {
            console.log('data received from backend', data);
            console.log('setting user data to: ', data)
            setUserData(data);
        }).catch(console.error).finally(() => setIsLoading(false));
        
        
    }, [])

    console.log('userData: ', userData);

    return (

        <>
            {
                isLoading ? <div>Loading...</div> : (
                    <>
                        { userData && userData.user_session ? (
                            <PrintoutContent userData={userData} />
                        ) : (
                            <div>There isn't any user session data yet for this user.</div>
                        )
                        }
                    </>
                )

            }
        </>
    )
}

export default Printout

