import React from 'react'
import shareImg from "../assets/section_share_1.png";
import { mail, text } from '../assets/svgs/all';

const Share = () => {

  const buttonOnClick = (dest) => (e) => {
    window.open(dest, "_blank", 'noopener, noreferrer')
    e.preventDefault();
  }

  return (
    <section id="section-share">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="">
          <img src={shareImg} />
        </div>
        <div className="h-[50vh] md:h-auto relative flex justify-center">
          {/* <div className=""> */}
            <div className="md:relative px-[10%] lg:px-[115px] md:py-0 flex flex-col content-center text-center md:text-left absolute top-[50%] translate-y-[-50%] justify-center">
              <h2 className="mb-[26px] md:mb-[2.6vw] text-[25px] md:text-[3vw] xl:text-[40px] text-center">Spread the Love<br/>Share Your Designs</h2>
              <div className="mx-auto text-[16px] md:text-[1.8vw] xl:text-[28px] font-normal w-[186px] md:w-[24vw] grid grid-cols-1 gap-x-[2vw]">
                <button
                  onClick={buttonOnClick(`mailto:?to=&body=${window.location.href}&subject=Portray by Fellowes Remote Experience Invite`)}
                  class="h-[46px] md:h-[7vw] mb-[26px] md:mb-[2.6vw] rounded-none bg-transparent w-[100%] hover:bg-[#FDB913] text-black hover:text-white py-2 px-4 hover:border-transparent"
                >
                  <div className="flex flex-row justify-center">
                    <div className="my-auto mr-[20px]">{mail('#000000', '37px', '23px')}</div>
                    <div className="font-normal">Email</div>
                  </div>
                </button>
                <button
                  onClick={buttonOnClick(`sms:?to=&body=${window.location.href}`)}
                  class="h-[46px] md:h-[7vw] rounded-none bg-transparent w-[100%] hover:bg-[#FDB913] text-black hover:text-white py-2 px-4 hover:border-transparent">
                  <div className="flex flex-row justify-center">
                    <div className="my-auto mr-[20px]">{text('#000000', '32px', '32px')}</div>
                    <div className="font-normal">Text</div>
                  </div>
                </button>
              </div>
            </div>
          {/* </div> */}
        </div>
      </div>
    </section>
  )
}

export default Share