import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams, Link } from 'react-router-dom';
import { useFieldArray, useForm } from "react-hook-form";
import { getBaseUrl, isValidEmail } from '../utils';
import { Field, FieldArray, Select } from '../components/form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { RegistrationWrapper } from '../components/RegistrationWrapper';


const Modal = ({ handleClose, handleConfirm, dialog, show, header, confirmLabel, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div class={`${showHideClassName} relative z-10`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">{header}</h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">{dialog}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button onClick={handleConfirm} type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">{confirmLabel}</button>
              <button onClick={handleClose} type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}


export const Registration = ({ editMode, ...props }) => {

  const { markerId, userId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const adminQueryParam = searchParams.get("adm");

  const [errorMessage, setErrorMessage] = useState('');

  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [modalDialog, setModalDialog] = useState('');
  const [modalHeader, setModalHeader] = useState('');
  const [modalConfirmLabel, setModalConfirmLabel] = useState('');
  const [modalHandleConfirm, setModalHandleConfirm] = useState(() => {});




  let decodedAdminQueryParam = '';

  try {
    decodedAdminQueryParam = atob(adminQueryParam || '')
  } catch (error) {
    console.log('something went wrong');
  }

  const isAdmin = decodedAdminQueryParam === '1337_admin';

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
        .required('Email is required')
        .email('Email is invalid'),
    virtual_attendees_email: Yup.array()
      .of(
        Yup.object().shape({
          email: Yup.string()
            .email()
        })
      )
  });
  // const formOptions = { resolver: yupResolver(validationSchema) };

  const { control, register, handleSubmit, reset, formState: { errors }, getValues } = useForm({
    defaultValues: {
      virtual_attendees_email: [{ email: '' }]
    },
    resolver: yupResolver(validationSchema),
  });

  const closeModal = () => {
    setModalHeader('');
    setModalDialog('');
    setModalConfirmLabel('');
    setModalHandleConfirm(() => {});
    setModalIsVisible(false);
  }


  const deleteUser = async () => {
    try {
      const result = await fetch(`${getBaseUrl()}/user/${userId}?encoded=true`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
    

      if (!result.ok) {
        setModalDialog('Something went wrong while deleting user. Please try again.')
        return;
      } else {
        navigate(`/register/confirmation`, {
          state: {
            isError: false,
            body: {
              msg: 'Successfully deleted user.'
            }
          }
        });
      }
    } catch (err) {
      setModalDialog('Something went wrong while deleting user. Please try again.')
      return;
    }
  }

  const openDeleteUserModal = () => {
    setModalHeader('Delete User')
    setModalDialog('Are you sure you want to delete this user? All user data will be permanently removed. This action cannot be undone.');
    setModalConfirmLabel('Delete User');
    setModalHandleConfirm(() => deleteUser);
    setModalIsVisible(true);
  }

  const releaseMarkerId = async () => {
    try {
      const markerId2 = getValues('marker_id');
      const result = await fetch(`${getBaseUrl()}/user/releaseMarkerId/${userId}?encoded=true`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      });
    

      if (!result.ok) {
        setModalDialog('Something went wrong while releasing marker id. Please try again.')
        return;
      } else {
        navigate(`/register/confirmation`, {
          state: {
            isError: false,
            body: {
              msg: `Successfully released marker id: ${markerId2}.`
            }
          }
        });
      }
    } catch (err) {
      setModalDialog('Something went wrong while releasing marker id. Please try again.')
      return;
    }
  }

  const openReleaseMarkerIdModal = () => {
    setModalHeader('Release Marker Id')
    setModalDialog('Doing this will set this user\'s marker id to a random, out-of-range value so that you can assign the current marker id to another user. Do this if this user is inactive and you are running low on user tokens.');
    setModalConfirmLabel('Release Marker Id');
    setModalHandleConfirm(() => releaseMarkerId);
    setModalIsVisible(true);
  }



  const onSubmit = async data => {
    const completeData = {
      id: userId,
      marker_id: data.marker_id,
      json_body: data,
    };

    let result;

    if (editMode) {
      result = await fetch(`${getBaseUrl()}/user/${userId}?encoded=true`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(completeData),
      });
    } else {
      result = await fetch(`${getBaseUrl()}/user?encoded=true`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(completeData),
      });
    }

    if (!result.ok) {
      navigate(`/register/confirmation`, {
        state: {
          isError: true,
        }
      });
      return;
    }

    try {
      const body = await result.json();

      navigate(`/register/confirmation`, {
        state: {
          isError: false,
          body
        }
      });
    } catch (error) {
      navigate(`/register/confirmation`, {
        state: {
          isError: true,
        }
      });
    }

  }

  const createAccountFields = [
    {
      label: 'Id',
      dataAttribute: 'id',
      type: 'text',
      required: true,
      value: userId,
      isStatic: true,
    },
    {
      label: 'Marker Id',
      dataAttribute: 'marker_id',
      type: 'text',
      required: true,
      isAdminOnly: true,
    },
    {
      label: 'First Name',
      dataAttribute: 'first_name',
      type: 'text',
      required: true,
    },
    {
      label: 'Last Name',
      dataAttribute: 'last_name',
      type: 'text',
      required: true,
    },
    {
      label: 'Sales Representative Name',
      dataAttribute: 'sales_representative_name',
      type: 'text',
      required: false,
    },
    {
      label: 'Sales Representative Email',
      dataAttribute: 'sales_representative_email',
      type: 'email',
      required: false,
    },
    {
      label: 'Company',
      dataAttribute: 'company',
      type: 'text',
      required: true,
    },
    {
      label: 'Email',
      dataAttribute: 'email',
      type: 'email',
      required: true,
    },
    {
      label: 'Virtual Attendees Email',
      dataAttribute: 'virtual_attendees_email',
      fieldName: 'email',
      type: 'array',
      required: false,
      ...useFieldArray({
        control,
        name: 'virtual_attendees_email'
      })
    },
    {
      label: 'Products Interested In',
      dataAttribute: 'products_interested_in',
      type: 'text',
      required: false,
    },
  ];
  
  const additionalInfoFields = [
    {
      label: 'Project Name',
      dataAttribute: 'project_name',
      type: 'text',
      required: false,
    },
    {
      label: 'Project Street Address',
      dataAttribute: 'project_address',
      type: 'text',
      required: false,
    },
    {
      label: 'Project Zip Code',
      dataAttribute: 'project_zip',
      type: 'text',
      required: false,
    },
    {
      label: 'Timeline',
      dataAttribute: 'timeline',
      type: 'text',
      required: false,
    },
    {
      label: 'Budget',
      dataAttribute: 'budget',
      type: 'text',
      required: false,
    },
    {
      label: 'A&D Firm / Commercial Broker',
      dataAttribute: 'commercial_broker',
      type: 'text',
      required: false,
    },
    {
      label: 'Dealer',
      dataAttribute: 'dealer',
      type: 'text',
      required: false,
    }
  ];

  useEffect(() => {

    const fetchUserData = async () => {
      let fetchUrl;

      if (editMode) {
        fetchUrl = `${getBaseUrl()}/user/userId/${userId}?encoded=true`;
      } else {
        fetchUrl = `${getBaseUrl()}/user/${markerId}?encoded=true`;
      }
      const resp = await fetch(fetchUrl);
      const data = await resp.json();
      return data;
    }

    fetchUserData().then(data => {
      if (editMode) {
        console.log(data);
        if (data.isRegistered) {
          reset({ ...data.user.json_body, id: userId, marker_id: data.user.marker_id, });
        } else {
          setErrorMessage(`User with id ${userId} was not found`);
        }
      } else {
        if (data && data.isRegistered) {
          setErrorMessage(`MarkerId ${markerId} is already associated with another user.
          Please select another markerId.`)
          // setIsMarkerIdRegistered(true);
        }
        reset({ marker_id: markerId });
      }
    }).catch(console.error);

    
    
  }, [])

  const RegisteredField = Field(register, isAdmin);
  const RegisteredSelect = Select(register);
  const RegisteredFieldArray = FieldArray(register);

  const inputMapper = (field, idx) => {
    switch (field.type) {
      case 'select':
        return <RegisteredSelect key={JSON.stringify(field.options)} {...field} errorMsg={errors[field.dataAttribute]} />
      case 'array':
        return <RegisteredFieldArray key={idx} {...field} errorMsg={errors[field.dataAttribute]} />
      default:
        return <RegisteredField key={idx} {...field} errorMsg={errors[field.dataAttribute]} />
    }
  };

  return (
    <RegistrationWrapper>
      { errorMessage 
        ? (
            <div className="text-red-500 font-bold text-md">
              {errorMessage}
            </div>
          ) 
        : (
            <>

              <div className="mx-auto max-w-[800px]">


                { (editMode && userId) &&
                  <div className="w-full mb-[50px]">
                    <div className="">
                      <Link to={`/printout/${userId}`} target="_blank" rel="noopener noreferrer">View PDF for Print</Link>
                    </div>
                    <div>-</div>
                    <div className="">
                      <Link to={`/userSession/${userId}?remote=true`} target="_blank" rel="noopener noreferrer">Portray Session Summary Page</Link>
                    </div>
                    <div>-</div>
                    <div className="">
                      <Link to={`/userCMS/${userId}`} target="_blank" rel="noopener noreferrer">Add / Manage Collage Media</Link>
                    </div>
                  </div>
                }

                {/* <form onSubmit={() => console.log('submitting form')} > */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="text-[18px] grid grid-cols-1 md:grid-cols-2 gap-x-[100px]">
                    <div className="">
                      <div className="mb-[30px]">
                        <div className="mb-[15px] font-medium">Create Account</div>
                        <div className="w-[80%] h-[3px] bg-[#D9D8D6] mx-auto" />
                      </div>
                      <div className="">
                        <div className="space-y-12">
                          <div className="pb-[68px]">
                            <div className="text-left">
                              { createAccountFields.map(inputMapper) }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="mb-[30px]">
                        <div className="mb-[15px] font-medium">Additional Information</div>
                        <div className="w-[80%] h-[3px] bg-[#D9D8D6] mx-auto" />
                      </div>
                      <div className="grid-cols-2">
                        <div className="space-y-12">
                          <div className="pb-12">
                            <div className="text-left">
                              { additionalInfoFields.map(inputMapper) }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="w-[300px] px-3 py-2 font-semibold text-[20px] border-[#FDB913] border-[2px] rounded-none"
                  >
                    Submit
                  </button>
                </form>
                {
                  editMode && isAdmin && (
                    <div className="mt-[50px] flex flex-row justify-center">
                      <div className="flex flex-col text-[red] w-[300px]">
                        <button className="border-[1px] border-[red] hover:border-[red]" onClick={openReleaseMarkerIdModal}>Release Marker Id</button>
                        <button className="border-[1px] border-[red] hover:border-[red] mt-[10px]" onClick={openDeleteUserModal}>Delete User</button>
                      </div>
                    </div>
                  )
                }
              </div>
              <Modal
                header={modalHeader} 
                dialog={modalDialog} 
                handleClose={closeModal} 
                handleConfirm={modalHandleConfirm}
                confirmLabel={modalConfirmLabel}
                show={modalIsVisible} />
            </>
          )
      }
    </RegistrationWrapper>
  );
}