import React from 'react'
import { text, mail } from '../assets/svgs/all';
import { Link } from './Link';

const Header = () => {
  return (
    <header className="h-20 flex flex-col">
      <div className="absolute h-20 left-0 top-0 right-0 bg-[#191919]">
        <div className="absolute top-[50%] translate-y-[-50%] right-0 flex items-center w-full justify-between md:justify-end">
          <div className="ml-[30px] md:mr-[40px] text-white text-[14px] text-left md:text-xs w-[115px] md:w-[100px]">Share Experience</div>
          <div className="mr-[30px] md:mr-[90px] flex">
            <a
              href={`mailto:?to=&body=${window.location.href}&subject=Portray by Fellowes Remote Experience Invite`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex mr-[30px] md:mr-0"
            >
              <Link
                className="mr-[0px] md:mr-[45px] font-semibold text-white md:text-[14px]"
              >
                <div className="md:hidden my-auto md:mr-[7px]">{mail('#FFFFFF', '34px', '22px')}</div>
                <div className="hidden md:block my-auto md:mr-[7px]">{mail('#FFFFFF', '16px', '16px')}</div>
                <div className="hidden md:block">EMAIL</div>
              </Link>
            </a>
            <a
              href={`sms:+18664504185?&body=${window.location.href}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex"
            >
              <Link
                className="font-semibold text-white md:text-[14px]"
              >
                <div className="md:hidden my-auto md:mr-[7px]">{text('#FFFFFF', '26px', '26px')}</div>
                <div className="hidden md:block my-auto md:mr-[7px]">{text('#FFFFFF', '16px', '16px')}</div>
                <div className="hidden md:block">TEXT</div>
              </Link>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header