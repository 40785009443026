import { fetchUtils, HttpError } from 'ra-core';

export const isValidEmail = email => {
  // eslint-disable-next-line no-useless-escape
  return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
} 

export const imageNotFoundUrl = 'https://fultonmarket-dev.s3.us-west-1.amazonaws.com/web-ui/misc/image-not-found-scaled-1150x647.png';

export const getBaseUrl = () => process.env.REACT_APP_BASE_URL;
// export const getBaseUrl = () => 'http://localhost:3009';
// export const getBaseUrl = () => 'https://fellowes-portray-dev.herokuapp.com';

export const getChunkedArray = (array, chunkSize) => {
  let chunkedArray = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunkedArray.push(chunk);
  }
  return chunkedArray;
}

export const queryWrapper = (query) => {
  if (process.env.REACT_APP_NO_AUTH === 'true') {
      console.log('setting no auth to true in query');
      query.noAuth = true;
  }
  return query;
}

export const reactAdminFetch = (url, options = {}) => {
  const requestHeaders = fetchUtils.createHeadersFromOptions(options);

  return fetch(url, { ...options, headers: requestHeaders })
      .then(response =>
          response.text().then(text => ({
              status: response.status,
              statusText: response.statusText,
              headers: response.headers,
              body: text,
          }))
      )
      .then(({ status, statusText, headers, body }) => {
          let json;
          try {
              json = JSON.parse(body);
          } catch (e) {
              // not json, no big deal
          }
          // localStorage.removeItem('token');
          if (status === 403 || status === 401) {
            localStorage.removeItem('token');
            window.dispatchEvent(new Event('storage'));
            return Promise.reject(
              new HttpError(
                  (json && json.message) || statusText,
                  status,
                  json
              )
            );
          }
          if (status < 200 || status >= 300) {
              return Promise.reject(
                  new HttpError(
                      (json && json.message) || statusText,
                      status,
                      json
                  )
              );
          }
          return Promise.resolve({ status, headers, body, json });
      });
};
