
export const PRODUCT_KEYS = {
  ARRAY: 'product_array',
  CAPTURE: 'product_capture',
  CHOICES: 'product_choices',
  ELEA: 'product_elea',
  FLEX_CHARGE: 'product_flex_charge',
  HANA_FOOT_REST: 'product_hana_foot_rest',
  HANA_LAPTOP_STAND: 'product_hana_laptop_stand',  INTRINSIC: 'product_intrinsic',
  LUSTRE: 'product_lustre',
  RISING: 'product_rising',
  RISING_LOFT: 'product_rising_loft',
  SENA: 'product_sena',
  TITAN: 'product_titan',
  VOLO_FRAMED: 'product_volo_framed',
  VOLO_FRAMELESS: 'product_volo_frameless',
  TRIG: 'product_trig',
  VICTORY: 'product_victory',
  ZONA: 'product_zona',
}

// export const getDisplayName = (key) => {
//   console.log('getting display name for : ', key);
//   return 'Display name';
// }

export const PRODUCT_TYPES = {
  ACCESSORY_CHAIR: 'product_type_accessory_chair',
  ACCESSORY_FOOT_REST: 'product_type_accessory_foot_rest',
  ACCESSORY_LAPTOP_STAND: 'product_type_accessory_laptop_stand',
  ACCESSORY_LIGHTING: 'product_type_accessory_lighting',
  ACCESSORY_MONITOR_ARM: 'product_type_accessory_monitor_arm',
  ACCESSORY_POWER: 'product_type_accessory_power',
  ACCESSORY_TABLE_ENHANCEMENT: 'product_type_accessory_table_enhancement',
  AIR_QUALITY_MANAGEMENT: 'product_type_air_quality_management',
  ASSEMBLY: 'product_type_assembly',
  WALL: 'product_type_wall',
}

export const getProductType = (productKey) => {
  switch (productKey) {
    case PRODUCT_KEYS.ELEA:
      return PRODUCT_TYPES.ACCESSORY_CHAIR;
    case PRODUCT_KEYS.HANA_FOOT_REST:
      return PRODUCT_TYPES.ACCESSORY_FOOT_REST;
    case PRODUCT_KEYS.HANA_LAPTOP_STAND:
      return PRODUCT_TYPES.ACCESSORY_LAPTOP_STAND;
    case PRODUCT_KEYS.LUSTRE:
      return PRODUCT_TYPES.ACCESSORY_LIGHTING;
    case PRODUCT_KEYS.RISING:
    case PRODUCT_KEYS.SENA:
      return PRODUCT_TYPES.ACCESSORY_MONITOR_ARM;
    case PRODUCT_KEYS.FLEX_CHARGE:
      return PRODUCT_TYPES.ACCESSORY_POWER;
    case PRODUCT_KEYS.RISING_LOFT:
      return PRODUCT_TYPES.ACCESSORY_TABLE_ENHANCEMENT;
    case PRODUCT_KEYS.ARRAY:
      return PRODUCT_TYPES.AIR_QUALITY_MANAGEMENT;
    case PRODUCT_KEYS.TRIG:
    case PRODUCT_KEYS.VICTORY:
    case PRODUCT_KEYS.CAPTURE:
    case PRODUCT_KEYS.CHOICES:
    case PRODUCT_KEYS.ZONA:
    case PRODUCT_KEYS.INTRINSIC:
      return PRODUCT_TYPES.ASSEMBLY;
    case PRODUCT_KEYS.TITAN:
      return PRODUCT_TYPES.WALL;
    case PRODUCT_KEYS.VOLO_FRAMED:
      return PRODUCT_TYPES.WALL;
    case PRODUCT_KEYS.VOLO_FRAMELESS:
      return PRODUCT_TYPES.WALL;
    default:
      return undefined;
  }
}

export const DISPLAY_NAMES = {

}

export const getNumberOfVignetteRooms = (vignetteLayout) => {
  switch (vignetteLayout) {
    case 'Single':
    case 'Open20x30':
    case 'Freestanding':
    case 'OfficeAbutted':
      return 1;
    case 'Double':
      return 2;
    case 'Triple':
      return 3;
    default:
      throw new Error('unexpected value for vignette.vignettePropSelections.vignetteLayout')
  }
}

export const getLayoutName = (vignette) => {
  if (!(vignette && vignette.vignettePropSelections && vignette.vignettePropSelections.vignetteLayout)) {
    return '';
  }

  switch (vignette.vignettePropSelections.vignetteLayout) {
    case 'Single':
      return 'Single';
    case 'Double':
      return 'Double';
    case 'Triple':
      return 'Triple';
    case 'Open20x30':
      return 'Open Space';
    case 'Freestanding':
      return 'Freestanding';
    case 'OfficeAbutted':
      return 'Office-Abutted';
    default:
      return '';
  }

}

export const isProductLevel2Certified = (productKey) => {
  /*
    Level 2 Certified Product Categories and Series

    All Modular Walls---Volo, Trendwall (and soon on Titan)
    The following Furniture---Intrinsic, Trig, Capture, Choices, Pack Filing
    Following Accessories---Conceal, CPU05, CPU01, TechDock
    Following Monitor Arms---Rising, Sena, Edge, Evolve, Kata
    Following Height Adjustable Tables---AllFlex, Triumph LX, Victory LX, Trada
  */
  switch (productKey) {
    case PRODUCT_KEYS.INTRINSIC:
    case PRODUCT_KEYS.TRIG:
    case PRODUCT_KEYS.CAPTURE:
    case PRODUCT_KEYS.CHOICES:
    case PRODUCT_KEYS.RISING:
    case PRODUCT_KEYS.SENA:
      return true;
    default:
      return false;
  }
}

export const getProductWarranty = (productKey) => {
  /*
    Lifetime Warranty by Product Category and Series (see attached)

    Modula Walls*---Titan, Volo, Trendwall
    Furniture*---Capture, Choices, Intrinsic, Trig, Zona Tables, Conference Tables, Collaboration and Standing Height Tables, Elea
    Workspace---Monitor Arms, Height Adjustable Tables, Motific Laptop Table
    * Panel and wall fabrics/vinyls are 10 years, and Elea mesh seat/back material is 5 years
    Following products have a 10 year warranty---Flexcharge (3, 4, Power Hub), Lustre, Lily, Lucera Lighting series and Hana models
*/
  switch (productKey) {
    case PRODUCT_KEYS.TITAN:
    case PRODUCT_KEYS.VOLO_FRAMED:
    case PRODUCT_KEYS.VOLO_FRAMELESS:
    case PRODUCT_KEYS.CAPTURE:
    case PRODUCT_KEYS.CHOICES:
    case PRODUCT_KEYS.INTRINSIC:
    case PRODUCT_KEYS.TRIG:
    case PRODUCT_KEYS.ZONA:
    case PRODUCT_KEYS.ELEA:
      return 'Lifetime warranty';
    case PRODUCT_KEYS.FLEX_CHARGE:
    case PRODUCT_KEYS.LUSTRE:
    case PRODUCT_KEYS.HANA_FOOT_REST:
    case PRODUCT_KEYS.HANA_LAPTOP_STAND:
      return '10 year warranty';
    default:
      return undefined;
  }
}
