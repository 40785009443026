import {
    Admin,
    AutocompleteInput,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    FileInput,
    ImageField,
    List,
    required,
    Resource,
    SimpleForm,
    TextField,
    UrlField,
    TextInput,
    useRecordContext,
    FileField,
} from 'react-admin';
import { PRODUCT_KEYS } from '../../../../utils/dataMaps.js';
import { getDisplayName } from '../../../../utils/metaData.js';
import { CMSLayout, FileInputPlaceholder } from '../../../../components/CMSUtils.jsx';
import { dataProvider } from '../../../../utils/cmsDataProvider';


export const ProductNameField = (props) => {
    const record = useRecordContext(props);
    return record ? <span>{getDisplayName(record.product_key)}</span> : null;
}

export const ProductInfoList = () => (
    <List>
        <Datagrid rowClick="edit" style={{tableLayout: 'fixed'}}>
            <TextField source="id" />
            <ImageField source="thumbnail_url" label="preview" />
            <TextField source="title" />
            <ProductNameField label="Product Name" />
            <TextField source="type" />
            <UrlField  source="url" />
            <UrlField source="thumbnail_url" />
            <TextField source="fullscreen_only" />
            {/* <TextField source="product_key" /> */}
        </Datagrid>
    </List>
);

let productKeyInputOptions = [];

for (const [_, value] of Object.entries(PRODUCT_KEYS)) {
    productKeyInputOptions.push({ id: value, name: getDisplayName(value)})
}

export const ProductInfoEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput disabled source="id" validate={[required()]} />
            <TextInput style={{ display: 'none' }} hidden source="corresponding_asset_type" validate={[required()]} />
            <TextInput source="title" validate={[required()]}/>
            <AutocompleteInput source="product_key" choices={productKeyInputOptions} validate={[required()]} />
            <TextInput source="url" validate={[required()]}/>
            <TextInput source="thumbnail_url" validate={[required()]}/>
            <BooleanInput source="fullscreen_only" validate={[required()]}/>
        </SimpleForm>
    </Edit>
);

export const ProductInfoCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput style={{ display: 'none' }} hidden source="isMedia" defaultValue={true} validate={[required()]} />
            <TextInput source="title" validate={[required()]} />
            <AutocompleteInput source="product_key" choices={productKeyInputOptions} validate={[required()]} />
            <FileInput
                source="file"
                accept={['image/jpeg', 'video/mp4']}
                validate={[required()]}
                placeholder={<FileInputPlaceholder />}
            >
                <FileField source="src" title="title" />
            </FileInput>
            <BooleanInput source="fullscreen_only" validate={[required()]} />
        </SimpleForm>
    </Create>
);



export const CMS = () => (
    <Admin
        dataProvider={dataProvider}
        basename='/admin/media/productInfo'
        layout={CMSLayout}
    >
        <Resource
            name="productInfo"
            recordRepresentation="name"
            options={{ label: 'Product Info Media' }}
            list={ProductInfoList}
            edit={ProductInfoEdit}
            create={ProductInfoCreate}
        />
    </Admin>
);