import { useEffect, useState } from 'react';
import {
  Navigate,
  useLocation
} from "react-router-dom";
import AuthConsumer from './util';

export function RequireAuth({ children }) {
  let [isAuthenticated, setIsAuthenticated] = useState(false);
  const { pathname } = useLocation();
  // console.log('location: ', location);

  const { authed, verifyToken } = AuthConsumer();


  // const abc = async () => {
  //   const isAuthenticated
  // }

  useEffect(() => {
    const checkAuth = async () => await verifyToken();
    checkAuth().then(isAuthd => {
      console.log('isAuthd: ', isAuthd);
      return setIsAuthenticated(isAuthd);
    });
  }, []);

  // isAuthenticated = true;
  
  console.log('checking auth');
  console.log('isAuthenticated: ', isAuthenticated);

  return true ? children : <Navigate to='/login' state={{ from: pathname }} replace />;

}