import {
    Admin,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    FileInput,
    FileField,
    ImageField,
    List,
    required,
    Resource,
    SimpleForm,
    TextField,
    UrlField,
    TextInput,
} from 'react-admin';
import { CMSLayout, FileInputPlaceholder } from '../../../../components/CMSUtils';
import { dataProvider } from '../../../../utils/cmsDataProvider';

export const MediaStructList = () => (
    <List>
        <Datagrid rowClick="edit" style={{tableLayout: 'fixed'}}>
            <TextField source="id" />
            <ImageField source="thumbnail_url" label="preview" />
            <TextField source="title" />
            <TextField source="type" />
            <UrlField  source="url" />
            <UrlField source="thumbnail_url" />
            <TextField source="fullscreen_only" />
            <TextField source="collage_marker_id" />
        </Datagrid>
    </List>
);

export const CollageEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput disabled source="id" />
            {/* <TextInput style={{ display: 'none' }} hidden source="corresponding_asset_type" validate={[required()]} /> */}
            <TextInput source="title" validate={[required()]} />
            <TextInput source="url" validate={[required()]} />
            <TextInput source="thumbnail_url" validate={[required()]} />
            <BooleanInput source="fullscreen_only" validate={[required()]} />
        </SimpleForm>
    </Edit>
);

export const CollageCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput style={{ display: 'none' }} hidden source="isMedia" defaultValue={true} validate={[required()]} />
            <TextInput source="title" validate={[required()]} />
            <FileInput
                source="file"
                accept={['image/jpeg', 'video/mp4']}
                validate={[required()]}
                placeholder={<FileInputPlaceholder />}
            >
                <FileField source="src" title="title" />
            </FileInput>
            <BooleanInput source="fullscreen_only" validate={[required()]} />
        </SimpleForm>
    </Create>
);



export const CMS = () => (
    <Admin
        dataProvider={dataProvider}
        basename='/admin/media/collage'
        layout={CMSLayout}
    >
        <Resource
            name="collage"
            recordRepresentation="name"
            options={{ label: 'Collage Images' }}
            list={MediaStructList}
            edit={CollageEdit}
            create={CollageCreate}
        />
    </Admin>
);