export const getThumbnailUrlsForProduct = (product, vignetteBase = '') => {
  vignetteBase = vignetteBase.toLowerCase();
  let defaultPath = '';
  switch (product.product) {
    case 'product_array':
      switch (vignetteBase) {
        case 'openfloorplan':
          defaultPath = 'AQM_Array_Hanging.png';
          break;
        case 'smalloffice':
        case 'executiveoffice':
          defaultPath = 'AQM_Array_Small.png';
          break;
        case 'largeconferenceroom':
        case 'trainingroom':
          defaultPath = 'AQM_Array_Large.png';
          break;
        default:
          defaultPath = 'AQM_Array_Small.png';
          break;
      }
      break;
    case 'product_rising':
      if (product.productVariant === 'rising_single_arm') {
        defaultPath = 'product_rising-rising_single_arm-rising_black_bronze.png';
      } else if (product.productVariant === 'rising_dual_arm') {
        defaultPath = 'product_rising-rising_dual_arm-rising_black_bronze.png';
      } else {
        defaultPath = 'product_rising-rising_single_arm-rising_black_bronze.png';
      }
      break;
    case 'product_sena':
      if (product.productVariant === 'sena_single_arm') {
        defaultPath = 'product_sena-sena_single_arm-sena_black.png';
      } else if (product.productVariant === 'sena_dual_arm') {
        defaultPath = 'product_sena-sena_dual_arm-sena_black.png';
      } else {
        defaultPath = 'product_sena-sena_single_arm-sena_black.png';
      }
      break;
    case 'product_rising_loft':
      defaultPath = 'product_rising_loft--rising_loft_black.png';
      break;
    case 'product_elea':
      if (product.productVariant === 'elea_black') {
        defaultPath = 'product_elea--elea_black.png';
      } else {
        defaultPath = 'product_elea--elea_white.png';
      }
      break;
    case 'product_lustre':
      defaultPath = 'product_lustre--lustre_white.png';
      break;
    case 'product_flex_charge':
      defaultPath = 'product_flex_charge--flex_charge_white.png';
      break;
    case 'product_hana_foot_rest':
      defaultPath = 'product_hana_foot_rest--hana_foot_rest_black.png';
      break;
    case 'product_hana_laptop_stand':
      defaultPath = 'product_hana_laptop_stand--hana_laptop_stand_black.png';
      break;
    default: defaultPath = 'undefined'; 
  }

  const basePath = 'https://fultonmarket-dev.s3.us-west-1.amazonaws.com/web-ui/product-thumbnails';

  const productKey = product.product !== 'null_opt' ? product.product : '';
  const productVariant = product.productVariant !== 'null_opt' ? product.productVariant : '';
  const productColorway = product.selectedColorway !== 'null_opt' ? product.selectedColorway : '';

  let imageUrl = `${basePath}/${productKey}-${productVariant}-${productColorway}.png`;

  const defaultUrl = `${basePath}/${defaultPath}`;
  if (!productColorway) {
    imageUrl = defaultUrl;
  }

  return {
    imageUrl,
    defaultUrl
  }
}

export const ProductThumbnail = ({ product, style, vignetteBase }) => {

  const { imageUrl, defaultUrl } = getThumbnailUrlsForProduct(product, vignetteBase);

  return (
    <img
      className="w-[100%] object-cover"
      src={imageUrl}
      style={style}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src=defaultUrl;
      }}
    />
  )
}

