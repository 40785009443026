import { Link } from 'react-router-dom';
import {
    Admin,
    AutocompleteInput,
    BooleanInput,
    Create,
    Datagrid,
    DateField,
    Edit,
    FileInput,
    FileField,
    ImageField,
    List,
    required,
    Resource,
    SimpleForm,
    TextField,
    UrlField,
    TextInput,
    useTranslate,
    useRecordContext,
    ReferenceField,
    ReferenceInput,
} from 'react-admin';
import { useWatch } from "react-hook-form";
import { CMSLayout, FileInputPlaceholder } from '../../../../components/CMSUtils';
import { dataProvider } from '../../../../utils/cmsDataProvider';


const LinkToRelatedTable = ({pathname, foreignKey, linkText}) => {
    const record = useRecordContext();
    const translate = useTranslate();
    return record ? (
        <Link
            to={{
                pathname: pathname,
                search: `filter=${JSON.stringify({ [foreignKey]: record.id })}`,
            }}
            onClick={(e) => e.stopPropagation()}
        >
            {linkText}
        </Link>
    ) : null;
};

export const InsightTokenList = (props) => {
    console.log('insight token list props: ', props);
    return (
        <List>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="token_key" />
                <LinkToRelatedTable
                    pathname="/admin/media/insightToken/insightToken/mainCategory"
                    foreignKey="insight_token_id"
                    linkText="Main Categories"
                />
                <DateField source="created_at" />
                <DateField source="updated_at" />
            </Datagrid>
        </List>
    );
};

export const InsightTokenEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="token_key" validate={[required()]} />
            <TextInput source="name" validate={[required()]} />
        </SimpleForm>
    </Edit>
);

export const InsightTokenCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="token_key" validate={[required()]} />
            <TextInput source="name" validate={[required()]} />
        </SimpleForm>
    </Create>
);

export const MainCategoryList = () => {
    return (
        <List storeKey={false}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <ReferenceField source="insight_token_id" reference="insightToken" />
                <TextField source="display_name" />
                <TextField source="index" />
                <LinkToRelatedTable
                    pathname="/admin/media/insightToken/insightToken/subCategory"
                    foreignKey="insight_token_main_category_id"
                    linkText="Sub Categories"
                />
                <DateField source="created_at" />
                <DateField source="updated_at" />
                {/* <TextField source="insight_token.name" /> */}
            </Datagrid>
        </List>
    );
};

export const MainCategoryEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="display_name" validate={[required()]} />
            <ReferenceInput source="insight_token_id" reference="insightToken" validate={[required()]} />
            <TextInput source="index" validate={[required()]} />
        </SimpleForm>
    </Edit>
);

export const MainCategoryCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="display_name" validate={[required()]} />
            <ReferenceInput source="insight_token_id" reference="insightToken" validate={[required()]} />
            <TextInput source="index" validate={[required()]} />
        </SimpleForm>
    </Create>
);

export const SubCategoryList = () => {
    return (
        <List storeKey={false}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <ReferenceField source="main_cat.insight_token.id" label="Insight Token" reference="insightToken" />
                <ReferenceField source="insight_token_main_category_id" label="Main Category" reference="insightToken/mainCategory" />
                <TextField source="display_name" />
                <TextField source="index" />
                <LinkToRelatedTable
                    pathname="/admin/media/insightToken/insightToken/media"
                    foreignKey="insight_token_sub_category_id"
                    linkText="Media"
                />
                <DateField source="created_at" />
                <DateField source="updated_at" />
            </Datagrid>
        </List>
    );
};

const MainCategoryInput = ({ source, validate, label, watchVal }) => {
    const insightTokenId = useWatch({ name: watchVal });
    console.log('insightToken: ', insightTokenId);
    const filter = insightTokenId && { insight_token_id: insightTokenId }
    return (
        <ReferenceInput source={source} validate={validate} reference="insightToken/mainCategory" filter={filter}>
            <AutocompleteInput source="main_category_id" label={label} />
        </ReferenceInput>
    );
};

const SubCategoryInput = ({ source, validate, label }) => {
    const insightTokenMainCategoryId = useWatch({ name: "sub_cat.main_cat.id" });
    console.log('insightTokenMainCategoryId: ', insightTokenMainCategoryId);
    const filter = insightTokenMainCategoryId && { insight_token_main_category_id: insightTokenMainCategoryId }
    return (
        <ReferenceInput source={source} label={label} reference="insightToken/subCategory" filter={filter} validate={validate}>
            <AutocompleteInput label={label} />
        </ReferenceInput>
    );
};

export const SubCategoryEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="display_name" validate={[required()]} />
            <ReferenceInput source="insight_token" reference="insightToken" validate={[required()]} />
            <MainCategoryInput source="insight_token_main_category_id" watchVal="insight_token" label="Main Category" validate={[required()]} />
            <TextInput source="index" validate={[required()]} />
        </SimpleForm>
    </Edit>
);

export const SubCategoryCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="display_name" validate={[required()]} />
            <ReferenceInput source="insight_token" reference="insightToken" validate={[required()]} />
            <MainCategoryInput source="insight_token_main_category_id" watchVal="insight_token" label="Main Category" validate={[required()]} />
            <TextInput source="index" validate={[required()]} />
        </SimpleForm>
    </Create>
);


export const MediaStructList = () => (
    <List>
        <Datagrid rowClick="edit" style={{tableLayout: 'fixed'}}>
            <TextField source="id" />
            <ImageField source="thumbnail_url" label="preview" />
            <ReferenceField source="sub_cat.main_cat.insight_token.id" label="Insight Token" reference="insightToken" />
            <ReferenceField source="sub_cat.main_cat.id" label="Main Category" reference="insightToken/mainCategory" />
            <ReferenceField source="sub_cat.id" label="Sub Category" reference="insightToken/subCategory" />
            <TextField source="insight_token_sub_category_index" label="Index" />
            <TextField source="title" />
            <TextField source="type" />
            <UrlField  source="url" />
            <UrlField source="thumbnail_url" />
            <TextField source="fullscreen_only" />
            <TextField source="insight_token_sub_category_id" />
        </Datagrid>
    </List>
);



export const MediaStructEdit = () => (
    <Edit>
        <SimpleForm>
            <ReferenceInput source="sub_cat.main_cat.insight_token.id" reference="insightToken">
                <AutocompleteInput source="insight_token" label="Insight Token" />
            </ReferenceInput>
            <MainCategoryInput source="sub_cat.main_cat.id" watchVal="sub_cat.main_cat.insight_token.id" label="Main Category" />
            <SubCategoryInput source="sub_cat.id" label="Sub Category" />
            <TextInput source="title" validate={[required()]} />
            <TextInput source="url" validate={[required()]} />
            <TextInput source="thumbnail_url" validate={[required()]} />
            <BooleanInput source="fullscreen_only" defaultValue={false} validate={[required()]} />
            <TextInput source="insight_token_sub_category_index" label="Index"  validate={[required()]} />
        </SimpleForm>
    </Edit>
);

export const MediaStructCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput style={{ display: 'none' }} hidden source="isMedia" defaultValue={true} validate={[required()]} />
            <ReferenceInput source="sub_cat.main_cat.insight_token.id" reference="insightToken">
                <AutocompleteInput source="insight_token" label="Insight Token" />
            </ReferenceInput>
            <MainCategoryInput source="sub_cat.main_cat.id" watchVal="sub_cat.main_cat.insight_token.id" label="Main Category" />
            <SubCategoryInput source="sub_cat.id" label="Sub Category" />
            <TextInput source="title" validate={[required()]} />
            <FileInput
                source="file"
                accept={['image/jpeg', 'video/mp4']}
                validate={[required()]}
                placeholder={<FileInputPlaceholder />}
            >
                <FileField source="src" title="title" />
            </FileInput>
            <BooleanInput source="fullscreen_only" defaultValue={false} validate={[required()]} />
            {/* <TextInput source="product_key"  /> */}
            {/* <TextInput source="insight_token_sub_category_id"  /> */}
            <TextInput source="insight_token_sub_category_index" label="Index"  validate={[required()]} />
        </SimpleForm>
    </Create>
);

export const CMS = () => (
    <Admin
        dataProvider={dataProvider}
        basename='/admin/media/insightToken'
        layout={CMSLayout}
    >
        <Resource
            name="insightToken"
            recordRepresentation="name"
            options={{ label: 'Insight Token' }}
            list={InsightTokenList}
            edit={InsightTokenEdit}
            create={InsightTokenCreate}
        />
        <Resource
            name="insightToken/mainCategory"
            recordRepresentation="display_name"
            options={{ label: 'Main Categories' }}
            list={MainCategoryList}
            edit={MainCategoryEdit}
            create={MainCategoryCreate}
        />
        <Resource
            name="insightToken/subCategory"
            recordRepresentation="display_name" 
            options={{ label: 'Sub Categories' }} 
            list={SubCategoryList} 
            edit={SubCategoryEdit} 
            create={SubCategoryCreate} 
        />
        <Resource
            name="insightToken/media"
            options={{ label: 'Media' }}
            list={MediaStructList}
            edit={MediaStructEdit}
            create={MediaStructCreate}
        />
    </Admin>
);