import * as React from "react";
import { getBaseUrl } from '../../../utils';


const authContext = React.createContext();

export function useAuth() {
  const [authed, setAuthed] = React.useState(false);

  return {
    authed,
    login1: ({ username, password }) => {
      const request = new Request(`${getBaseUrl()}/auth/adminLogin`, {
          method: 'POST',
          body: JSON.stringify({ username, password }),
          headers: new Headers({ 'Content-Type': 'application/json' }),
      });
      return fetch(request);
    },
    login: ({ username, password }) => {
      const request = new Request(`${getBaseUrl()}/auth/adminLogin`, {
          method: 'POST',
          body: JSON.stringify({ username, password }),
          headers: new Headers({ 'Content-Type': 'application/json' }),
      });
      return fetch(request)
          .then(response => {
              if (response.status < 200 || response.status >= 300) {
                  throw new Error(response.statusText);
              }
              return response.json();
          })
          .then(body => {
              localStorage.setItem('token', body.token);
              setAuthed(true);
          })
          .catch(() => {
              throw new Error('Network error')
          })
    },
    login2() {
      return new Promise((res) => {
        setAuthed(true);
        console.log('just set authed to true')
        res();
      });
    },
    verifyToken() {
      const request = new Request(`${getBaseUrl()}/auth/verifyToken`, {
          method: 'POST',
          body: JSON.stringify({ 
              token: localStorage.getItem('token')
          }),
          headers: new Headers({ 'Content-Type': 'application/json' }),
      });
      return fetch(request)
          .then(response => {
              if (response.status < 200 || response.status >= 300) {
                  throw new Error(response.statusText);
              }
              return response.json();
          })
          .then(auth => {
              console.log('verifying token, auth: ', auth);
              return true;
              // localStorage.setItem('auth', JSON.stringify(auth));
          })
          .catch(() => {
              return false;
          });
    },
    logout() {
      return new Promise((res) => {
        setAuthed(false);
        res();
      });
    },
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}

