import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Table,
  Header,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell,
} from '@table-library/react-table-library/table';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTheme } from '@table-library/react-table-library/theme';
import { getBaseUrl } from '../../utils';

const THEME = {
  BaseRow: `
    font-size: 16px;
  `,
  HeaderRow: `
    background-color: #eaf5fd;
  `,
  Row: `
    text-align: left;

    &:nth-child(odd) {
      background-color: #d2e9fb;
    }

    &:nth-child(even) {
      background-color: #eaf5fd;
    }

    button {
      border: none;
      font-size: 16px;
      color: #646cff;
      padding: 0;
    }

    a {
      font-size: 16px;
    }
  `,
};

const handleClick = (markerId, machineId) => async () => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "fdsf893r9fdsjf3");
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    fetch(`${process.env.REACT_APP_RAILS_SERVER_URL}/${markerId}?machineId=${machineId}`, requestOptions)
      .then(response => {
        console.log('response: ', response);
        if (response.status !== 200) {
          throw new Error('Something went wrong. Please try again.');
        }
        return response.text();
      })
      .then(result => {
        if (result.success) {
          throw new Error('Unexpected response from the backend. Please try again.');
        }
        return toast.success(`Successfully loaded user ${markerId} to ${machineId}`);
      })
      .catch(error => {
        return toast.error(error.message);
      });
  } catch (err) {
      console.log(err.message)
  }
}

const getUserName = (item) => `${item.user.json_body.first_name} ${item.user.json_body.last_name}`;

export const Users = () => {

  const [users, setUsers] = useState([]);
  let data = { nodes: users };

  const [searchParams, setSearchParams] = useSearchParams();
  let machineId = searchParams.get('mid') || process.env.REACT_APP_DEFAULT_PORTRAY_MACHINE_ID;

  const [nameSearch, setNameSearch] = useState('');
  const [markerIdSearch, setMarkerIdSearch] = useState('');

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  console.log('windowSize: ', windowSize);

  const largeBreakpoint = windowSize[0] > 1024;


  const handleNameSearch = (event) => {
    setNameSearch(event.target.value);
  };

  const handleMarkerIdSearch = (event) => {
    setMarkerIdSearch(event.target.value);
  };

  if (nameSearch) {
    data = {
      nodes: data.nodes.filter((item) =>
        getUserName(item).toLowerCase().includes(nameSearch.toLowerCase())
      ),
    };
  } else if (markerIdSearch) {
    data = {
      nodes: data.nodes.filter((item) =>
        item.user.marker_id.includes(markerIdSearch)
      ),
    };
  }

  const theme = useTheme(THEME);

  useEffect(() => {
    const fetchUsers = async () => {
      let fetchUrl = `${getBaseUrl()}/user?token=${localStorage.getItem('token')}`;
      const resp = await fetch(fetchUrl);
      if (resp.status === 403 || resp.status === 401) {
        localStorage.removeItem('token');
        window.dispatchEvent(new Event('storage'));
        return Promise.reject(
            new Error('Invalid token')
        );
      }
      const data = await resp.json();
      return data;
    }

    fetchUsers().then(data => {
      setUsers(data);
    }).catch(err => {
      console.error(err);
    });
  }, []);


  return (
    <>
      {
        users.length ? (
          <div className="mt-[30px]">
            <div className="mb-[10px]">
              <label className="mr-[40px]" htmlFor="search">
                Search by Name:&nbsp;
                <input id="search" type="text" value={nameSearch} onChange={handleNameSearch} onFocus={() => setMarkerIdSearch('')} />
              </label>
              <label htmlFor="search">
                Search by Marker Id:&nbsp;
                <input id="search" type="text" value={markerIdSearch} onChange={handleMarkerIdSearch} onFocus={() => setNameSearch('')} />
              </label>
            </div>
            <br />
            <Table data={data} theme={theme} className="px-[20px]">
              {(tableList) => (
                <>
                  <Header>
                    <HeaderRow>
                      {/* <HeaderCell>Id</HeaderCell> */}
                      <HeaderCell>Marker Id</HeaderCell>
                      <HeaderCell>Company</HeaderCell>
                      <HeaderCell>Name</HeaderCell>
                      <HeaderCell hide={!largeBreakpoint}>Email</HeaderCell>
                      <HeaderCell hide={!largeBreakpoint}>Edit User Info</HeaderCell>
                      <HeaderCell hide={!largeBreakpoint}>Load User Session On App</HeaderCell>
                      <HeaderCell hide={!largeBreakpoint}>Render Session PDF</HeaderCell>
                      <HeaderCell hide={largeBreakpoint}>Actions</HeaderCell>
                    </HeaderRow>
                  </Header>
                  <Body>
                    {tableList.map((item) => (
                      <Row key={item.id} item={item}>
                        {/* <Cell>{item.user.id}</Cell> */}
                        <Cell>{item.user.marker_id}</Cell>
                        <Cell>{item.user.json_body.company}</Cell>
                        <Cell>{item.user.json_body.first_name} {item.user.json_body.last_name}</Cell>
                        <Cell hide={!largeBreakpoint}>{item.user.json_body.email}</Cell>
                        <Cell hide={!largeBreakpoint}><a href={item.adminRegistrationUrl} target="_blank" rel="noreferrer">EDIT</a></Cell>
                        <Cell hide={!largeBreakpoint}><button onClick={handleClick(item.user.marker_id, machineId)}>LOAD</button></Cell>
                        {/* <Cell><a href={`https://fellowes-fulton-market-prod.herokuapp.com/activate_session/${item.user.marker_id}?machineId=DEREK`} target="_blank" rel="noreferrer">Load</a></Cell> */}
                        <Cell hide={!largeBreakpoint}><a href={item.renderPdfUrl} target="_blank" rel="noreferrer">RENDER</a></Cell>
                        <Cell hide={largeBreakpoint}>
                          <a href={item.adminRegistrationUrl} target="_blank" rel="noreferrer">EDIT</a>/
                          <button onClick={handleClick(item.user.marker_id, machineId)}>LOAD</button>/
                          <a href={item.renderPdfUrl} target="_blank" rel="noreferrer">RENDER</a>
                        </Cell>
      
                      </Row>
                    ))}
                  </Body>
                </>
              )}
            </Table>
            <ToastContainer />
          </div>
        ) : null
      }
    </>

  )
}
